import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CreateEvaModalContextProvider, CreateEvaModalOpen } from '../../../../../contexts/CreateEvaModalContext';
import { setArchiveEvaId } from '../../../../../Redux/EvaInfo';
import { ReactComponent as EmptyTrends } from './../../../../../assets/icons/empty_Trends.svg';
import { ReactComponent as NewEmptyTrend } from './../../../../../assets/icons/emptyTrend.svg';

interface EmptyRiskProps {
    title: string;
    description: string;
    buttonName: string;
    isRisk?: boolean;
}

const EmptyRisk: React.FC<EmptyRiskProps> = ({ title, description, buttonName, isRisk }) => {
    return (
        <CreateEvaModalContextProvider>
            <EmptyRiskCom title={title} description={description} buttonName={buttonName} isRisk={isRisk} />
        </CreateEvaModalContextProvider>
    );
};

const EmptyRiskCom: React.FC<EmptyRiskProps> = ({ title, description, isRisk, buttonName }) => {
    const { t } = useTranslation();
    const { id: organisationId } = useParams<{ id: string }>();
    const history = useHistory();

    const dispatch = useDispatch();
    const { setCreateEvaModalOpen } = CreateEvaModalOpen();

    const handleOpen = () => {
        setCreateEvaModalOpen(true);
        dispatch(setArchiveEvaId(null));
        setTimeout(() => {
            history.push(`/organisation/${organisationId}/eva`);
        }, 200);
    };

    return (
        <div className='empty_box flex items-center scrollbar justify-center'>
            <div>
                <div className='empty_graph flex items-center justify-center'>
                    {isRisk && isRisk ? <EmptyTrends /> : <NewEmptyTrend />}
                </div>
                <h4>{title}</h4>
                {isRisk && isRisk ? (
                    <p dangerouslySetInnerHTML={{ __html: t('risk.policyProtocol') }} />
                ) : (
                    <p>{description}</p>
                )}
                <button className='blueprint_btn' onClick={handleOpen}>
                    {buttonName}
                </button>
            </div>
        </div>
    );
};

export default EmptyRisk;
