import { useState } from 'react';

import Menu from '@mui/material/Menu';
import * as Sentry from '@sentry/react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { appInsights } from '../../../AppInsights';
import { CreateEvaModalContextProvider, CreateEvaModalOpen } from '../../../contexts/CreateEvaModalContext';
import { SubscriptionProvider } from '../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { SetIsCreateEvaFromBlueprints } from '../../../Redux/EvaInfo';
import { store } from '../../../store';
import { EvaArchive } from '../../../store/OrganisationStore';
import { CustomLoader } from '../../basic/CustomeLoader/CustomLoader';
import { SearchBar } from '../../basic/SearchBar.component';
import SubHeader from './eva/components/SubHeader';
import EvaArchiveCard from './EvaArchiveCard.component';

const Library = () => {
    return (
        <CreateEvaModalContextProvider>
            <LibraryComponent />
        </CreateEvaModalContextProvider>
    );
};
const LibraryComponent = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const [search, setSearch] = useState('');
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const [archivedEva, setArchivedEva] = useState<EvaArchive[]>([]);
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const { setCreateEvaModalOpen } = CreateEvaModalOpen();
    const [role, setRole] = useState<string>('');
    const [sortBy, setSortBy] = useState<{ property: string; ascending: boolean }>({
        property: 'lastEdited',
        ascending: false,
    });
    const history = useHistory();
    const { t } = useTranslation();
    const open = Boolean(anchor);
    const { getAllArchivedEva, checkUserPermission, getUserPermission } = useOrganisationForm();
    const {
        isLoading,
        isFetching,
        refetch: refetchEvaData,
    } = useQuery(['evaData', organisationId], async () => getAllArchivedEva(organisationId), {
        enabled: true,
        onSuccess: async (data) => {
            setArchivedEva(data);
            runInAction(() => {
                store.organisation.setArchivedEVAs(data);
            });
            const userPermission = await checkUserPermission(organisationId);
            const role = await getUserPermission(organisationId);
            setIsUserAdmin(userPermission);
            setRole(role);
        },
        onError: (error: Error) => {
            Sentry.captureException(error);
            appInsights.trackException({ error: error });
        },
    });

    const getFilteredArchivedEVAs = (): EvaArchive[] => {
        return store.organisation.archivedEVAs.filter(
            (eva) =>
                eva.title.toLowerCase().includes(search.toLowerCase()) ||
                eva.description.toLowerCase().includes(search.toLowerCase()),
        );
    };

    const dispatch = useDispatch();

    const handleSort = () => {
        setSortBy({
            property: 'lastEdited',
            ascending: !sortBy.ascending,
        });
        if (store.organisation.archivedEVAs) {
            const sorted = store.organisation.archivedEVAs.sort((a, b) => {
                if (sortBy.ascending) {
                    return a.lastEdited.localeCompare(b.lastEdited);
                } else {
                    return b.lastEdited.localeCompare(a.lastEdited);
                }
            });
            runInAction(() => {
                store.organisation.setArchivedEVAs(sorted);
            });
        }
        handleClose();
    };

    const handleRefetch = async () => {
        await refetchEvaData();
    };

    if (organisationId) localStorage.setItem('spaceId', organisationId);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    if (isLoading || isFetching) {
        return <CustomLoader />;
    }

    const openBluePrintModal = () => {
        setCreateEvaModalOpen(true);
        dispatch(SetIsCreateEvaFromBlueprints(true));
        setTimeout(() => {
            history.push(`/organisation/${organisationId}/eva`);
        }, 300);
    };

    return (
        <SubscriptionProvider>
            <SubHeader labelName={'Library'} />
            <div className='h-full w-full max-w-5xl mx-auto'>
                <div className='flex flex-row items-center md:justify-between md:mt-4 mb-6 md:mb-8 md:space-x-56'>
                    <div className='w-full flex items-center mb-4  pick-up-label-box justify-center relative'>
                        <label className='w-full  pick-up-label  text-center text-sm text-darker text-opacity-75'>
                            {t('library.title')}
                        </label>

                        <button
                            onClick={openBluePrintModal}
                            className='create_blueprint_btn absolute right-0 flex justify-center items-center'
                        >
                            {t('library.createBlueprint')}
                        </button>
                    </div>
                </div>
                <div className='flex w-full justify-between items-center'>
                    <div className='w-1/2' style={{ paddingRight: '1rem' }}>
                        <SearchBar value='' onChange={setSearch} placeholder={`${t('search')}...`} />
                    </div>
                    {/* <button
                        className='bg-white bg-opacity-25 flex items-center text-sm text-darker text-opacity-75 space-x-2 rounded-full p-2 border border-blue-dark border-opacity-10'
                        onClick={handleClick}
                    >
                        <FilterIcon className='w-3 h-3' fill='#0F1044' />
                        <p>{t('sort_by')}</p>
                        <KeyboardArrowDownOutlinedIcon
                            style={{
                                color: 'rgba(15, 16, 68, 0.75)',
                                fontSize: '1rem',
                            }}
                        />
                    </button> */}
                    <Menu
                        id='workspace-menu'
                        anchorEl={anchor}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            className: 'menu-list',
                        }}
                    >
                        <button
                            className='w-full flex p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                            onClick={handleSort}
                        >
                            <p className='text-sm'> </p>
                        </button>
                    </Menu>
                </div>
                <div className='overflow-y-auto scrollbar pr-2' style={{ maxHeight: 'calc(100vh - 100px)' }}>
                    {archivedEva?.length ? (
                        <div className='grid md:grid-cols-2 grid-cols-1 gap-5 mt-10'>
                            {getFilteredArchivedEVAs().map((eva) => (
                                <EvaArchiveCard
                                    key={eva.evaId}
                                    evaId={eva.evaId}
                                    title={eva.title}
                                    description={eva.description}
                                    lastEdited={eva.lastEdited}
                                    daysUsed={eva.daysUsed}
                                    triggers={eva.triggers}
                                    isUserAdmin={isUserAdmin}
                                    role={role}
                                    setCreateEvaModalOpen={setCreateEvaModalOpen}
                                    refetchEvaData={handleRefetch}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className='flex items-center justify-center mt-24'>
                            <p className='text-sm text-darker text-opacity-75'>{t('library.emptyLibrary')}</p>
                        </div>
                    )}
                </div>
            </div>
        </SubscriptionProvider>
    );
};

export default observer(Library);
