import { useEffect, useState } from 'react';

import { Button, withStyles } from '@material-ui/core';
import { useTour } from '@reactour/tour';
import * as Sentry from '@sentry/react';
import i18next from 'i18next';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as EditIcon } from '../../../../../src/assets/icons/Edit_Icon.svg';
import miniOrbitdisable from '../../../../../src/assets/icons/mini_OrbitIcondisable.svg';
import miniOrbit from '../../../../../src/assets/icons/mini_OrbitIocn.svg';
import miniOrbitHover from '../../../../../src/assets/icons/mini_OrbitIocnHover.svg';
import { appInsights } from '../../../../AppInsights';
import emptyUser from '../../../../assets/icons/EmptyProfile.svg';
import { CreateEvaModalContextProvider, CreateEvaModalOpen } from '../../../../contexts/CreateEvaModalContext';
import { SubscriptionProvider, useSubscriptionDetail } from '../../../../contexts/subscription.context';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { useOrganisationForm } from '../../../../hooks/useOrganisation';
import { useSettings } from '../../../../hooks/useSettings';
import {
    getEvaId,
    setDraftProtocols,
    setEvaluateMode,
    setEvaProtocolsData,
    setOpenBluePrintModal,
    setOpenLeftSidePanel,
    setProtocolIndex,
    setPublishProtocols,
} from '../../../../Redux/EvaInfo';
import { RootState } from '../../../../Redux/store';
import { EvaService } from '../../../../services/eva/eva.service';
import { store } from '../../../../store';
import { DomainCategory } from '../../../../store/OrganisationStore';
import { ROLES, SIZES } from '../../../../utils/constants';
import { getDocumentUrl } from '../../../../utils/getDocumentUrl';
import { AnimatedCircle } from '../../../basic/AnimatedCircle';
import { CustomLoader } from '../../../basic/CustomeLoader/CustomLoader';
import { StarIcon } from '../../../basic/StarIcon.component';
import { useServices } from '../../../ServiceProvider';
import { UpdateEvaUser } from '../evaAnalytics/components/analyticsChart/types';
import CreateEvaModel from './components/CreateEva.components';
import { PublishedEva } from './components/PublishedEva.component';
import CtrlLeftSidePanel from './components/publishedPlanet/CtrlLeftSidePannel/CtrlLeftSidePanel';
import SubHeader from './components/SubHeader';
import { EvaType, MetaMarkerItem, SubScriptionType } from './types';

const messageKeys: string[] = [
    'greeting',
    'culture',
    'collaboration',
    'self_governance',
    'risk_management',
    'conflict_management',
    'diversity',
    'learning',
    'excited',
    'policy',
    'blueprint',
    'potential',
    'future',
];

const EvaComponent = () => {
    const { t } = useTranslation();
    const { getEvaByOrganisationId, checkUserPermission } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();
    const { isCreateEvaModalOpen, setCreateEvaModalOpen } = CreateEvaModalOpen();
    const [publishedEvaExist, setPublishedEvaExist] = useState(false);
    const [draftEvaExist, setDraftEvaExist] = useState(false);
    const [archive, setArchive] = useState(false);
    const [restarted, setRestarted] = useState(false);
    const [randomMessage, setRandomMessage] = useState<string>('');
    const { setIsOpen, setCurrentStep } = useTour();
    const { updateOnboardingTour } = useSettings();
    const [labelShow, setLabelShow] = useState(true);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [testMode, setTestMode] = useState<boolean>(false);
    const [OrbitHover, setOrbitHover] = useState<boolean>(false);
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);
    const archiveEvaId = useSelector((state: RootState) => state.EvaInfo.archiveEvaId);

    const [topThreeDomainCategory, setTopThreeDomainCategory] = useState([]);
    const { organisation } = useServices();
    const [filteredItems, setFilteredItems] = useState<MetaMarkerItem[]>([]);
    const [updateEvaUser, setUpdateEvaUser] = useState<UpdateEvaUser>({
        userId: null,
        firstName: '',
        lastName: '',
        documentId: null,
        blobName: '',
    });
    const StyledButton = withStyles({
        root: {
            textTransform: 'none',
        },
    })(Button);

    const dispatch = useDispatch();

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (evaId === null || evaId == '') {
            dispatch(setProtocolIndex(null));
            dispatch(setPublishProtocols([]));
            dispatch(setDraftProtocols([]));
            dispatch(setEvaProtocolsData([]));
            dispatch(setPublishProtocols([]));

            setFilteredItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaId]);

    const subscription = useSubscriptionDetail();

    const shieldSubscription = subscription.productName === SubScriptionType.SHIELD;

    const evaService = new EvaService();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    const onboardingTour = store.user.userData?.onboardingTour;
    useEffect(() => {
        if (organisationId) {
            localStorage.setItem('spaceId', organisationId);
        }
    }, [organisationId]);

    const { data: userPermit } = useQuery(['checkUserPermission', organisationId], () => {
        try {
            return checkUserPermission(organisationId);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });

    const { getUserPermission } = useOrganisationForm();

    const { data: getuserPermit } = useQuery(['getUserPermission', organisationId], () => {
        try {
            return getUserPermission(organisationId);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });

    const { data: evaSummary, isLoading: evaSummaryLoading } = useQuery(['getLatestEvaUpdate', evaId], () => {
        try {
            if (evaId) return evaService.getLatestEvaUpdate(evaId);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });

    const { isLoading, isFetching, refetch, data } = useQuery(
        ['evaData', organisationId],
        async () => getEvaByOrganisationId(organisationId, EvaType.draft),
        {
            onSuccess: (eva) => {
                if (eva.id) {
                    setDescription(eva.description);
                    dispatch(getEvaId(eva.id));
                    setTitle(eva.title);
                    setArchive(eva.isEvaArchived);
                    setRestarted(eva.isRestarted);
                    setTestMode(eva?.isTestMode);
                    if (eva.id !== null && eva.isPublished) {
                        setPublishedEvaExist(true);
                    }
                    if (eva.id !== null && !eva.isPublished) {
                        setDraftEvaExist(true);
                    }
                } else {
                    dispatch(getEvaId(null));
                    setPublishedEvaExist(false);
                    setDraftEvaExist(false);
                    if (onboardingTour?.startTour && !onboardingTour.eva) {
                        setCurrentStep(2);
                        setIsOpen(true);
                        updateOnboardingTour(true, 'eva');
                    }
                }
            },
            onError: (error: Error) => {
                Sentry.captureException(error);
                appInsights.trackException({ error: error });
            },
        },
    );

    const {
        isLoading: publishLoading,
        isFetching: isFetchingPublish,
        refetch: publishRefetch,
        data: result,
    } = useQuery(
        ['getEvaByOrganisationId', organisationId, EvaType.publish],
        () => getEvaByOrganisationId(organisationId, EvaType.publish),
        {
            enabled: true,
            onSuccess: (result) => {
                if (result.id !== null) {
                    if (result) {
                        dispatch(getEvaId(result.id));
                        dispatch(setPublishProtocols(result.metaMarkers));
                        setDescription(result.description);
                        setTitle(result.title);
                        setArchive(result.isEvaArchived);
                        setRestarted(result.isRestarted);
                        setTestMode(result?.isTestMode);

                        if (result.evaLock?.userData) {
                            setUpdateEvaUser(result.evaLock.userData);
                        }
                        return result;
                    }
                } else {
                    dispatch(getEvaId(null));
                }
            },
            onError: (error: Error) => {
                Sentry.captureException(error);
                appInsights.trackException({ error: error });
            },
        },
    );

    useQuery<DomainCategory[] | null>(['domain-category', organisationId], async () => {
        try {
            const data = await organisation.getAllDomainCategory(organisationId);
            if (data) {
                const threeData = data
                    .sort((a: DomainCategory, b: DomainCategory) => b.percentage - a.percentage)
                    .slice(0, 3);
                setTopThreeDomainCategory(threeData);
            }
            return data;
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });

    useEffect(() => {
        if (!draftEvaExist && !publishedEvaExist) {
            refetch();
        }
        dispatch(setProtocolIndex(null));
        dispatch(setOpenBluePrintModal(isCreateEvaModalOpen));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publishedEvaExist, isCreateEvaModalOpen, draftEvaExist]);

    const handleOpen = () => {
        if (userPermit) setCreateEvaModalOpen(true);
        setIsOpen(false);
        dispatch(setOpenLeftSidePanel(''));
    };

    const handleClose = () => {
        if (archive) {
            dispatch(getEvaId(null));
        } else {
            publishRefetch();
        }
        dispatch(setOpenLeftSidePanel(''));
        dispatch(setProtocolIndex(null));
        dispatch(setEvaluateMode(false));
        setCreateEvaModalOpen(false);
    };

    const getRandomMessage = (): void => {
        const randomKey = messageKeys[Math.floor(Math.random() * messageKeys.length)];
        setRandomMessage(i18next.t(`messages.${randomKey}`));
    };

    // Set random message on component mount
    useEffect(() => {
        getRandomMessage();
    }, []);

    if (isLoading || isFetching || publishLoading) {
        return <CustomLoader />;
    }

    const handlePlanetClick = () => {
        if (getuserPermit !== ROLES?.Member) {
            setCreateEvaModalOpen(true);
        }
        dispatch(setOpenLeftSidePanel(''));
    };

    const evaIDundefined = evaId === null;

    const updateTriggerData = async () => {
        await evaService.putEvaTriggerField();
    };

    return (
        <>
            <Tooltip id='orbit_eva' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {labelShow ? t('hide_labels') : t('display_labels')} </p>
            </Tooltip>
            <Tooltip id='create_update' offset={5} place='top' className='tooltip-box show_arrow'>
                {evaIDundefined ? <p> {t('createBlueprint')} </p> : <p> {t('updateBlueprint')} </p>}
            </Tooltip>

            <SubHeader labelName={'Oversight'} />

            <div className='main_outside_eva relative'>
                <div className='h-full'>
                    {isMobile ? (
                        <div className='eva_info_box'>
                            <h2> {title} </h2>
                            <p>{description}</p>
                        </div>
                    ) : (
                        <div className='graph_top state_of_entities'>
                            <div style={{ marginRight: '5px', display: 'none' }}>
                                <button onClick={updateTriggerData}>{t('trigger')}</button>
                            </div>
                            <div className='button_box'>
                                {evaIDundefined ? (
                                    <img className='no_eva_image' src={miniOrbit} alt='' />
                                ) : (
                                    <div
                                        data-tooltip-id='orbit_eva'
                                        onClick={() => setLabelShow(!labelShow)}
                                        className='orbit_eva flex justify-center items-center relative cursor-pointer'
                                        onMouseEnter={() => setOrbitHover(true)}
                                        onMouseLeave={() => setOrbitHover(false)}
                                    >
                                        {OrbitHover ? (
                                            <img
                                                src={labelShow ? miniOrbitHover : miniOrbitdisable}
                                                alt=''
                                                className={`${labelShow ? 'orbit_hover' : ''} cursor-pointer`}
                                            />
                                        ) : (
                                            <img
                                                onClick={() => setLabelShow(!labelShow)}
                                                src={labelShow ? miniOrbit : miniOrbitdisable}
                                                alt=''
                                                className={`${labelShow ? 'orbit_hover' : ''} cursor-pointer`}
                                            />
                                        )}

                                        {OrbitHover && (
                                            <>
                                                {description.length > 0 && (
                                                    <div className='eva_info_box'>
                                                        <h2> {title} </h2>
                                                        <p>{description}</p>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}

                                <div className='graph_buttons btn_wrapper  relative'>
                                    {evaIDundefined ? (
                                        <StyledButton
                                            data-tooltip-id='create_update'
                                            className='update_btn'
                                            onClick={handleOpen}
                                        >
                                            <EditIcon />

                                            {t('btnNames.create')}
                                        </StyledButton>
                                    ) : (
                                        <>
                                            <StyledButton
                                                data-tooltip-id='create_update'
                                                className='update_btn'
                                                onClick={handlePlanetClick}
                                            >
                                                <EditIcon />

                                                {t('btnNames.update')}
                                            </StyledButton>

                                            {result?.evaLock !== null ? (
                                                <div className='eva_update_user_img absolute'>
                                                    <img
                                                        className=' '
                                                        src={
                                                            updateEvaUser.documentId !== null
                                                                ? getDocumentUrl(updateEvaUser.documentId)
                                                                : emptyUser
                                                        }
                                                        alt='User Document'
                                                    />
                                                    <div className='tooltip_box'>
                                                        {updateEvaUser.firstName.length > 0 ||
                                                        updateEvaUser.lastName.length > 0 ? (
                                                            <>
                                                                <p>
                                                                    {`${updateEvaUser?.firstName} ${updateEvaUser?.lastName}`.trim()}
                                                                </p>
                                                            </>
                                                        ) : (
                                                            <p> {t('no_name_available')} </p>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className='state_of_entities flex  items-center absolute '>
                        <button className=' flex items-center justify-center  shield_btn text-[#0014FF] font-[500] rounded-[5px] border-[1px] border-[#0014FF] bg-[#0014FF0D]'>
                            {shieldSubscription ? (
                                <>
                                    {t('shield')}
                                    <Link to={`/organisation/${id}/change-tier`}>
                                        <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                                    </Link>
                                </>
                            ) : (
                                <>{t('oversight')}</>
                            )}
                        </button>

                        <button disabled className='upcoming_btn relative '>
                            <p>{t('state_of_entities')}</p>
                            <span className='absolute'>{t('upcoming')} </span>
                        </button>
                    </div>

                    {!isCreateEvaModalOpen && <CtrlLeftSidePanel />}

                    {isMobile && (
                        <div className='eva_bottom_content'>
                            {evaSummaryLoading ? (
                                <div className='flex mt-5 '>
                                    <div className='text-animation-load mx-auto'>
                                        <div className='gradient1' />
                                        <div className='gradient2' />
                                    </div>
                                </div>
                            ) : (
                                <div className='publish-eva-dynamic-text mt-5'>
                                    <p className='text-normal bg-clip-text bg-text-gradient text-fill-transparent text-center'>
                                        {evaSummary}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}

                    <>
                        {!isMobile && (
                            <>
                                {!isCreateEvaModalOpen && (
                                    <>
                                        {publishedEvaExist && (
                                            <PublishedEva
                                                labelShow={labelShow}
                                                isCreateEvaModalOpen={isCreateEvaModalOpen}
                                                setPublishedEvaExist={setPublishedEvaExist}
                                                setCreateEvaModalOpen={setCreateEvaModalOpen}
                                                setDraftEvaExist={setDraftEvaExist}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>

                    <>
                        {!isMobile && (
                            <>
                                {!publishedEvaExist && (
                                    <div className='relative publishedEva_exist flex flex-col w-full border border-grey justify-between items-center rounded-xl  bg-white'>
                                        <div />

                                        <div data-tour='eva' className='eva_before_img outset_loader'>
                                            <AnimatedCircle />
                                        </div>
                                        <div className='flex mt-5 eva_before_content '>
                                            <p className='text-normal bg-clip-text bg-text-gradient text-transparent ml-auto mr-auto mb-0'>
                                                {randomMessage}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                </div>

                {isCreateEvaModalOpen && (
                    <CreateEvaModel
                        open={isCreateEvaModalOpen}
                        handleClose={handleClose}
                        isPublished={archiveEvaId !== null ? true : false}
                        setPublishedEvaExist={setPublishedEvaExist}
                        existingEvaId={archiveEvaId !== null ? archiveEvaId : evaId}
                        isPublishedEvaModel={archiveEvaId !== null ? false : publishedEvaExist}
                        archive={archiveEvaId !== null ? true : archive}
                        setArchive={setArchive}
                        isArchived={archiveEvaId !== null ? true : false}
                        restarted={restarted}
                    />
                )}
            </div>
        </>
    );
};

const Eva = () => {
    return (
        <SubscriptionProvider>
            <CreateEvaModalContextProvider>
                <EvaComponent />
            </CreateEvaModalContextProvider>
        </SubscriptionProvider>
    );
};

export default observer(Eva);
