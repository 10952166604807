import React, { useEffect, useRef, useState } from 'react';

import './evaAnalysisStyle.css';
import { ReactComponent as PublishedDisablePlanet } from '../../../../../../assets/images/published-disable-planet.svg';
import { AnimatedCircle } from '../../../../../basic/AnimatedCircle';
import EvaKpi from './EvaKpi';
import Orbit from './Orbit';

interface EvaOrbitProps {
    enablePlanet: boolean;
    labelShow: boolean;
    isCreateEvaModalOpen: boolean;
    setCreateEvaModalOpen: (value: boolean) => void;
    evaId: string;
    setPublishedEvaExists: (value: boolean) => void;
    setDraftEvaExists: (value: boolean) => void;
}

export const EvaOrbit: React.FC<EvaOrbitProps> = ({
    enablePlanet,
    labelShow,
    isCreateEvaModalOpen,
    setCreateEvaModalOpen,
    evaId,
    setPublishedEvaExists,
    setDraftEvaExists,
}) => {
    const [publishedEvaExist, setPublishedEvaExist] = useState(true);

    useEffect(() => {
        if (!publishedEvaExist) {
            setPublishedEvaExists(false);
            setDraftEvaExists(false);
        }
    }, [publishedEvaExist, setPublishedEvaExists, setDraftEvaExists]);

    useEffect(() => {
        const mainOutsideEva = document.querySelector('.main_outside_eva');
        if (mainOutsideEva) {
            const toggleHighlight = (action: 'add' | 'remove') => {
                mainOutsideEva?.classList[action]('z-999');
            };
            const overviewElements = document.querySelectorAll('.overview_info');
            overviewElements?.forEach((element) => {
                element.addEventListener('mouseenter', () => toggleHighlight('add'));
                element.addEventListener('mouseleave', () => toggleHighlight('remove'));
            });

            // Cleanup event listeners on unmount
            return () => {
                overviewElements?.forEach((element) => {
                    element.removeEventListener('mouseenter', () => toggleHighlight('add'));
                    element.removeEventListener('mouseleave', () => toggleHighlight('remove'));
                });
            };
        }
    }, []);

    // const { data: protocolInsights } = useQuery(
    //     ['ProtocolInsights', evaId],
    //     async () => {
    //         if (evaId) {
    //             const protocolInsight: ProtocolInsights = await evaService.getProtocolInsights(evaId);
    //             const protocolInsightData = { ...protocolInsight, evaId };
    //             sessionStorage.setItem(`protocol-${evaId.slice(-8)}`, JSON.stringify(protocolInsightData));
    //             return protocolInsight;
    //         }
    //     },
    //     {
    //         refetchInterval: 7200000, // Poll every 2 hour
    //         refetchOnWindowFocus: false, // Refetch on  page refresh
    //         staleTime: 7200000, // Keep data fresh for 2 hour
    //         enabled: !!evaId,
    //         initialData: () => {
    //             const storedData = sessionStorage.getItem(`protocol-${evaId.slice(-8)}`);
    //             if (storedData) {
    //                 const parsedStoredData = JSON.parse(storedData);
    //                 if (parsedStoredData.evaId === evaId) {
    //                     return parsedStoredData;
    //                 }
    //             }
    //             return undefined;
    //         },
    //     },
    // );

    const [selectedKPIs, setSelectedKPIs] = useState<Set<number>>(new Set());

    const circleRef = useRef<HTMLDivElement>(null);

    const toggleKPI = (id: number) => {
        setSelectedKPIs((prev) => {
            const newSelection = new Set(prev);
            if (newSelection.has(id)) {
                newSelection.delete(id);
            } else {
                newSelection.add(id);
            }
            return newSelection;
        });
    };

    return (
        <>
            <EvaKpi selectedKPIs={selectedKPIs} toggleKPI={toggleKPI} evaId={evaId} />

            <div className='eva-orbit-space '>
                <div className=' relative w-full h-full'>
                    <div className='absolute w-full h-full top-0 left-0' style={{ pointerEvents: 'none' }}>
                        <Orbit
                            labelShow={labelShow}
                            isCreateEvaModalOpen={isCreateEvaModalOpen}
                            isEnableEva={enablePlanet}
                        />
                    </div>

                    <div className='flex justify-between items-center w-full h-full'>
                        <>
                            <div className='flex justify-center outer_img items-center relative w-full h-full'>
                                {enablePlanet ? (
                                    <div className='circle_box relative'>
                                        <div className='center_orbit   EVA absolute' ref={circleRef} />
                                        <AnimatedCircle />
                                    </div>
                                ) : (
                                    <PublishedDisablePlanet className='svgComponent absolute' />
                                )}
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};
