import { Skeleton } from '@mui/material';
import clsx from 'clsx';

import HorizontalSeparator from '../basic/HorizontalSeparator';


type SessionPostSkeletonProps = {
    number: number;
    className?: string;
};
const SessionSkeleton = ({ number, className }: SessionPostSkeletonProps) => {
    const sessions: JSX.Element[] = [];
    for (let i = 0; i < number; i++) {
        sessions.push(
            <div className='w-full max-w-5xl mx-auto relative space-y-3 md:px-0 px-5' key={i}>
                <div className='w-full flex space-x-4 items-center'>
                    <Skeleton
                        variant='rectangular'
                        width={32}
                        height={32}
                        sx={{
                            border: '1px solid rgba(15, 16, 68, 0.07)',
                            borderRadius: '10px',
                            background: 'rgba(15, 16, 68, 0.03)',
                        }}
                    />
                    <Skeleton
                        variant='rectangular'
                        sx={{
                            fontSize: '16px',
                            borderRadius: '50px',
                            background: 'rgba(15, 16, 68, 0.03)',
                        }}
                        width={85}
                        height={16}
                    />
                </div>
                <div className='w-full rounded-xl bg-white border border-grey'>
                    <div className='w-full flex flex-col gap-2 p-4'>
                        <div className='w-full flex items-center justify-between'>
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    fontSize: '16px',
                                    borderRadius: '50px',
                                    background: 'rgba(15, 16, 68, 0.03)',
                                }}
                                width={100}
                                height={16}
                            />
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    fontSize: '16px',
                                    borderRadius: '50px',
                                    background: 'rgba(15, 16, 68, 0.03)',
                                }}
                                width={70}
                                height={16}
                            />
                        </div>
                        <Skeleton
                            variant='rectangular'
                            sx={{
                                fontSize: '16px',
                                borderRadius: '50px',
                                background: 'rgba(15, 16, 68, 0.03)',
                            }}
                            width={'auto'}
                            height={16}
                        />
                    </div>
                    <HorizontalSeparator />
                    <div className='w-full flex flex-col gap-2 p-4'>
                        <div className='w-full flex items-center justify-between'>
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    fontSize: '16px',
                                    borderRadius: '50px',
                                    background: 'rgba(15, 16, 68, 0.03)',
                                }}
                                width={100}
                                height={16}
                            />
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    fontSize: '16px',
                                    borderRadius: '50px',
                                    background: 'rgba(15, 16, 68, 0.03)',
                                }}
                                width={70}
                                height={16}
                            />
                        </div>
                        <Skeleton
                            variant='rectangular'
                            sx={{
                                fontSize: '16px',
                                borderRadius: '50px',
                                background: 'rgba(15, 16, 68, 0.03)',
                            }}
                            width={'auto'}
                            height={16}
                        />
                    </div>
                    <HorizontalSeparator />
                    <div className='w-full flex flex-col gap-2 p-4'>
                        <div className='w-full flex items-center justify-between'>
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    fontSize: '16px',
                                    borderRadius: '50px',
                                    background: 'rgba(15, 16, 68, 0.03)',
                                }}
                                width={100}
                                height={16}
                            />
                            <Skeleton
                                variant='rectangular'
                                sx={{
                                    fontSize: '16px',
                                    borderRadius: '50px',
                                    background: 'rgba(15, 16, 68, 0.03)',
                                }}
                                width={70}
                                height={16}
                            />
                        </div>
                        <Skeleton
                            variant='rectangular'
                            sx={{
                                fontSize: '16px',
                                borderRadius: '50px',
                                background: 'rgba(15, 16, 68, 0.03)',
                            }}
                            width={'auto'}
                            height={16}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return <div className={clsx('space-y-10', className)}>{sessions}</div>;
};

export default SessionSkeleton
