import { BlobServiceClient } from '@azure/storage-blob';
import * as Sentry from '@sentry/react';

import { appInsights } from '../../AppInsights';
import { store } from '../../store';
import { DocumentInfo } from '../../store/DocumentsStore';
import { get, post } from '../../utils/network';

type CreateDocumentResponse = {
    document: DocumentInfo;
    blobSasUrl: string;
};

export type DocumentType =
    | 'profilePicture'
    | 'sharedFile'
    | 'organisationLogo'
    ;

export class DocumentsService {
    async createDocument(
        fileName: string,
        fileSize: number,
        fileType: string,
        sharedWithUserId: number,
        type?: DocumentType,
        sharedWithSpaceId?: string,
        isPublic?: boolean,
        organisationLogoId?: number,
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const createDocumentResponse: CreateDocumentResponse = await post('/documents', {
            fileName,
            fileSize,
            fileType,
            sharedWithUserId,
            type,
            sharedWithSpaceId,
            isPublic,
            organisationLogoId
        });

        return createDocumentResponse;
    }

    async uploadFile(
        file: File,
        createDocumentResponse: CreateDocumentResponse,
        onProgress?: (progress: number) => void,
    ) {
        try{
            const client = new BlobServiceClient(createDocumentResponse.blobSasUrl);
            const containerName = 'documents';
            const containerClient = client.getContainerClient(containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(createDocumentResponse.document.blobName);
            await blockBlobClient.uploadData(file, {
                onProgress: (progress) => {
                    const percent = Math.floor((progress.loadedBytes / file.size) * 100);
                    onProgress?.(percent);
                },
            });
            store.documents.pushDocument({ ...createDocumentResponse.document });
        }catch(err){
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async getAll() {
        try{
            return await get('/documents/get-all');
        }catch(err){
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }
}
