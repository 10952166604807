import { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { FormControl } from '@mui/material';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/icon-check-filled.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/icon-edit-fill.svg';
import { ReactComponent as Flag } from '../../../../../../assets/icons/icon-flag.svg';
import { ReactComponent as TestUser } from '../../../../../../assets/icons/icon-test-user.svg';
import { setOrbitLoader } from '../../../../../../Redux/EvaInfo';
import { Loading } from '../../../../../loading';
import { StyledTextField } from '../createEva/StyledTextField';
import ChangeResponse, { ChangeResponseType } from './ChangeResponse.component';

export enum UserType {
    User1 = 'User1',
    User2 = 'User2',
}

const useStyles = makeStyles(() => ({
    evaField: (props: { userType: string }) => ({
        borderRadius: 7,
        backgroundColor: 'transparent',
        width: '100%',
        overflow: 'hidden',
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiInput-underline input': {
            textAlign: 'start',
            color: props.userType === UserType.User1 ? '#B0E1FD' : '#8799FF',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 0.5,
            textAlign: 'start',
        },
    }),
}));

const UserResponseCard = ({
    userType,
    text,
    protocolName,
    evalScore,
    evalFlagged,
    onInputChange,
    handleActionChange,
    setEditUserResponse,
    isEditing,
    onEditToggle,
}: {
    userType: string;
    text: string;
    protocolName: string;
    evalScore: number;
    evalFlagged: boolean;
    onInputChange: (newText: string) => Promise<{ score: number; flagged: boolean }>;
    handleActionChange: (text: string, posneg: boolean) => void;
    setEditUserResponse: React.Dispatch<React.SetStateAction<boolean>>;
    isEditing: boolean;
    onEditToggle: () => void;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [input, setInput] = useState(text);
    const [score, setScore] = useState(evalScore);
    const [flagged, setFlagged] = useState(evalFlagged);
    const [posneg, setPosNeg] = useState<boolean>(evalFlagged);
    const [loading, setLoading] = useState(false);
    const classes = useStyles({ userType });
    useEffect(() => {
        dispatch(setOrbitLoader(loading));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        setInput(text);
    }, [text]);

    const toggleEditResponse = async () => {
        onEditToggle();
        setEditUserResponse(true);
    };

    const handleSaveResponse = async () => {
        setLoading(true);
        onEditToggle();
        setEditUserResponse(false);
        try {
            const result = await onInputChange(input);
            if (result) {
                setLoading(false);
                setScore(result.score);
                setFlagged(result.flagged);
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
            }
        }
    };

    const handleMouseEnter = () => {
        if (protocolName) setIsHovered(true); // Set hover state to true on hover
    };

    const handleMouseLeave = () => {
        if (protocolName) setIsHovered(false); // Set hover state to false when not hovering
    };

    useEffect(() => {
        setScore(evalScore);
    }, [evalScore]);

    useEffect(() => {
        setFlagged(evalFlagged);
    }, [evalFlagged]);

    return (
        <div>
            <div className='w-full flex'>
                <div className='group w-full flex relative'>
                    <div className='flex flex-col items-center'>
                        <div className='ml-2'>
                            <TestUser className='w-4 h-4' fill={userType === UserType.User1 ? '#B0E1FD' : '#8799FF'} />
                        </div>

                        <div className='flex items-center space-x-2 justify-center mt-2'>
                            <div className='flex items-center mr-1'>
                                {flagged && (
                                    <div className='user-flag'>
                                        <Flag className='w-2 h-2' fill='#60F4C8' />
                                    </div>
                                )}
                            </div>
                            <button onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {!isHovered ? (
                                    <div className='flex items-center justify-center user-score'>
                                        <p>{score.toFixed(0)}</p>
                                    </div>
                                ) : (
                                    <ChangeResponse
                                        type={flagged ? ChangeResponseType.Positive : ChangeResponseType.Negative}
                                        posneg={posneg}
                                        setPosNeg={setPosNeg}
                                        handleActionChange={() => handleActionChange(input, posneg)}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                    {!isEditing ? (
                        <div className='w-full flex relative'>
                            <div
                                className={clsx(
                                    'w-full ml-4 text-sm overflow-hidden',
                                    userType === UserType.User1 ? 'user1-response' : 'user2-response',
                                    loading && 'opacity-50',
                                )}
                            >
                                {input}
                            </div>
                            {loading && (
                                <div className='w-full flex absolute justify-center text-xxs mt-4'>
                                    <Loading color='evaluate' size={24} />
                                </div>
                            )}
                            <button
                                className='realtive flex group-hover:opacity-100 opacity-0 ml-1 mr-1 justify-end edit_btn'
                                onClick={() => toggleEditResponse()}
                                disabled={loading}
                            >
                                <div className='edit'>
                                    <EditIcon className='w-4 h-4' fill='#60F4C880' />
                                </div>
                                <div className='edit_tooltip_box'>
                                    <p>{t('btnNames.edit')}</p>
                                </div>
                            </button>
                        </div>
                    ) : (
                        <div className='flex w-full ml-4'>
                            <FormControl fullWidth>
                                <StyledTextField
                                    className={`${classes.evaField}`}
                                    font={'small'}
                                    fontWeight={'normal'}
                                    placeHolder={''}
                                    value={input}
                                    setValue={setInput}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSaveResponse();
                                        }
                                    }}
                                    showRequiredStar={false}
                                />
                            </FormControl>
                            <button className='edit ml-1 mr-1 edit_btn' onClick={() => handleSaveResponse()}>
                                <CheckIcon className='w-4 h-4' fill='#60F4C880' />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserResponseCard;
