import clsx from 'clsx';

import leftIconPath from '../../assets/icons/icon-arrow-left.svg';
import rightIconPath from '../../assets/icons/icon-arrow-right.svg';
import { getDisplayedPageButtons, MAX_PAGE_BUTTONS_COUNT } from '../../utils/getDisplayedPageButtons';

export const TablePagination = ({
    page,
    pageCount,
    setPage,
}: {
    page: number;
    pageCount: number;
    setPage: (page: number) => void;
}) => {
    const displayedPageButtons = getDisplayedPageButtons(page, pageCount);
    const onClick = (value: string | number, index: number) => {
        if (displayedPageButtons[MAX_PAGE_BUTTONS_COUNT - 2] !== '...') {
            if (typeof value === 'number' || parseInt(value) === 1) {
                setPage(typeof value === 'number' ? value : 1);
            } else {
                setPage(pageCount - (MAX_PAGE_BUTTONS_COUNT - 2));
            }
        } else {
            if (displayedPageButtons[1] !== '...') {
                if (typeof value === 'number') {
                    setPage(value);
                } else {
                    setPage(MAX_PAGE_BUTTONS_COUNT - 2);
                }
            } else {
                if (typeof value === 'number') {
                    setPage(value);
                } else {
                    // if the dots are the left ones vs the right ones
                    if (index === 1) {
                        setPage(Math.max(1, page - Math.floor(MAX_PAGE_BUTTONS_COUNT / 2) + 1));
                    } else {
                        setPage(Math.min(pageCount, page + Math.floor(MAX_PAGE_BUTTONS_COUNT / 2) - 1));
                    }
                }
            }
        }
    };
    return (
        <div className='flex flex-row justify-between items-center space-x-4'>
            <img src={leftIconPath} className='h-3 cursor-pointer' onClick={() => setPage(Math.max(1, page - 1))} />
            {displayedPageButtons.map((button, index) => {
                return (
                    <div
                        className={clsx(
                            'text-sm text-darker text-opacity-50 cursor-pointer',
                            button === page &&
                                'bg-bberry-blue w-6 h-6 rounded-md text-opacity-100 text-white font-semibold flex items-center justify-center',
                        )}
                        onClick={() => onClick(button, index)}
                    >
                        {button}
                    </div>
                );
            })}
            <img
                src={rightIconPath}
                className='h-3 cursor-pointer'
                onClick={() => setPage(Math.min(pageCount, page + 1))}
            />
        </div>
    );
};
