import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import i18n from '../../../../../../../i18n';
import { RootState } from '../../../../../../../Redux/store';
import { MetaMarkerItem } from '../../../types';

interface ProtocolChartProps {
    singeProtocolObj: MetaMarkerItem;
    timeFilter: string;
}

const ProtocolChart: React.FC<ProtocolChartProps> = ({ singeProtocolObj, timeFilter }) => {
    const { t } = useTranslation();
    const isOpenBluePrintModal = useSelector((state: RootState) => state.EvaInfo.isOpenBluePrintModal);
    const graphData = useSelector((state: RootState) => state.EvaInfo.leftSideGraphProtocol);
    const filterFormat = graphData.dateRange;
    let timeFormat = '';
    const getProtocolGraphData = graphData?.protocols.find(
        (item: { protocolName: string }) =>
            item.protocolName == singeProtocolObj?.protocolIdentification?.protocol_name,
    );

    let categories: number[] | string[] = [];

    const chartData = getProtocolGraphData?.data;
    const maxValue = chartData && Math.max(...chartData) + 1;
    const currentYear = new Date().getFullYear();
    const protocolSeries = chartData && chartData.length > 0 ? chartData : Array(filterFormat?.length).fill(0);
    const series = [
        {
            name: getProtocolGraphData?.protocolName || '',
            data: protocolSeries,
        },
    ];

    switch (timeFilter) {
        case 'Year':
        case 'All':
            timeFormat = 'MMM YYYY';
            categories = filterFormat?.map((date) => {
                if (date.includes('-')) {
                    // If the format is MM-YYYY
                    const [month, year] = date.split('-');

                    return moment(`${year}-${month}-01`, 'YYYY-MM-DD')
                        .locale(i18n.language || 'en')
                        .format(timeFormat)
                        .replace(/^\w/, (c) => c.toUpperCase());
                } else {
                    // If the format is only YYYY
                    return moment(`${date}-01-01`, 'YYYY-MM-DD')
                        .locale(i18n.language || 'en')
                        .format('YYYY');
                }
            });
            break;
        case 'Month':
            timeFormat = 'D'; // Day of the month
            categories = filterFormat?.map((date) => {
                const [day, month, year] = date.split('-');
                return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')
                    .locale(i18n.language || 'en')
                    .format(timeFormat);
            });
            break;
        case 'Week':
            categories = filterFormat?.map((date) => {
                const [day, month] = date.split('-');
                return moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD')
                    .locale(i18n.language || 'en')
                    .format('ddd'); // 'dddd' returns full day name
            });
            break;
        case 'Day':
            timeFormat = 'HH:mm';
            categories = filterFormat;

            break;
        default:
            categories = [];
            break;
    }

    const options: ApexOptions = {
        chart: {
            type: 'area',
            width: '100%',
            toolbar: { show: false },
            stacked: false,
            zoom: { enabled: false },
        },
        colors: isOpenBluePrintModal ? ['#6FBFE1'] : ['#6884E1'],
        dataLabels: { enabled: false },
        markers: {
            size: 0,
            strokeWidth: 0,
            strokeColors: '#fff',
            colors: [isOpenBluePrintModal ? '#6FBFE180' : '#11113F40'],
            hover: { size: 0 },
            shape: 'circle',
        },

        stroke: { curve: 'smooth', width: 1 },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.3,
                opacityTo: 0.0,
                stops: [0, 90, 0],
            },
        },
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    colors: isOpenBluePrintModal ? 'rgba(111, 191, 225, 0.5)' : 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
            },
            position: 'bottom',
            axisTicks: { show: false },
            axisBorder: { show: false },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: [isOpenBluePrintModal ? 'rgba(111, 191, 225, 0.5)' : 'rgba(15, 16, 68, 0.75)'],
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                },
                formatter: (value: number): string => {
                    if (value === 0) return t('protocolDetail.low');
                    if (value === maxValue || value === 0 ? 5 : maxValue) return t('protocolDetail.high');
                    return '';
                },
            },
            max: maxValue,
            min: 0,
        },
        tooltip: {
            followCursor: true,
            enabled: true,
            shared: true,
            intersect: false,
            custom: ({ series, seriesIndex, dataPointIndex }) => {
                const value = series[seriesIndex][dataPointIndex];
                return `<div style="padding: 7px 10px; background-color: #0014ff; border-radius: 5px;">
                            <p style="font-size: 10px; color: #fff;">${value}</p>
                        </div>`;
            },
        },
        legend: { show: false },
        grid: {
            show: true,
            borderColor: '#0f104412',
            padding: { left: 10, right: 30 },
        },
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Chart options={options} series={series} type='area' height={168} />
        </div>
    );
};

export default ProtocolChart;
