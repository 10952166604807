import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIconNuance } from '../../../../../assets/icons/icon-close.svg';
import { setApiNuances } from '../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../Redux/store';
import { ThumbData } from '../types';
import './slider/slider.css';
import { convertToThumbData } from './createEva/EvaCompare';

interface NuancesMappingProps {
    setNuancesData: React.Dispatch<React.SetStateAction<ThumbData[]>>;
    setNuancesDataValues: React.Dispatch<React.SetStateAction<number[]>>;
    nuancesDataValues: number[];

    fileInfo: {
        fileName: string;
        fileSize: string;
        fileType: string;
    };
    errorMsg: string | null;
}
const NuancesMapping: React.FC<NuancesMappingProps> = ({
    setNuancesDataValues,
    setNuancesData,

    fileInfo,
    errorMsg,
}) => {
    const apiNuances = useSelector((state: RootState) => state.EvaInfo.apiNuances);

    const dispatch = useDispatch();
    const deleteNuance = (indexToDelete: number) => {
        const updatedNuances = apiNuances.filter((_, index) => index !== indexToDelete);

        dispatch(setApiNuances(updatedNuances));
    };

    // new design
    const groupedData = convertToThumbData(apiNuances);
    const thresholdValues: number[] = groupedData?.map((item) => item?.value);

    useEffect(() => {
        if (apiNuances) {
            setNuancesDataValues(thresholdValues);
            setNuancesData(groupedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiNuances]);
    return (
        <>
            <div className='main_slider right_side_nuances'>
                {apiNuances?.length > 0 ? (
                    <div className='nuance_list'>
                        <ul>
                            {apiNuances?.map((obj, index) => {
                                return (
                                    <li className='flex items-center'>
                                        <span className='nuances_count flex items-center justify-center'>
                                            {obj.threshold}
                                        </span>
                                        <div className='nuances_name'>
                                            <p> {obj.nuance}</p>
                                        </div>
                                        <button
                                            className='flex items-center justify-center'
                                            onClick={() => deleteNuance(index)}
                                        >
                                            <CloseIconNuance />
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                ) : (
                    <>
                        {errorMsg !== null ? (
                            <span className='error_msg'> {errorMsg} </span>
                        ) : (
                            <span className='nodata'>No Data Found</span>
                        )}
                    </>
                )}

                <h6>{`${fileInfo?.fileName}’s Protocol View `}</h6>
            </div>
        </>
    );
};

export default NuancesMapping;
