import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as DownArrow } from '../../../../../../../../src/assets/icons/DownArrow.svg';
import { ReactComponent as InfoIcon } from '../../../../../../../../src/assets/icons/InfoIcon.svg';
import { ReactComponent as KpiMeter } from '../../../../../../../../src/assets/icons/KpiMeter.svg';
import { ReactComponent as SearchIcon } from '../../../../../../../../src/assets/icons/serachIocn.svg';
import { ReactComponent as SettingIcon } from '../../../../../../../../src/assets/icons/Setting_Icon.svg';
import { ReactComponent as SortIcon } from '../../../../../../../../src/assets/icons/SortArrow_Icon.svg';
import { setChildScreen, setSessionsId } from '../../../../../../../Redux/SessionsProtocols';
import { RootState } from '../../../../../../../Redux/store';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import { msgHistory, reportSummary } from '../../../../evaAnalytics/components/analyticsChart/types';
import EvaDialog from '../../EvaDialog.component';
import { OptionData, protocolTypeIcon } from '../../ProtocolTypeList';
import KpiMetricItem from './KpiMetricItem';
import MarkdownRenderer from './MarkdownRender';

interface ReportListProps {}

const ReportList: React.FC<ReportListProps> = () => {
    const { t } = useTranslation();
    const [sortVal, setSortVal] = useState('');
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

    const evaService = new EvaService();

    const screen = useSelector((state: RootState) => state.SessionsProtocols.screen);

    const [searchVal, setSearchVal] = useState<string>('');
    const [deleteId, setDeleteId] = useState<string>('');
    const [showInput, setShowInput] = useState<boolean>(false);
    const [summary, setSummary] = useState([]);
    const [remainingRecords, setRemainingRecords] = useState([]);

    const evaId = useSelector((state: RootState) => state.SessionsProtocols.EvaId);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);

    const sortType = ['Newest First', 'Older First', 'Sort A-Z', 'Sort Z-A'];
    const [showSortList, setShowSortList] = useState(false);
    const oversight = screen === 'Oversight';

    const {
        data: summaryData,
        refetch,
        isFetching,
    } = useQuery(
        ['reportSummary', evaId, sortVal, searchVal, page],
        async () => {
            if (evaId) {
                const reportSummary = await evaService.getReportSummary(evaId, sortVal, searchVal, String(page));

                return reportSummary;
            }
            return [];
        },
        {
            enabled: !!evaId,
        },
    );

    const { refetch: deleteReportRefetch } = useQuery(
        ['deleteReportData'],
        async () => {
            if (deleteId) {
                const reportData = await evaService.DeleteHistory(evaId, [deleteId], true);
                if (reportData.status === 200) {
                    return reportData;
                }
            }
        },
        {
            enabled: false,
            onSuccess: () => {
                dispatch(setChildScreen(''));
                refetch();
            },
        },
    );

    //when more records in report list
    useEffect(() => {
        if (summaryData) {
            if (page === 1) {
                // Set the summary and remaining records for the first page
                setSummary(summaryData?.summary.slice(0, 30));
                setRemainingRecords(summaryData?.summary.slice(30));
            } else {
                // Update remaining records
                setRemainingRecords(summaryData?.summary);
                setSummary((prevSummary) => [...prevSummary, ...remainingRecords]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryData, page]);

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const SummaryList = document.querySelector('.msg_history');

        // Scroll to the top when sortVal is updated

        // Define the scroll handler with debounce
        const handleScroll = debounce(() => {
            if (SummaryList) {
                const { scrollTop, scrollHeight, clientHeight } = SummaryList;
                // Check if the user has scrolled near the bottom of the container
                setPage((pre: number) => {
                    if (summaryData?.totalPage > pre) {
                        if (Math.abs(scrollTop) + clientHeight > scrollHeight - 10) {
                            return pre + 1;
                        }
                    }
                    return pre;
                });
            }
        }, 0);

        // Attach the scroll event listener
        if (SummaryList) {
            SummaryList.addEventListener('scroll', handleScroll);
        }

        // Cleanup: Remove the scroll event listener when the component unmounts
        return () => {
            if (SummaryList) {
                SummaryList.removeEventListener('scroll', handleScroll);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryData, page, isFetching]); // Add sortVal to dependencies

    // Debounce utility function to limit the rate of function execution
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
        let timeoutId: NodeJS.Timeout | null;

        return function (...args: Parameters<T>): void {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const handleChangeSortType = (name: string) => {
        setSortVal(name);
        const SummaryList = document.querySelector('.msg_history');
        if (name && SummaryList) {
            SummaryList.scrollTop = 0; // Scroll to the top of the element
            setPage(1);
        }
    };

    const getsSessionId = (sessionId: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        dispatch(setSessionsId(sessionId));
        dispatch(setChildScreen('Report'));
    };

    if (summaryData?.isReportExist == false) {
        return (
            <div className='flex report_list report_list no_protocol  gap-5 flex-col w-full  justify-center items-center'>
                <div className='img_box' />
                <div>
                    <h6> {t('reports.firstReport')} </h6>
                    <p> {t('reports.askInsight')} </p>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='main_report_list'>
                {summary?.length == 0 && oversight && summaryData?.isReportExist == false ? (
                    ''
                ) : (
                    <>
                        <div className='summaries_information panel_top_bar flex justify-center'>
                            <div className={` input_box relative ${showInput ? 'showInput' : ''}`}>
                                <SearchIcon className='cursor-pointer' onClick={() => setShowInput(!showInput)} />
                                <input
                                    type='text'
                                    onChange={(e) => {
                                        setSearchVal(e.target.value);
                                        setPage(1);
                                    }}
                                />
                            </div>
                            <div
                                className={`sort hovered flex relative justify-center gap-2 items-center cursor-pointer  ${
                                    showSortList ? 'active' : ''
                                }`}
                                onClick={() => setShowSortList(!showSortList)}
                            >
                                <SortIcon />
                                <DownArrow className='arrow' />

                                {showSortList && (
                                    <div
                                        className={`sort_list absolute ${showSortList ? 'active' : ''}`}
                                        onClick={(event) => event.stopPropagation()}
                                    >
                                        <div className='sort_head flex gap-4 items-center '>
                                            <SortIcon />
                                            {t('sort_by')}
                                        </div>
                                        <ul>
                                            {sortType.map((item) => {
                                                return (
                                                    <li
                                                        className={`${sortVal === item ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setShowSortList(false);
                                                            handleChangeSortType(item);
                                                        }}
                                                    >
                                                        {t(`sortOptions.${item}`)}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}

                <>
                    <div
                        className={`${
                            screen && screen === 'Oversight' ? 'oversight_scroll' : ''
                        } msg_history scrollbar`}
                    >
                        {summary && summary?.length > 0 ? (
                            summary?.map((obj: reportSummary, index: number) => {
                                let timeLabel: string;

                                switch (obj.time) {
                                    case 'Today':
                                        timeLabel = t('time_labels.today');
                                        break;
                                    case 'Recent':
                                        timeLabel = t('time_labels.recent');
                                        break;
                                    case 'Last Week':
                                        timeLabel = t('time_labels.last_week');
                                        break;
                                    case 'This Month':
                                        timeLabel = t('time_labels.this_month');
                                        break;
                                    case 'Older':
                                        timeLabel = t('time_labels.older');
                                        break;
                                    default:
                                        timeLabel = obj.time;
                                }

                                return (
                                    <div className='details'>
                                        <label className='time'> {timeLabel} </label>

                                        {obj.msgHistory?.map((item: msgHistory, index: number | null | undefined) => {
                                            const { metrics, protocols, sessionId, version } = item;
                                            return (
                                                <div
                                                    key={index}
                                                    className={`${
                                                        obj.time !== 'Today' ? 'prev_data' : ''
                                                    } msg_content relative mb-2 cursor-pointer`}
                                                    onClick={(event) => {
                                                        getsSessionId(sessionId, event);
                                                    }}
                                                >
                                                    <div
                                                        className='info_box'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (sessionId === deleteId) {
                                                                setDeleteId('');
                                                            } else {
                                                                setDeleteId(sessionId);
                                                            }
                                                        }}
                                                    >
                                                        <InfoIcon />

                                                        {deleteId === sessionId && (
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setIsOpenDeleteModal(true);
                                                                }}
                                                                className='action hovered  flex relative justify-center gap-2 items-center cursor-pointer'
                                                            >
                                                                <div className='action-list absolute'>
                                                                    <ul>
                                                                        <li>{t('btnNames.delete')}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className='head flex items-center justify-between'>
                                                        <h1>{item.title}</h1>
                                                    </div>
                                                    <p className='dis'>
                                                        {t('creation_date', {
                                                            date: item.time,
                                                        })}
                                                    </p>
                                                    <p className='dis'>
                                                        <MarkdownRenderer>{item.dis ?? ''}</MarkdownRenderer>
                                                    </p>

                                                    <div className='kpis_box flex items-center justify-between'>
                                                        <div className='flex gap-2 items-center'>
                                                            {metrics && metrics.length > 0 && (
                                                                <div className='flex meter_box items-center gap-1'>
                                                                    <KpiMeter />
                                                                    <div className='tooltip_box items-start'>
                                                                        {metrics.map((metric, index) => (
                                                                            <KpiMetricItem
                                                                                key={index}
                                                                                metric={metric}
                                                                                OptionData={OptionData}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {protocols && protocols?.length > 0 && (
                                                                <div className='protocol_icon relative flex items-center justify-center'>
                                                                    <SettingIcon />
                                                                    <div className='tooltip_box items-start'>
                                                                        {protocols?.map((protocol, index) => (
                                                                            <p
                                                                                key={index}
                                                                                className='flex gap-1 items-center'
                                                                            >
                                                                                <img
                                                                                    src={protocolTypeIcon(
                                                                                        protocol.protocolType,
                                                                                    )}
                                                                                    alt={protocol.protocolType}
                                                                                />
                                                                                <span>
                                                                                    {protocol.name
                                                                                        ? protocol.name
                                                                                        : t('unknown_protocol')}
                                                                                </span>
                                                                            </p>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='versions'>
                                                            <p>
                                                                {version} {t('versions')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })
                        ) : (
                            <>{summaryData?.isReportExist == true && <p className='no_data'> {summaryData?.msg} </p>}</>
                        )}
                    </div>
                </>

                <EvaDialog
                    open={isOpenDeleteModal}
                    setClose={setIsOpenDeleteModal}
                    onConfirm={deleteReportRefetch}
                    disabled={false}
                    title={t('reports.deleteReport')}
                    content={t('reports.deleteDescription')}
                    confirmButtonText={t('btnNames.delete')}
                    buttonVariant='rejectRed'
                />
            </div>
        </>
    );
};

export default ReportList;
