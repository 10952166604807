import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../assets/icons/icon-arrow-left-long.svg';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';
import CreditCardDetails from '../admin/paymentMethods/CreditCardDetails';
import CardCheckButton from '../basic/CardCheckButton.component';
import HorizontalSeparator from '../basic/HorizontalSeparator';

const AddPayment = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { cardTypeCheck, setCardTypeCheck } = usePaymentMethods();
    const { id: organisationId } = useParams<{ id: string }>();
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    return (
        <div>
            <button
                onClick={() => history.goBack()}
                className='absolute right-6 flex items-center space-x-2 ml-auto h-8 mt-8'
            >
                <BackIcon />
                <p className='text-sm text-darker text-opacity-75'>{t('btnNames.back')}</p>
            </button>
            <div className='w-full mx-auto flex flex-col text-sm text-darker text-opacity-75 max-w-5xl'>
                <p className='mb-6 mt-10 text-center'>{t('billing.addPayment')}</p>
                <CardCheckButton checked={cardTypeCheck} onClick={() => setCardTypeCheck(!cardTypeCheck)} />
                <HorizontalSeparator className='mt-6 mb-4' />
                <CreditCardDetails isCardTypeChecked={cardTypeCheck} />
            </div>
        </div>
    );
};

export default AddPayment;
