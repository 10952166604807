import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { mockable } from '../../utils/mockable';
import { useForm } from '../../utils/useForm';
import { and, isEmail, required } from '../../utils/validation';
import { Button } from '../basic/Button.component';
import { IconAlert } from '../basic/IconAlert.component';
import { Input } from '../basic/Input.component';
import { useServices } from '../ServiceProvider';

export const useReSendForm = mockable(() => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { auth } = useServices();
    const [sent, setSent] = useState<boolean>(false);
    const { fields, isValid, setAllVisited } = useForm({
        fields: {
            email: {
                validation: and(
                    (value: string) => isEmail(value, t),
                    (value: string) => required(value, t),
                ),
            },
        },
    });

    const onSubmit = async () => {
        setIsLoading(true);
        setAllVisited();
        if (isValid()) {
            try {
                await auth.sendConfirmationEmail(fields.email.value);
                setSent(true);
                setError(null);
            } catch (err) {
                setError('This email does not match with an account');
                setSent(false);
            }
        } else {
            setError(t('validation.invalidEmail'));
            setSent(false);
        }
        setIsLoading(false);
    };

    return { onSubmit, fields, isLoading, error, sent, setSent };
});

export type ReSendFormProps = {
    setSent: (bool: boolean) => void;
    setEmail: (email: string) => void;
};

// component used to re send a confirmation email when completing registration
export const ReSendForm = ({ setSent, setEmail }: ReSendFormProps) => {
    const { t } = useTranslation();
    const { onSubmit, fields, isLoading, error, sent } = useReSendForm();

    const isExpert: boolean = localStorage.getItem('expert-sign-up') === 'true';

    if (isLoading) {
        setSent(sent);
        setEmail(fields.email.value);
    }

    return (
        <form
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}
            className='overflow-hidden transition-shadow w-full'
        >
            <div className={`space-y-6 ${isExpert ? 'text-darker text-opacity-75' : 'text-darker'}`}>
                <h1 className={`text-tiny font-semibold ${isExpert ? 'md:text-base' : 'md:text-xl'}`}>
                    {t('signIn&Up.resendConfirmEmail')}
                </h1>
                <div className='space-y-5'>
                    <h1 className={`text-sm ${isExpert ? '' : 'md:text-tiny'}`}>
                    {t('signIn&Up.resendConfirmEmailMsg')}
                    </h1>
                    <Input
                        label='Email'
                        placeholder='Email'
                        type='string'
                        {...fields.email}
                        className='rounded-[50px]'
                    />
                    {error !== null && <IconAlert message={error} />}
                </div>
                <div className='flex'>
                    <Button
                        className='flex-grow rounded-[50px] capitalize h-10'
                        type='submit'
                        disabled={isLoading}
                        variant='bberryBlue'
                        size='oval'
                        children={t('signIn&Up.sendConfirmEmail')}
                    />
                </div>
            </div>
        </form>
    );
};
