import { useState, useEffect } from 'react';

import ClickAwayListener from '@mui/base/ClickAwayListener';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../assets/icons/icon-edit-fill.svg';
import { ReactComponent as MoreIcon } from '../../assets/icons/icon-more2.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg';
import { useSpaces } from '../../hooks/useSpaces';
import { store } from '../../store';
import { AssociatedMemberInfo, SpaceInfo } from '../../store/SpacesStore';
import { handleSpaceDeleted } from '../../utils/chromeExtension';
import { getDocumentUrl } from '../../utils/getDocumentUrl';
import { Button } from '../basic/Button.component';
import { ProfilePicture } from '../basic/ProfilePicture';
import SpaceLogo from '../basic/SpaceLogo.component';
import { ColumnConfig, Sort, Table } from '../manage/Table.component';
import TableSkeleton from '../skeleton/TableSkeleton.component';
import DeleteDialog from './styles/DeleteDialog.component';

const MAX_SPACE_DISPLAYED = 8;

const SpacesTable = observer(() => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [input, setInput] = useState('');
    const [selection, setSelection] = useState<SpaceInfo[]>([]);
    const [spacesData, setSpacesData] = useState<SpaceInfo[]>();
    const [showPopUp, setShowPopUp] = useState(false);
    const [selectedSpace, setSelectedSpace] = useState<SpaceInfo | undefined>();
    const [showDialog, setShowDialog] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);
    const { getSpacesWithoutOrg, deleteSpace, getUserProfilePicture, checkGlobalPermission } = useSpaces();
    const history = useHistory();
    const [spaceId, setSpaceId] = useState('');
    const [currentSort, setCurrentSort] = useState<Sort | undefined>();

    const handleSort = (columnId: string) => {
        const newSort: Sort =
            currentSort && currentSort[0] === columnId
                ? [columnId, currentSort[1] === 'ASC' ? 'DESC' : 'ASC']
                : [columnId, 'ASC'];
        setCurrentSort(newSort);
        if (spacesData) {
            const sortedRows = [...spacesData].sort((a, b) => {
                //@ts-ignore
                const valueA = a[columnId];
                //@ts-ignore
                const valueB = b[columnId];

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return newSort[1] === 'ASC' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else {
                    return newSort[1] === 'ASC' ? valueA - valueB : valueB - valueA;
                }
            });
            setSpacesData(sortedRows);
        }
    };

    const columnsConfigSpaces = getColumnsConfigSpaces(
        setShowPopUp,
        showPopUp,
        selectedSpace,
        setSelectedSpace,
        checkGlobalPermission,
        t,
    );

    useEffect(() => {
        const newSpaceId = nanoid();
        setSpaceId(newSpaceId);

        const id = store.user.userData?.id;
        if (id) {
            (async () => {
                // Get spaces
                try {
                    const data: SpaceInfo[] = await getSpacesWithoutOrg();
                    getImages(data);
                } catch (e) {
                    console.log(e);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pageCount = spacesData ? Math.ceil(spacesData?.length / MAX_SPACE_DISPLAYED) : 0;

    const getImages = (data: SpaceInfo[]) => {
        // @ts-ignore
        const promises = [];
        data?.forEach((entry) => {
            promises.push(getUserProfilePicture(entry.createdById));
        });

        // Once all promises are resolved, update the state
        // @ts-ignore
        Promise.all(promises).then((responses) => {
            const images = responses.map((response) => response.url);
            // Now map the data state
            const filteredData = data.map((entry, index) => {
                return {
                    ...entry,
                    imgUrl: images[index],
                };
            });
            setSpacesData(filteredData);
        });
    };

    return (
        <div className='h-full w-full max-w-5xl mx-auto relative'>
            {showDialog && (
                <DeleteDialog
                    title={selectedSpace?.spaceName}
                    description='Deleting a space will delete all its content including Message and files. This operation cannot be undone.'
                    close={close}
                    open={open}
                    handleConfirm={() => {
                        if (selectedSpace?.spaceId) {
                            deleteSpace(selectedSpace?.spaceId);
                            handleSpaceDeleted(selectedSpace?.spaceId);
                        }
                        return window.location.reload();
                    }}
                />
            )}
            <Button
                variant='bberryBlue'
                size='oval'
                onClick={() => {
                    history.push(`/workspace/add-space/${spaceId}`);
                    localStorage.setItem('spaceId', spaceId);
                    store.spaces.setCurrentSpaceId(spaceId);
                }}
                className='space-x-2 ml-auto mb-8'
            >
                <PlusIcon width={10} height={10} fill='white' />
                <p className='font-medium text-white'>{t('spaces.create')}</p>
            </Button>

            {!spacesData ? (
                <TableSkeleton rows={5} />
            ) : (
                <Table
                    rows={
                        input !== ''
                            ? spacesData.filter((space: SpaceInfo) =>
                                  space.spaceName.toLocaleLowerCase().includes(input.toLocaleLowerCase()),
                              )
                            : spacesData.slice(MAX_SPACE_DISPLAYED * (page - 1), MAX_SPACE_DISPLAYED * page)
                    }
                    columns={columnsConfigSpaces}
                    getKey={getClientId}
                    pageCount={pageCount}
                    page={page}
                    setPage={setPage}
                    selection={selection}
                    selectable={false}
                    onSelectionChange={setSelection}
                    totalItems={spacesData.length}
                    onClickRow={(row) => {
                        localStorage.setItem('spaceId', row.spaceId);
                        if (row.organisationSpace?.length) history.push(`/organisation/${row.spaceId}/eva`);
                        else history.push(`/spaces/view/${row.spaceId}`);
                        store.spaces.setSelectedSpace(row);
                    }}
                    maxRowsPerPage={MAX_SPACE_DISPLAYED}
                    handleSort={handleSort}
                />
            )}
        </div>
    );
});

export const getClientId = (spaceInfo: SpaceInfo) => {
    return spaceInfo.spaceId;
};

export const getColumnsConfigSpaces = (
    setShowPopUp: (value: boolean) => void,
    showPopUp: boolean,
    selectedRow: SpaceInfo | undefined,
    setSelectedRow: (value: SpaceInfo) => void,
    checkUserPermission: (spaceId: string, membersId: AssociatedMemberInfo[]) => boolean,
    t: TFunction<"translation", undefined>
): ColumnConfig<SpaceInfo>[] => [
    {
        id: 'spaceName',
        header: t('spaces.name'),
        renderCell: ({ row }) => (
            <div className='flex flex-row items-center py-1 space-x-4 text-sm text-darker text-opacity-75 font-medium'>
                <SpaceLogo
                    className='w-8 h-8'
                    //@ts-ignore
                    variant={row.spaceColor}
                    title={row.spaceName[0]}
                    size='text-md'
                    preview={row.spaceLogo !== '' ? getDocumentUrl(+row.spaceLogo) : ''}
                />
                <p>{row.spaceName}</p>
            </div>
        ),
        sortable: true,
    },
    {
        id: 'createdAt',
        header: t('spaces.createdDate'),
        renderCell: ({ row }) => (
            <p className='text-sm text-darker text-opacity-75'>{row.createdAt.toString().split('T')[0]}</p>
        ),
        sortable: true,
    },
    {
        id: 'owner',
        header: t('spaces.owner'),
        renderCell: ({ row }) => {
            return (
                <div className='flex flex-row items-center py-1 text-sm text-darker text-opacity-75 space-x-4'>
                    <ProfilePicture size='32px' path={row.imgUrl} />
                    <div>{store.user.userData?.firstName + ' ' + store.user.userData?.lastName}</div>
                </div>
            );
        },
    },
    {
        id: 'delete',
        header: '',
        renderCell: ({ row }) => {
            return (
                <div
                    className='absolute'
                    onClick={(event) => {
                        event.stopPropagation();
                        setShowPopUp(!showPopUp);
                        setSelectedRow(row);
                    }}
                >
                    {(checkUserPermission(row.spaceId, row.associatedMemberIds) ||
                        row.createdById === store.user.userData?.id) && (
                        <>
                            <MoreIcon className='w-3 h-3 cursor-pointer' fill='#0F104480' />
                            {showPopUp && selectedRow === row && (
                                <ClickAwayListener onClickAway={() => setShowPopUp(false)}>
                                    <div className='absolute bg-white w-40 py-4 shadow-message-container rounded-xl left-3 top-1'>
                                        <Link
                                            className='text-sm py-2 px-6 space-x-4 w-full flex items-center h-8 hover:bg-primary-darker hover:bg-opacity-3'
                                            to={`/workspace/edit-space/${row.spaceId}`}
                                        >
                                            <EditIcon /> <p>{t('spaces.edit')}</p>
                                        </Link>
                                    </div>
                                </ClickAwayListener>
                            )}
                        </>
                    )}
                </div>
            );
        },
        sortable: false,
    },
];
export default SpacesTable;
