import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { useAppDispatch } from '../../../../../Redux/store';
import { setSelectedGraphData, setSelectedMetricGraphData } from '../../../../../Redux/TrendMetric';

interface TrendsFilterProps {
    timeFilter: string;
    setTimeFilter: Dispatch<SetStateAction<string>>;
    setBackTimeFilter: Dispatch<SetStateAction<string[]>>;
}

//time filter for trends
const TrendsFilter: React.FC<TrendsFilterProps> = ({ setTimeFilter, timeFilter, setBackTimeFilter }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    return (
        <div className='filter_box  flex items-center '>
            <Tooltip id='Day' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('today')} </p>
            </Tooltip>
            <Tooltip id='Week' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('last_7_days')} </p>
            </Tooltip>
            <Tooltip id='Month' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('last_30_days')} </p>
            </Tooltip>
            <Tooltip id='Year' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('last_year')} </p>
            </Tooltip>
            <Tooltip id='All' offset={5} place='top' className='tooltip-box show_arrow'>
                <p> {t('allTime')} </p>
            </Tooltip>
            <div
                className={`${timeFilter === 'Day' ? 'active' : ''} relative filter_btn`}
                data-tooltip-id='Day'
                onClick={() => {
                    setTimeFilter('Day');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.d')}
            </div>
            <div
                className={`${timeFilter === 'Week' ? 'active' : ''} relative filter_btn`}
                data-tooltip-id='Week'
                onClick={() => {
                    setTimeFilter('Week');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.w')}
            </div>
            <div
                className={`${timeFilter === 'Month' ? 'active' : ''} relative filter_btn`}
                data-tooltip-id='Month'
                onClick={() => {
                    setTimeFilter('Month');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.m')}
            </div>
            <div
                className={`${timeFilter === 'Year' ? 'active' : ''} relative filter_btn`}
                data-tooltip-id='Year'
                onClick={() => {
                    setTimeFilter('Year');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.y')}
            </div>
            <div
                className={`${timeFilter === 'All' ? 'active' : ''} relative filter_btn`}
                data-tooltip-id='All'
                onClick={() => {
                    setTimeFilter('All');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.a')}
            </div>
        </div>
    );
};

export default TrendsFilter;
