import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { appInsights } from '../AppInsights';
import { useServices } from '../components/ServiceProvider';
import { initUserSession } from '../services/initUserSession';
import { useStore } from '../store';
import { handleLogin } from '../utils/chromeExtension';
import { BUSINESS, SIZES } from '../utils/constants';
import { mockable } from '../utils/mockable';
import { useForm } from '../utils/useForm';
import { useQuery } from '../utils/useQuery';
import { and, isEmail, required } from '../utils/validation';
import useMediaQuery from './useMediaQuery';
import { useOrganisationForm } from './useOrganisation';

export const useSignInForm = mockable((userType = BUSINESS) => {
    const { t } = useTranslation();
    const query = useQuery();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(query.has('expired') ? 'Your session has expired' : null);
    const services = useServices();
    const { auth } = services;
    const history = useHistory();
    const store = useStore();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    const { fields, isValid, setAllVisited } = useForm({
        fields: {
            email: { validation: and((value:string) => isEmail(value, t), (value:string) => required(value, t)) },
            password: {},
        },
    });
    const { updateMemberInvite } = useOrganisationForm();
    const firstVisit = localStorage.getItem('first-visit');

    const onSubmit = async () => {
        setIsLoading(true);
        setError(null);
        setAllVisited();
        try {
            if (!isValid()) throw new Error(t('error.fields'));
            const {
                access_token,
                userId,
                userType: bberryUserType,
            } = await auth.login(fields.email.value, fields.password.value, userType);
            if (firstVisit && firstVisit === 'true') await updateMemberInvite(fields.email.value);
            store.user.setBberryUserCookie(userId, bberryUserType);
            store.user.setToken(access_token);
            await initUserSession(services, store);
            if (!isMobile) handleLogin(access_token);
            const redirect = query.get('redirect');
            if (redirect && redirect !== '/') goTo(redirect);
            else goTo('/home');
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
                appInsights.trackException({ exception: err });
                Sentry.captureException(err);
            } else {
                setError('Wrong email or password');
                appInsights.trackException({ exception: new Error('Error while sign in') });
            }
            setIsLoading(false);
        }
    };

    const goTo = (path: string) => {
        history.push(path);
    };

    const isEmailValid = () => {
        try {
            if (!isValid()) {
                throw new Error(t('validation.invalidEmail'));
            } else {
                return true;
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
                appInsights.trackException({ exception: err });
                return false;
            }
            return true;
        }
    };
    const clearError = () => {
        setError(null);
    };

    return { onSubmit, fields, goTo, isLoading, error, isEmailValid, clearError };
});
