import i18next from 'i18next';

import CriticalThinking from '../../../../../../src/assets/icons/CriticalThinking.svg';
import CriticalThinkingFill from '../../../../../../src/assets/icons/CriticalThinkingFill.svg';
import CriticalThinkingTransparent from '../../../../../../src/assets/icons/CriticalThinkingTransparent.svg';
import GoodPractice from '../../../../../../src/assets/icons/GoodPractice.svg';
import GoodPracticeFill from '../../../../../../src/assets/icons/GoodPracticeFill.svg';
import GoodPracticeTransparent from '../../../../../../src/assets/icons/GoodPracticeTransparent.svg';
import Policy from '../../../../../../src/assets/icons/Policy.svg';
import PolicyFill from '../../../../../../src/assets/icons/PolicyFill.svg';
import PolicyTransparent from '../../../../../../src/assets/icons/PolicyTransparent.svg';
import Signal from '../../../../../../src/assets/icons/Signal.svg';
import SignalFill from '../../../../../../src/assets/icons/SignalFill.svg';
import SignalTransparent from '../../../../../../src/assets/icons/SignalTransparent.svg';
import { ProtocolOption, ProtocolTypes } from '../types';

export const OptionData: ProtocolOption[] = [];

i18next.on('initialized', () => {
    OptionData.push(
        {
            name: ProtocolTypes.Critical,
            icon: CriticalThinking,
            linearBg:
                'linear-gradient(88.43deg, rgba(57, 188, 249, 0.1) 4.31%, rgba(4, 242, 101, 0.1) 100%) , linear-gradient(0deg, #ffffff, #ffffff) ',
            txtColor: 'linear-gradient(89.15deg, #55B7FF 0%, #13ED65 101.53%) ',
            description: i18next.t('protocolTypesDesc.critical'),
            fillIcon: CriticalThinkingFill,
            lineColor: '#12ed66',
            bgColor: '#12ed66',
            transparentIcon: CriticalThinkingTransparent,
        },
        {
            name: ProtocolTypes.GoodPractice,
            icon: GoodPractice,
            linearBg:
                'linear-gradient(88.43deg, rgba(57, 188, 249, 0.1) 4.31%, rgba(4, 242, 101, 0.1) 100%) , linear-gradient(0deg, #ffffff, #ffffff) ',
            txtColor: 'linear-gradient(89.15deg, #55B7FF 0%, #13ED65 101.53%)',
            description: i18next.t('protocolTypesDesc.goodPractice'),
            fillIcon: GoodPracticeFill,
            lineColor: '#12ed66',
            bgColor: '#12ed66',
            transparentIcon: GoodPracticeTransparent,
        },
        {
            name: ProtocolTypes.Signal,
            icon: Signal,
            linearBg:
                ' linear-gradient(267.62deg, rgba(193, 101, 221, 0.1) -1.4%, rgba(92, 39, 254, 0.1) 98.21%)  , linear-gradient(0deg, #ffffff, #ffffff)',
            txtColor: 'linear-gradient(90deg, #5C27FE 0%, #C165DD 100%)',
            description: i18next.t('protocolTypesDesc.signal'),
            fillIcon: SignalFill,
            lineColor: '#c165dd',
            bgColor: '#c165dd',
            transparentIcon: SignalTransparent,
        },
        {
            name: ProtocolTypes.Policy,
            icon: Policy,
            linearBg:
                'linear-gradient(267.62deg, rgba(255, 221, 67, 0.1) -1.4%, rgba(255, 150, 3, 0.1) 98.21%), linear-gradient(0deg, #ffffff, #ffffff)',
            txtColor: 'linear-gradient(269.51deg, #FFDD43 0.08%, #FF9603 99.92%)',
            description: i18next.t('protocolTypesDesc.policy'),
            fillIcon: PolicyFill,
            lineColor: '#ff9603',
            bgColor: '#ff9603',
            transparentIcon: PolicyTransparent,
        },
    );
});

export const AverageProtocolGraph = '#6fbfe1';

export const protocolTypeIcon = (protocolType: string) => {
    const icon = OptionData.find((item) => item.name === protocolType);
    return icon?.icon;
};
