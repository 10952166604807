import * as Sentry from '@sentry/react';

const getEnvironment = (): string => {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) return 'development';
    if (hostname.includes('staging')) return 'staging';
    if (hostname.includes('blankstate')) return 'production';
    return 'development'; // Default to production
};

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Dynamically set environment
    environment: getEnvironment(),
    // Tracing
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/staging\.traceflow\.net\/api/, // Staging API
        /^https:\/\/app\.blankstate\.ai\/api/, // Production API
    ],

    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

console.log('Sentry is Initialized');
