import { useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { Organisation } from '../../../store/OrganisationStore';
import { countryList } from '../../../store/UserStore';
import { businessTypes } from '../../../utils/constants';
import { Button } from '../../basic/Button.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { IconAlert } from '../../basic/IconAlert.component';
import FormInput from '../../profile/Form/FormInput';
import FormSelect from '../../profile/Form/FormSelect';
import InputPhoneCode from './InputPhoneCode';

const OrganistaionForm = ({
    settings = false,
    initialValues,
    file,
}: {
    settings?: boolean;
    initialValues?: Organisation | null;
    file?: File | null;
}) => {
    const {
        fields,
        error,
        isLoading,
        updateOrganisation,
        createOrganisation,
        success,
        emailVerified,
        isValid,
        setError,
    } = useOrganisationForm(settings, initialValues);
    const { t } = useTranslation();
    const { id: organisationId } = useParams<{ id: string }>();

    useEffect(() => {
        // Check if all fields are valid whenever fields change
        if (isValid() && error === 'Some fields are not valid, please check again') {
            setError(''); // Clear error message
        }
    }, [fields, isValid, setError, error]);

    return (
        <div className='w-full'>
            {settings && (
                <div className='grid grid-cols-2 gap-6 mb-6'>
                    <FormInput
                        {...fields.email}
                        rounded='rounded'
                        label={t('createOrg.email')}
                        subLabel={t('createOrg.emailSuffix')}
                        placeholder={t('createOrg.email')}
                        edit
                        className='w-full'
                        required
                        onKeyPress={() => {
                            if (isValid()) setError('');
                        }}
                    />
                </div>
            )}
            <div className='grid grid-cols-2 gap-6'>
                <FormInput
                    {...fields.organisationName}
                    rounded='rounded'
                    label={t('createOrg.name')}
                    placeholder={t('createOrg.name')}
                    edit
                    className='w-full'
                    required
                    maxLength={50}
                />
                <FormSelect
                    entries={Object.values(businessTypes)}
                    {...fields.businessType}
                    rounded='rounded'
                    label={t('createOrg.type')}
                    placeholder={t('createOrg.type')}
                    edit
                    className='w-full'
                    required
                    error={fields.businessType.error}
                    value={fields.businessType.value}
                    getName={(type) => t(`createOrg.organisationType.${type}`)}
                    marginTop={40}
                />
                <FormInput
                    {...fields.crn}
                    rounded='rounded'
                    label={t('createOrg.crn')}
                    placeholder={t('createOrg.crnPlaceholder')}
                    edit
                    className='w-full'
                />
                <FormInput
                    {...fields.vat}
                    rounded='rounded'
                    label={t('createOrg.vat')}
                    placeholder={t('createOrg.vatPlaceholder')}
                    edit
                    className='w-full'
                />
            </div>
            <HorizontalSeparator className='my-6' />
            <div className='grid grid-cols-2 gap-6'>
                <InputPhoneCode
                    label={t('createOrg.phNo')}
                    placeholder={t('createOrg.phNoPlaceholder')}
                    {...fields.phone}
                    value={fields.phone.value}
                />
                <FormInput
                    {...fields.website}
                    rounded='rounded'
                    label={t('createOrg.website')}
                    placeholder={t('createOrg.websitePlaceholder')}
                    edit
                    className='w-full'
                />
                <FormInput
                    {...fields.postcode}
                    rounded='rounded'
                    label={t('createOrg.postcode')}
                    placeholder={t('createOrg.postcodePlaceholder')}
                    edit
                    className='w-full'
                    required
                    maxLength={8}
                />
                <FormInput
                    {...fields.address}
                    rounded='rounded'
                    label={t('createOrg.address')}
                    placeholder={t('createOrg.addressPlaceholder')}
                    edit
                    className='w-full'
                    required
                />
                <FormInput
                    {...fields.city}
                    rounded='rounded'
                    label={t('createOrg.city')}
                    placeholder={t('createOrg.city')}
                    edit
                    className='w-full'
                    required
                />
                <FormSelect
                    entries={countryList}
                    {...fields.country}
                    rounded='rounded'
                    label={t('createOrg.country')}
                    placeholder={t('createOrg.country')}
                    edit
                    className='w-full'
                    required
                    value={fields.country.value}
                    marginTop={40}
                    showSearchBar
                />
            </div>

            {error && (
                <div className='mt-6'>
                    <IconAlert message={error} />
                </div>
            )}
            {success && (
                <div className='mt-6'>
                    <IconAlert message={success} type='success' />
                </div>
            )}
            {!settings ? (
                <div className='flex flex-col'>
                    <Button
                        disabled={isLoading || !isValid()}
                        onClick={() => {
                            createOrganisation(organisationId, file);
                            localStorage.setItem('organisationName', fields.organisationName.value);
                        }}
                        className='flex space-x-2 w-full py-5 h-10 items-center mt-5'
                        variant='primaryDarkBlue'
                        size='oval'
                        type='submit'
                    >
                        <p>{t('btnNames.next')}</p>
                    </Button>
                </div>
            ) : (
                <Button
                    disabled={isLoading || !isValid()}
                    onClick={() => updateOrganisation(organisationId, file, Number(initialValues?.logo))}
                    className='flex space-x-2 w-full mt-5 py-5 h-10 items-center'
                    variant='bberryBlue'
                    size='oval'
                    type='submit'
                >
                    <p>{t('btnNames.save')}</p>
                </Button>
            )}
            {settings && ((initialValues?.emailVerified === false && initialValues?.email) || !emailVerified) && (
                <div className='mt-2 flex justify-center'>
                    <p className='text-sm italic text-darker text-opacity-75'>{t('createOrg.emailValidation')}</p>
                </div>
            )}
        </div>
    );
};

export default observer(OrganistaionForm);
