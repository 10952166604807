export type Scenario = {
    name: string;
    id: string;
    category: string;
    user1: string;
    user2: string;
};

export const Categories = {
    1: 'scenarios.financial.category',
    2: 'scenarios.healthcare.category',
    3: 'scenarios.retail.category',
    4: 'scenarios.technology.category',
    5: 'scenarios.education.category',
    6: 'scenarios.humanResources.category',
    7: 'scenarios.legal.category',
    8: 'scenarios.realEstate.category',
    9: 'scenarios.hospitality.category',
    10: 'scenarios.automotive.category',
    11: 'scenarios.government.category',
    12: 'scenarios.nonProfit.category',
};

export const scenarios: Scenario[] = [
    // Financial Services
    {
        name: 'scenarios.financial.banker.name',
        id: 'f47ac10b-58cc-4372-a567-0e02b2c3d479',
        category: Categories[1],
        user1: 'scenarios.financial.banker.user1',
        user2: 'scenarios.financial.banker.user2',
    },
    {
        name: 'scenarios.financial.mortage.name',
        id: 'c9bf9e57-1685-4c89-bafb-ff5af830be8a',
        category: Categories[1],
        user1: 'scenarios.financial.mortage.user1',
        user2: 'scenarios.financial.mortage.user2',
    },
    {
        name: 'scenarios.financial.creditCard.name',
        id: '7f263e90-bd24-4b97-a47b-c2fdd7b8d8bb',
        category: Categories[1],
        user1: 'scenarios.financial.creditCard.user1',
        user2: 'scenarios.financial.creditCard.user2',
    },
    {
        name: 'scenarios.financial.retirementPlanning.name',
        id: '1c2d35e6-38f8-46fa-bf5b-007b5fef49a0',
        category: Categories[1],
        user1: 'scenarios.financial.retirementPlanning.user1',
        user2: 'scenarios.financial.retirementPlanning.user2',
    },
    {
        name: 'scenarios.financial.smallBusinessLoan.name',
        id: '3d594650-3f91-44ff-baae-2baf6a234536',
        category: Categories[1],
        user1: 'scenarios.financial.smallBusinessLoan.user1',
        user2: 'scenarios.financial.smallBusinessLoan.user2',
    },
    {
        name: 'scenarios.financial.fraudDetection.name',
        id: 'e0294e36-26fc-4e1a-b2b5-3721a2ff52c5',
        category: Categories[1],
        user1: 'scenarios.financial.fraudDetection.user1',
        user2: 'scenarios.financial.fraudDetection.user2',
    },
    {
        name: 'scenarios.financial.wealthManagement.name',
        id: 'de305d54-75b4-431b-adb2-eb6b9e546014',
        category: Categories[1],
        user1: 'scenarios.financial.wealthManagement.user1',
        user2: 'scenarios.financial.wealthManagement.user2',
    },
    // Healthcare
    {
        name: 'scenarios.healthcare.doctorPatientConsultation.name',
        id: '9a09a3a5-2094-444c-bb09-b8bba7b2fbbd',
        category: Categories[2],
        user1: 'scenarios.healthcare.doctorPatientConsultation.user1',
        user2: 'scenarios.healthcare.doctorPatientConsultation.user2',
    },
    {
        name: 'scenarios.healthcare.emergencyRoomTriage.name',
        id: '4f629d42-3876-4562-964a-0debb6c72f39',
        category: Categories[2],
        user1: 'scenarios.healthcare.emergencyRoomTriage.user1',
        user2: 'scenarios.healthcare.emergencyRoomTriage.user2',
    },
    {
        name: 'scenarios.healthcare.mentalHealthTherapy.name',
        id: '1b13b35a-3b77-4e90-bc6a-174bc90f8766',
        category: Categories[2],
        user1: 'scenarios.healthcare.mentalHealthTherapy.user1',
        user2: 'scenarios.healthcare.mentalHealthTherapy.user2',
    },
    {
        name: 'scenarios.healthcare.pharmacistMedicationCounseling.name',
        id: '5346d012-7da5-4b8b-87fc-3e430d6310fa',
        category: Categories[2],
        user1: 'scenarios.healthcare.pharmacistMedicationCounseling.user1',
        user2: 'scenarios.healthcare.pharmacistMedicationCounseling.user2',
    },
    {
        name: 'scenarios.healthcare.insuranceClaimProcessing.name',
        id: 'f4fba69c-78b4-4a90-a8f4-76f184370a39',
        category: Categories[2],
        user1: 'scenarios.healthcare.insuranceClaimProcessing.user1',
        user2: 'scenarios.healthcare.insuranceClaimProcessing.user2',
    },
    {
        name: 'scenarios.healthcare.telemedicineAppointment.name',
        id: '0b2f0bc4-e19c-4658-85ae-70f3b1bb69a4',
        category: Categories[2],
        user1: 'scenarios.healthcare.telemedicineAppointment.user1',
        user2: 'scenarios.healthcare.telemedicineAppointment.user2',
    },
    {
        name: 'scenarios.healthcare.nursingHomeCarePlanning.name',
        id: 'e10dc9b4-2f8e-4c8f-9dbe-5f9b8e1cdb97',
        category: Categories[2],
        user1: 'scenarios.healthcare.nursingHomeCarePlanning.user1',
        user2: 'scenarios.healthcare.nursingHomeCarePlanning.user2',
    },
    // Retail
    {
        name: 'scenarios.retail.customerService.name',
        id: '99ed37b7-4569-4c95-ae3d-0d8b285ef6b5',
        category: Categories[3],
        user1: 'scenarios.retail.customerService.user1',
        user2: 'scenarios.retail.customerService.user2',
    },
    {
        name: 'scenarios.retail.productReturnAndExchange.name',
        id: 'd1f404be-d7c6-466c-b9e6-9b77de1f06e4',
        category: Categories[3],
        user1: 'scenarios.retail.productReturnAndExchange.user1',
        user2: 'scenarios.retail.productReturnAndExchange.user2',
    },
    {
        name: 'scenarios.retail.vipCustomerAssistance.name',
        id: 'a5a78b6c-2f53-431e-9808-3b535cdc4dc1',
        category: Categories[3],
        user1: 'scenarios.retail.vipCustomerAssistance.user1',
        user2: 'scenarios.retail.vipCustomerAssistance.user2',
    },
    {
        name: 'scenarios.retail.inStorePersonalShopping.name',
        id: '3bb77b55-1225-4462-b4a5-79d4567a2f88',
        category: Categories[3],
        user1: 'scenarios.retail.inStorePersonalShopping.user1',
        user2: 'scenarios.retail.inStorePersonalShopping.user2',
    },
    {
        name: 'scenarios.retail.onlineShoppingSupport.name',
        id: 'd52a2765-fde5-4b90-8e39-e3a25f9785b9',
        category: Categories[3],
        user1: 'scenarios.retail.onlineShoppingSupport.user1',
        user2: 'scenarios.retail.onlineShoppingSupport.user2',
    },
    {
        name: 'scenarios.retail.loyaltyProgramEnrollment.name',
        id: 'e36e7422-8880-4f9a-a598-8e67f024a1c6',
        category: Categories[3],
        user1: 'scenarios.retail.loyaltyProgramEnrollment.user1',
        user2: 'scenarios.retail.loyaltyProgramEnrollment.user2',
    },
    {
        name: 'scenarios.retail.productRecommendation.name',
        id: '64bd60e8-9a82-4827-989f-799a5cf68c68',
        category: Categories[3],
        user1: 'scenarios.retail.productRecommendation.user1',
        user2: 'scenarios.retail.productRecommendation.user2',
    },
    // Technology
    {
        name: 'scenarios.technology.technicalSupport.name',
        id: 'd53e9891-b4f6-4f82-a681-63260a8651f0',
        category: Categories[4],
        user1: 'scenarios.technology.technicalSupport.user1',
        user2: 'scenarios.technology.technicalSupport.user2',
    },
    {
        name: 'scenarios.technology.softwareDemoAndSales.name',
        id: 'a7d23e5f-23b7-4d4d-a10a-4b4d8f39173c',
        category: Categories[4],
        user1: 'scenarios.technology.softwareDemoAndSales.user1',
        user2: 'scenarios.technology.softwareDemoAndSales.user2',
    },
    {
        name: 'scenarios.technology.dataPrivacy.name',
        id: 'c0e44712-09d8-4930-9f7b-8dbca57b13e4',
        category: Categories[4],
        user1: 'scenarios.technology.dataPrivacy.user1',
        user2: 'scenarios.technology.dataPrivacy.user2',
    },
    {
        name: 'scenarios.technology.cloudMigration.name',
        id: 'e63d2e38-e4de-4f2b-83a7-027d146ee999',
        category: Categories[4],
        user1: 'scenarios.technology.cloudMigration.user1',
        user2: 'scenarios.technology.cloudMigration.user2',
    },
    {
        name: 'scenarios.technology.cybersecurity.name',
        id: 'b6ab9d5f-9c31-4b9b-b66e-65f8049e66c1',
        category: Categories[4],
        user1: 'scenarios.technology.cybersecurity.user1',
        user2: 'scenarios.technology.cybersecurity.user2',
    },
    {
        name: 'scenarios.technology.userExperience.name',
        id: '978907ea-68f5-41b4-b462-5ab7f238a47e',
        category: Categories[4],
        user1: 'scenarios.technology.userExperience.user1',
        user2: 'scenarios.technology.userExperience.user2',
    },
    {
        name: 'scenarios.technology.agileSprint.name',
        id: '7f4d940a-d70e-41fd-9f44-929af6959f36',
        category: Categories[4],
        user1: 'scenarios.technology.agileSprint.user1',
        user2: 'scenarios.technology.agileSprint.user2',
    },
    // Education
    {
        name: 'scenarios.education.studentAcademicAdvising.name',
        id: 'b5b660ed-95b6-4628-a29e-3e25b80fc751',
        category: Categories[5],
        user1: 'scenarios.education.studentAcademicAdvising.user1',
        user2: 'scenarios.education.studentAcademicAdvising.user2',
    },
    {
        name: 'scenarios.education.parentTeacherConference.name',
        id: '1e840c07-b183-45bb-bbd7-b3bc1ecb3f90',
        category: Categories[5],
        user1: 'scenarios.education.parentTeacherConference.user1',
        user2: 'scenarios.education.parentTeacherConference.user2',
    },
    {
        name: 'scenarios.education.onlineCourse.name',
        id: 'fb90c4ae-91a0-4e54-a029-7e28f5d663e8',
        category: Categories[5],
        user1: 'scenarios.education.onlineCourse.user1',
        user2: 'scenarios.education.onlineCourse.user2',
    },
    {
        name: 'scenarios.education.collegeAdmissionsInterview.name',
        id: '752c2c2a-7e2a-4b4b-84f7-56a9e1e593d8',
        category: Categories[5],
        user1: 'scenarios.education.collegeAdmissionsInterview.user1',
        user2: 'scenarios.education.collegeAdmissionsInterview.user2',
    },
    {
        name: 'scenarios.education.specialEducationPlanningMeeting.name',
        id: '6f8a2162-924e-4625-b06b-87e37cf7ea7d',
        category: Categories[5],
        user1: 'scenarios.education.specialEducationPlanningMeeting.user1',
        user2: 'scenarios.education.specialEducationPlanningMeeting.user2',
    },
    {
        name: 'scenarios.education.careerCounselingSession.name',
        id: 'e586158e-0c02-43a4-84b4-97d74e11f6d4',
        category: Categories[5],
        user1: 'scenarios.education.careerCounselingSession.user1',
        user2: 'scenarios.education.careerCounselingSession.user2',
    },
    {
        name: 'scenarios.education.studentDisciplinaryHearing.name',
        id: 'f28c76a1-5abf-43b2-978e-447d4dd7e26a',
        category: Categories[5],
        user1: 'scenarios.education.studentDisciplinaryHearing.user1',
        user2: 'scenarios.education.studentDisciplinaryHearing.user2',
    },
    // Human Resources
    {
        name: 'scenarios.humanResources.jobInterview.name',
        id: '44d8d2f2-c32f-4d07-81d3-2fcd1a2be308',
        category: Categories[6],
        user1: 'scenarios.humanResources.jobInterview.user1',
        user2: 'scenarios.humanResources.jobInterview.user2',
    },
    {
        name: 'scenarios.humanResources.employeePerformanceReview.name',
        id: '1a7c1e22-9be6-41f8-b13e-5c0b0285b7fb',
        category: Categories[6],
        user1: 'scenarios.humanResources.employeePerformanceReview.user1',
        user2: 'scenarios.humanResources.employeePerformanceReview.user2',
    },
    {
        name: 'scenarios.humanResources.workplaceConflictResolution.name',
        id: '5a8b2c9f-165c-4f6c-b46c-14e431cfcc25',
        category: Categories[6],
        user1: 'scenarios.humanResources.workplaceConflictResolution.user1',
        user2: 'scenarios.humanResources.workplaceConflictResolution.user2',
    },
    {
        name: 'scenarios.humanResources.benefitsEnrollmentAssistance.name',
        id: '768d4a34-2c37-4056-8e29-7a2bb60af689',
        category: Categories[6],
        user1: 'scenarios.humanResources.benefitsEnrollmentAssistance.user1',
        user2: 'scenarios.humanResources.benefitsEnrollmentAssistance.user2',
    },
    {
        name: 'scenarios.humanResources.onboardingNewEmployee.name',
        id: '5cf06d91-9d35-4a3f-84d4-74665398d2ae',
        category: Categories[6],
        user1: 'scenarios.humanResources.onboardingNewEmployee.user1',
        user2: 'scenarios.humanResources.onboardingNewEmployee.user2',
    },
    {
        name: 'scenarios.humanResources.diversityAndInclusionTraining.name',
        id: 'b5c2841f-76ed-4569-a737-e0ed5db1a203',
        category: Categories[6],
        user1: 'scenarios.humanResources.diversityAndInclusionTraining.user1',
        user2: 'scenarios.humanResources.diversityAndInclusionTraining.user2',
    },
    {
        name: 'scenarios.humanResources.exitInterview.name',
        id: '01f2c8bc-10a2-44e5-91ad-b66a6e6b69c4',
        category: Categories[6],
        user1: 'scenarios.humanResources.exitInterview.user1',
        user2: 'scenarios.humanResources.exitInterview.user2',
    },
    // Legal Services
    {
        name: 'scenarios.legal.attorneyClientCaseDiscussion.name',
        id: '18f563cc-e5ba-4634-842e-3704dc63c65d',
        category: Categories[7],
        user1: 'scenarios.legal.attorneyClientCaseDiscussion.user1',
        user2: 'scenarios.legal.attorneyClientCaseDiscussion.user2',
    },
    {
        name: 'scenarios.legal.witnessInterview.name',
        id: 'b5b7dc02-e446-4415-b85f-4d6e9edc405d',
        category: Categories[7],
        user1: 'scenarios.legal.witnessInterview.user1',
        user2: 'scenarios.legal.witnessInterview.user2',
    },
    {
        name: 'scenarios.legal.mediationSession.name',
        id: '5797e52d-b37c-4228-9d89-d4e2a2d3589b',
        category: Categories[7],
        user1: 'scenarios.legal.mediationSession.user1',
        user2: 'scenarios.legal.mediationSession.user2',
    },
    {
        name: 'scenarios.legal.legalDocumentReview.name',
        id: 'f6b3f77a-2094-4e3f-98b4-421e4f8c112a',
        category: Categories[7],
        user1: 'scenarios.legal.legalDocumentReview.user1',
        user2: 'scenarios.legal.legalDocumentReview.user2',
    },
    {
        name: 'scenarios.legal.courtHearingPreparation.name',
        id: '65d54dd5-b482-42f0-8120-6d346032f6c8',
        category: Categories[7],
        user1: 'scenarios.legal.courtHearingPreparation.user1',
        user2: 'scenarios.legal.courtHearingPreparation.user2',
    },
    {
        name: 'scenarios.legal.contractNegotiation.name',
        id: '8b7f73d7-d1c4-421a-a1b2-320d12b2e4d3',
        category: Categories[7],
        user1: 'scenarios.legal.contractNegotiation.user1',
        user2: 'scenarios.legal.contractNegotiation.user2',
    },
    {
        name: 'scenarios.legal.legalConsultationForStartups.name',
        id: '6bb0e086-d1de-4e88-8b5d-69fa6df7075c',
        category: Categories[7],
        user1: 'scenarios.legal.legalConsultationForStartups.user1',
        user2: 'scenarios.legal.legalConsultationForStartups.user2',
    },
    // Real Estate
    {
        name: 'scenarios.realEstate.propertyShowingAndDiscussion.name',
        id: '5f7adf31-6486-4ac7-80f3-f247dc5f2dc5',
        category: Categories[8],
        user1: 'scenarios.realEstate.propertyShowingAndDiscussion.user1',
        user2: 'scenarios.realEstate.propertyShowingAndDiscussion.user2',
    },
    {
        name: 'scenarios.realEstate.leaseAgreementNegotiation.name',
        id: '914dfd45-1c98-4d55-b41e-ff2a2ecb2d9a',
        category: Categories[8],
        user1: 'scenarios.realEstate.leaseAgreementNegotiation.user1',
        user2: 'scenarios.realEstate.leaseAgreementNegotiation.user2',
    },
    {
        name: 'scenarios.realEstate.homeInspectionReview.name',
        id: 'f57b1a26-c0b4-42d2-95d6-3ad2d5e410f4',
        category: Categories[8],
        user1: 'scenarios.realEstate.homeInspectionReview.user1',
        user2: 'scenarios.realEstate.homeInspectionReview.user2',
    },
    {
        name: 'scenarios.realEstate.propertyManagementComplaintResolution.name',
        id: 'ad399ee9-1d06-460d-9e48-d1e160c023f2',
        category: Categories[8],
        user1: 'scenarios.realEstate.propertyManagementComplaintResolution.user1',
        user2: 'scenarios.realEstate.propertyManagementComplaintResolution.user2',
    },
    {
        name: 'scenarios.realEstate.realEstateInvestmentConsultation.name',
        id: '7e0c27e6-34ea-4a2f-a4ed-b9cf7c39b6f7',
        category: Categories[8],
        user1: 'scenarios.realEstate.realEstateInvestmentConsultation.user1',
        user2: 'scenarios.realEstate.realEstateInvestmentConsultation.user2',
    },
    {
        name: 'scenarios.realEstate.commercialPropertyAcquisitionMeeting.name',
        id: '931a0f3c-8a19-4a47-a8d4-4ea6ccaf35fc',
        category: Categories[8],
        user1: 'scenarios.realEstate.commercialPropertyAcquisitionMeeting.user1',
        user2: 'scenarios.realEstate.commercialPropertyAcquisitionMeeting.user2',
    },
    {
        name: 'scenarios.realEstate.rentalApplicationProcess.name',
        id: '80f14bda-4be7-4b0d-8aa3-8335baf3791e',
        category: Categories[8],
        user1: 'scenarios.realEstate.rentalApplicationProcess.user1',
        user2: 'scenarios.realEstate.rentalApplicationProcess.user2',
    },
    // Hospitality
    {
        name: 'scenarios.hospitality.hotelCheckinAndConciergeService.name',
        id: '7ebd930e-0731-4f22-891f-c3e15b7fa5e4',
        category: Categories[9],
        user1: 'scenarios.hospitality.hotelCheckinAndConciergeService.user1',
        user2: 'scenarios.hospitality.hotelCheckinAndConciergeService.user2',
    },
    {
        name: 'scenarios.hospitality.restaurantReservationAndSeating.name',
        id: '9e034d4b-72c4-4215-9ae3-d1d72b9bb4d4',
        category: Categories[9],
        user1: 'scenarios.hospitality.restaurantReservationAndSeating.user1',
        user2: 'scenarios.hospitality.restaurantReservationAndSeating.user2',
    },
    {
        name: 'scenarios.hospitality.travelBookingAssistance.name',
        id: '208a97d9-c8f2-41a0-83d4-ec8cc8cdb50c',
        category: Categories[9],
        user1: 'scenarios.hospitality.travelBookingAssistance.user1',
        user2: 'scenarios.hospitality.travelBookingAssistance.user2',
    },
    {
        name: 'scenarios.hospitality.guestComplaintResolution.name',
        id: 'e0f9cb56-9638-4a1e-8e9d-56c9b67b7d1f',
        category: Categories[9],
        user1: 'scenarios.hospitality.guestComplaintResolution.user1',
        user2: 'scenarios.hospitality.guestComplaintResolution.user2',
    },
    {
        name: 'scenarios.hospitality.eventPlanningConsultation.name',
        id: '88b32e2d-1ef6-4b83-8a3f-5ed5e4bca8a5',
        category: Categories[9],
        user1: 'scenarios.hospitality.eventPlanningConsultation.user1',
        user2: 'scenarios.hospitality.eventPlanningConsultation.user2',
    },
    {
        name: 'scenarios.hospitality.roomServiceOrder.name',
        id: 'f5d6ef44-d2a3-48b2-9024-7921f52a0ab2',
        category: Categories[9],
        user1: 'scenarios.hospitality.roomServiceOrder.user1',
        user2: 'scenarios.hospitality.roomServiceOrder.user2',
    },
    {
        name: 'scenarios.hospitality.tourismInformationAndRecommendations.name',
        id: 'dd28280b-ef32-46f3-9c12-81d2c0aab745',
        category: Categories[9],
        user1: 'scenarios.hospitality.tourismInformationAndRecommendations.user1',
        user2: 'scenarios.hospitality.tourismInformationAndRecommendations.user2',
    },
    // Automotive
    {
        name: 'scenarios.automotive.carSalesNegotiation.name',
        id: 'aab7187e-6b2f-4990-85a7-7d86b7a1c047',
        category: Categories[10],
        user1: 'scenarios.automotive.carSalesNegotiation.user1',
        user2: 'scenarios.automotive.carSalesNegotiation.user2',
    },
    {
        name: 'scenarios.automotive.vehicleRepairConsultation.name',
        id: 'a1c10a74-d239-4110-858e-7bc88c8f7ed5',
        category: Categories[10],
        user1: 'scenarios.automotive.vehicleRepairConsultation.user1',
        user2: 'scenarios.automotive.vehicleRepairConsultation.user2',
    },
    {
        name: 'scenarios.automotive.insuranceClaimAfterAccident.name',
        id: '87d8f4b6-ff3a-4f02-85c5-94da6fc3f9a6',
        category: Categories[10],
        user1: 'scenarios.automotive.insuranceClaimAfterAccident.user1',
        user2: 'scenarios.automotive.insuranceClaimAfterAccident.user2',
    },
    {
        name: 'scenarios.automotive.testDriveExperience.name',
        id: 'ab2e7a82-78de-4b22-b06a-404299ebef83',
        category: Categories[10],
        user1: 'scenarios.automotive.testDriveExperience.user1',
        user2: 'scenarios.automotive.testDriveExperience.user2',
    },
    {
        name: 'scenarios.automotive.carLoanApplication.name',
        id: '2b9df35f-c83a-4c91-9950-64a75995f1b5',
        category: Categories[10],
        user1: 'scenarios.automotive.carLoanApplication.user1',
        user2: 'scenarios.automotive.carLoanApplication.user2',
    },
    {
        name: 'scenarios.automotive.vehicleMaintenanceAdvisory.name',
        id: '0cf16a8a-88c2-4b9a-8005-79ad47e2eaf2',
        category: Categories[10],
        user1: 'scenarios.automotive.vehicleMaintenanceAdvisory.user1',
        user2: 'scenarios.automotive.vehicleMaintenanceAdvisory.user2',
    },
    {
        name: 'scenarios.automotive.usedCarInspectionAndValuation.name',
        id: 'c79d9495-5b25-46f9-90de-d82f836d2da0',
        category: Categories[10],
        user1: 'scenarios.automotive.usedCarInspectionAndValuation.user1',
        user2: 'scenarios.automotive.usedCarInspectionAndValuation.user2',
    },
    // Government Services
    {
        name: 'scenarios.government.passportApplicationProcess.name',
        id: 'b420967d-9266-4e80-988f-b8b7a64bfc15',
        category: Categories[11],
        user1: 'scenarios.government.passportApplicationProcess.user1',
        user2: 'scenarios.government.passportApplicationProcess.user2',
    },
    {
        name: 'scenarios.government.taxFilingAssistance.name',
        id: '409a376f-bb7e-4e82-bbd1-3f6b7cbfbd60',
        category: Categories[11],
        user1: 'scenarios.government.taxFilingAssistance.user1',
        user2: 'scenarios.government.taxFilingAssistance.user2',
    },
    {
        name: 'scenarios.government.votingRegistration.name',
        id: 'b2f4b4e3-9270-48b2-8e78-c8b0c9e3ff88',
        category: Categories[11],
        user1: 'scenarios.government.votingRegistration.user1',
        user2: 'scenarios.government.votingRegistration.user2',
    },
    {
        name: 'scenarios.government.socialServicesBenefitsApplication.name',
        id: '9264f9e6-47f5-4f91-bc9a-5393cfd45b62',
        category: Categories[11],
        user1: 'scenarios.government.socialServicesBenefitsApplication.user1',
        user2: 'scenarios.government.socialServicesBenefitsApplication.user2',
    },
    {
        name: 'scenarios.government.buildingPermitApplication.name',
        id: '3b8c3a23-4d5f-499a-b9ed-dcb93115f248',
        category: Categories[11],
        user1: 'scenarios.government.buildingPermitApplication.user1',
        user2: 'scenarios.government.buildingPermitApplication.user2',
    },
    {
        name: 'scenarios.government.publicTransportationInformation.name',
        id: 'a9f7b994-0fe2-44da-a9c1-765c5d6c09b3',
        category: Categories[11],
        user1: 'scenarios.government.publicTransportationInformation.user1',
        user2: 'scenarios.government.publicTransportationInformation.user2',
    },
    {
        name: 'scenarios.government.cityCouncilMeetingPublicComment.name',
        id: 'f5e6b6c4-0ee7-4e4b-b6a8-2191159e9be7',
        category: Categories[11],
        user1: 'scenarios.government.cityCouncilMeetingPublicComment.user1',
        user2: 'scenarios.government.cityCouncilMeetingPublicComment.user2',
    },
    // Non-Profit
    {
        name: 'scenarios.nonProfit.donationProcessing.name',
        id: 'b56952e6-d38a-4782-8fe6-6dba8e9dfbae',
        category: Categories[12],
        user1: 'scenarios.nonProfit.donationProcessing.user1',
        user2: 'scenarios.nonProfit.donationProcessing.user2',
    },
    {
        name: 'scenarios.nonProfit.volunteerRecruitment.name',
        id: 'a7de450b-c356-46d9-a6d5-593b6ff4c7e2',
        category: Categories[12],
        user1: 'scenarios.nonProfit.volunteerRecruitment.user1',
        user2: 'scenarios.nonProfit.volunteerRecruitment.user2',
    },
    {
        name: 'scenarios.nonProfit.grantApplicationReview.name',
        id: 'ebc05e43-1420-4f5f-8641-7b4d4b94d85d',
        category: Categories[12],
        user1: 'scenarios.nonProfit.grantApplicationReview.user1',
        user2: 'scenarios.nonProfit.grantApplicationReview.user2',
    },
    {
        name: 'scenarios.nonProfit.beneficiaryNeedsAssessment.name',
        id: '531d1bfa-46e6-4ec3-8515-349a32e1c2e7',
        category: Categories[12],
        user1: 'scenarios.nonProfit.beneficiaryNeedsAssessment.user1',
        user2: 'scenarios.nonProfit.beneficiaryNeedsAssessment.user2',
    },
    {
        name: 'scenarios.nonProfit.fundraisingEventPlanning.name',
        id: '64ae53eb-01ae-41c1-9571-7b3e621ce158',
        category: Categories[12],
        user1: 'scenarios.nonProfit.fundraisingEventPlanning.user1',
        user2: 'scenarios.nonProfit.fundraisingEventPlanning.user2',
    },
    {
        name: 'scenarios.nonProfit.communityOutreachProgram.name',
        id: '7d0fe9c2-1c2b-48ef-83e0-6c3bc62c10fb',
        category: Categories[12],
        user1: 'scenarios.nonProfit.communityOutreachProgram.user1',
        user2: 'scenarios.nonProfit.communityOutreachProgram.user2',
    },
    {
        name: 'scenarios.nonProfit.boardMemberSelectionInterview.name',
        id: 'c7b2792d-5f88-4727-849f-0b6c6493c8fe',
        category: Categories[12],
        user1: 'scenarios.nonProfit.boardMemberSelectionInterview.user1',
        user2: 'scenarios.nonProfit.boardMemberSelectionInterview.user2',
    },
];
