import * as Sentry from '@sentry/react';

import { appInsights } from '../../AppInsights';
import { get, put } from '../../utils/network';

export class SessionService{
    async getAll(){
        try{
            const sessions = await get('session/get-all');
            return sessions
        }catch(err){
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async getSessionsBySpaceId(spaceId: string){
        try{
            const sessions = await get(`session/${spaceId}`);
            return sessions
        }catch(err){
            if(err instanceof Error){
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async downgradeTrackedSession(){
        try{
            const sessions = await put(`session/downgrade/traked-sessions`);
            return sessions
        }catch(err){
            if(err instanceof Error){
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async deleteSession(sessionId: number){
        try{
            const sessions = await put(`session/${sessionId}`);
            return sessions
        }catch(err){
            if(err instanceof Error){
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async deleteSessionsByDomain(domain: string){
        try{
            const sessions = await put(`session/domain`, {domain});
            return sessions
        }catch(err){
            if(err instanceof Error){
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }
}