import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { SubscriptionProvider } from '../../../../contexts/subscription.context';
import AnalyticsComponent from './Analytics.component';
import './style.css';

const AnalyticsPage = () => {
    const { path } = useRouteMatch();

    return (
        <div className='w-full h-full flex analytics-background'>
            <SubscriptionProvider>
                <div className='w-full'>
                    <Switch>
                        <Route exact path={[`${path}/`]} component={AnalyticsComponent} />
                    </Switch>
                </div>
            </SubscriptionProvider>
        </div>
    );
};

export default AnalyticsPage;
