import { ClickAwayListener } from '@material-ui/core';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoutIcon } from '../../assets/icons/icon-logout-new.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/icon-question.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useStore } from '../../store';
import { handleLogout } from '../../utils/chromeExtension';
import { SIZES } from '../../utils/constants';
import { ProfilePicture } from '../basic/ProfilePicture';

const BottomSideMenuPopUp = ({ setShowPopUp }: { setShowPopUp: (val: boolean) => void }) => {
    const { t } = useTranslation();
    const store = useStore();
    const profilePicture = store.user.userData?.profilePictureUrl;
    const email = store.user.userData?.email;
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    const redirectToAccountTab = {
        pathname: `/account-details`,
        state: 'account',
    };

    const redirectToCopilotTab = {
        pathname: `/account-details`,
        state: 'copilot',
    };

    const closeSideMenuPopup = () => {
        setShowPopUp(false);
    };

    return (
        <ClickAwayListener onClickAway={closeSideMenuPopup}>
            <div className='w-72 p-6 bg-white shadow-container rounded-xl'>
                <div className='flex flex-row space-x-5 items-center mb-5'>
                    <Link to={redirectToAccountTab} onClick={closeSideMenuPopup}>
                        <ProfilePicture path={profilePicture} />
                    </Link>
                    <Link to={redirectToAccountTab} onClick={closeSideMenuPopup}>
                        <h1 className='font-normal text-sm text-darker text-opacity-75'>{email}</h1>
                    </Link>
                </div>
                <hr className='border-blue-dark border-opacity-10' />
                <div
                    className='flex flex-col space-y-5 my-4 text-normal text-darker text-opacity-75'
                    onClick={() => setShowPopUp(false)}
                >
                    {/* hiding the link to the plans page */}
                    <Link to='/workspace/spaces'>{t('manage')}</Link>
                    {/* <Link to='/plans'>My Plan</Link> */}
                </div>
                <hr className='border-blue-dark border-opacity-10' />
                <div className='flex flex-row items-center justify-between' onClick={closeSideMenuPopup}>
                    <a
                        className='flex flex-row space-x-4 mt-4 group'
                        href={`/sign-in`}
                        onClick={() => {
                            localStorage.clear();
                            Cookies.remove('bberry-token');
                            Cookies.remove('bberry-user');
                            if(!isMobile) handleLogout();
                        }}
                    >
                        <LogoutIcon className='h-4 opacity-50 group-hover:opacity-100' fill='#0F1044' />
                        <p className='text-normal text-darker text-opacity-75'>{t('logout')}</p>
                    </a>

                    <a 
                        className='flex flex-row space-x-5 cursor-pointer mt-3'
                        href='https://blankstate.ai/contact-us/'
                        target='_blank'
                    >
                        <QuestionIcon className='h-4 opacity-50 hover:opacity-100' fill='#0F1044' />
                    </a>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default BottomSideMenuPopUp;
