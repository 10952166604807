import { ChangeEvent, useEffect, useRef, useState } from 'react';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useTranslation } from 'react-i18next';

import { useSpaces } from '../../../hooks/useSpaces';
import { store } from '../../../store';
import { SpaceInfo } from '../../../store/SpacesStore';
import { MAX_SPACE_ICON_SIZE } from '../../../utils/constants';
import { getDocumentUrl } from '../../../utils/getDocumentUrl';
import { isFileValidation } from '../../../utils/validation';
import { ColorSelector } from '../../basic/ColorSelector.component';
import SpaceLogo, { selectVariant } from '../../basic/SpaceLogo.component';

const SpaceIcon = ({
    letter,
    color,
    setColor,
    spaceId,
    currentSpace,
    setFileValidation,
    onChange,
}: {
    letter: string;
    color: string;
    setColor: (value: string) => void;
    spaceId?: string;
    currentSpace: SpaceInfo | undefined;
    setFileValidation: (value: string | null) => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
    const { t } = useTranslation();
    const logoInputRef = useRef<HTMLInputElement | null>(null);
    const [preview, setPreview] = useState<string>('');

    const handleLogoUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const validation = isFileValidation(file, MAX_SPACE_ICON_SIZE, t);
            if (validation) {
                setFileValidation(validation);
                setTimeout(() => {
                    setFileValidation(null);
                }, 5000);
                return;
            }
            setPreview(URL.createObjectURL(file));
            onChange(e);
        }
    };

    const handleOpenFilePrompt = () => {
        if (logoInputRef.current) {
            logoInputRef.current.value = '';
            logoInputRef.current.click();
        }
    };

    useEffect(() => {
        if (currentSpace) {
            if (currentSpace.spaceLogo !== '') {
                setPreview(getDocumentUrl(+currentSpace.spaceLogo));
                store.spaces.currentSpaceLogoDocumentId = currentSpace.spaceLogo;
            } else setPreview('');
        }
    }, [currentSpace]);

    return (
        <div className='space-y-2'>
            <label className='text-sm font-medium text-darker text-opacity-75'>{t('spaces.icon')}</label>
            <div className='flex space-x-4 px-2'>
                {preview === '' ? (
                    <SpaceLogo
                        className='w-12 h-12  rounded-xl'
                        title={letter}
                        size='text-xl'
                        textColor={selectVariant(color)}
                        borderColor='border border-blue-dark border-opacity-10'
                    />
                ) : (
                    <img src={preview} className=' h-6 rounded-xl' alt='' />
                )}

                <div className='flex flex-row justify-center items-center'>
                    <ColorSelector
                        checked={color === 'orange'}
                        onClick={() => setColor('orange')}
                        className='bg-spaces-orange'
                    />
                    <ColorSelector
                        checked={color === 'teal'}
                        onClick={() => setColor('teal')}
                        className='bg-spaces-teal'
                    />
                    <ColorSelector
                        checked={color === 'lightBlue'}
                        onClick={() => setColor('lightBlue')}
                        className='bg-spaces-light-blue'
                    />
                    <ColorSelector
                        checked={color === 'blue'}
                        onClick={() => setColor('blue')}
                        className='bg-spaces-blue'
                    />
                    <ColorSelector
                        checked={color === 'pink'}
                        onClick={() => setColor('pink')}
                        className='bg-spaces-pink'
                    />
                    <ColorSelector
                        checked={color === 'black'}
                        onClick={() => setColor('black')}
                        className='bg-spaces-black'
                    />
                    <p>or</p>
                    <button
                        className='flex items-center justify-center bg-white border 
                        border-blue-dark border-opacity-10 p-1 w-7 h-7 rounded ml-4'
                        onClick={() => handleOpenFilePrompt()}
                    >
                        <FileUploadOutlinedIcon
                            style={{
                                fontSize: '1rem',
                                color: 'rgba(15, 16, 68, 0.5)',
                            }}
                            className='justify-center'
                        />
                        <input
                            ref={logoInputRef}
                            accept='image/*'
                            onChange={handleLogoUpload}
                            className='hidden'
                            type='file'
                        />
                    </button>
                </div>
            </div>
            <div className='relative flex justify-end items-center'>
                <p className='absolute text-xs right-0'>{t('spaces.1MB')}</p>
            </div>
            <button
                className='text-blue-dark text-opacity-50'
                onClick={() => {
                    store.spaces.currentSpaceLogoDocumentId = '';
                    setPreview('');
                }}
            >
                {t('spaces.reset')}
            </button>
        </div>
    );
};

export default SpaceIcon;
