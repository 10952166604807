import './style.css';
import { useEffect, useState } from 'react';

import { SearchIcon } from '@heroicons/react/solid';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { appInsights } from '../../../../../../AppInsights';
import { ReactComponent as AllIcon } from '../../../../../../assets/icons/icon-all.svg';
import { ReactComponent as FavIcon } from '../../../../../../assets/icons/icon-fav.svg';
import { ReactComponent as LockFilledIcon } from '../../../../../../assets/icons/icon-lock-filled.svg';
import { ReactComponent as LockIcon } from '../../../../../../assets/icons/icon-lock-outline.svg';
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/icon-plus-fill.svg';
import { RootState } from '../../../../../../Redux/store';
import { MetricService } from '../../../../../../services/metric/metric.service';
import { store } from '../../../../../../store';
import { Input } from '../../../../../basic/Input.component';
import CreateNewMetric from './CreateNewMetric.component';
import MetricCard, { clearSvg } from './MetricCard.component';
import MetricCardSkeleton from './MetricCardSkeleton.component';
import { FeedbackStatus, Metric, SortType } from './metricsType';
import SortComponent from './Sort.component';

export const MAX_FAVOURITES = 7;
const Metrics = ({
    handleDlClick,
    protocolUpdated,
    genMetricProtocol,
}: {
    handleDlClick: (event: React.MouseEvent<HTMLElement>, index?: number | null) => void;
    protocolUpdated: boolean;
    genMetricProtocol: (mode: string, title: string, inputValues: { nuance: string; threshold: number }[]) => void;
}) => {
    const { t } = useTranslation();
    const [addMetric, setAddMetric] = useState(false);
    const [metrics, setMetrics] = useState<Metric[]>([]);
    const [metricsData, setMetricsData] = useState<Metric[]>([]); // Track all data separately
    const [filter, setFilter] = useState<'All' | 'Fav' | 'Lock'>('All');
    const [updateMetric, setUpdateMetric] = useState(false);
    const [metricId, setMetricId] = useState<string | undefined>(undefined);
    const metricService = new MetricService();
    const [favouritesCount, setFavouritesCount] = useState<number>();
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);

    const openAddMetric = () => {
        clearSvg();
        setAddMetric(true);
        setMetricId(undefined);
    };

    const closeAddMetric = () => {
        setAddMetric(false);
    };

    const closeUpdateMetric = () => {
        setUpdateMetric(false);
        setMetricId(undefined);
    };

    const { isLoading, refetch } = useQuery<Metric[]>(
        ['getAllMetrics', evaId],
        async () => {
            try {
                const data = await metricService.getAllMetrics(evaId as string);
                const favMetrics = data.filter((metric: Metric) => metric.isFavorite);
                setFavouritesCount(favMetrics.length);
                setMetricsData(data);
                setMetrics(data);
                return data;
            } catch (e) {
                if (e instanceof Error) {
                    Sentry.captureException(e);
                    appInsights.trackException({ error: e });
                }
            }
        },
        { enabled: !!evaId },
    );

    // Handle Favorite Toggle
    const handleFavToggle = async (metricId: string, status: boolean) => {
        try {
            await metricService.enableOrDisableFavourite(metricId, status);
            refetch();
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    };

    // Handle Lock Toggle
    const handleLockToggle = async (metricId: string, status: boolean) => {
        try {
            await metricService.enableOrDisableLock(metricId, status);
            refetch();
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    };

    // Handle Enable/Disable Metric
    const enableOrDisableMetric = async (metricId: string, status: boolean) => {
        try {
            await metricService.enableOrDisableMetric(metricId, status);
            setMetricId(undefined);
            refetch();
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    };

    // Filter based on type
    const filteredMetrics = (type: 'All' | 'Fav' | 'Lock') => {
        if (type === 'All') {
            setMetrics(metricsData || []); // Show all metrics
        } else if (type === 'Fav') {
            setMetrics((metricsData || []).filter((metric) => metric.isFavorite));
        } else if (type === 'Lock') {
            setMetrics((metricsData || []).filter((metric) => metric.isLocked));
        }
    };

    const handleSort = (sortType: SortType) => {
        if (sortType === SortType.AtoZ) {
            setMetrics((prevMetrics) => [...prevMetrics].sort((a, b) => a.name.localeCompare(b.name)));
            return;
        }
        if (sortType === SortType.ZtoA) {
            setMetrics((prevMetrics) => [...prevMetrics].sort((a, b) => b.name.localeCompare(a.name)));
            return;
        }
        if (sortType === SortType.Category) {
            setMetrics((prevMetrics) => [...prevMetrics].sort((a, b) => a.category.localeCompare(b.category)));
            return;
        }
        if (sortType === SortType.Date) {
            setMetrics((prevMetrics) =>
                [...prevMetrics].sort((a, b) => {
                    // Handle the case where createdAt might be undefined or null
                    const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0); // Default to epoch if undefined
                    const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0); // Default to epoch if undefined
                    return dateB.getTime() - dateA.getTime();
                }),
            );
            return;
        }
    };

    const handleSearch = (searchText: string) => {
        setMetricId(undefined);
        clearSvg();
        if (searchText === '') {
            setMetrics(metricsData);
            return;
        }
        setMetrics((prevMetrics) =>
            prevMetrics.filter((metric) => metric.name.toLowerCase().includes(searchText.toLowerCase())),
        );
    };

    const deleteMetric = async (metricId: string) => {
        try {
            await metricService.deleteMetric(metricId);
            refetch();
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    };

    const handleAddFeedback = async (metricId: string, status: string) => {
        try {
            await metricService.addMetricFeedback(metricId, status);
            refetch();
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    };

    const handleDeleteFeedback = async (metricId: string) => {
        try {
            await metricService.deleteMetricFeedback(metricId);
            refetch();
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    };

    const getFeedbackStatus = (metric: Metric) => {
        const userId = store.user.userData?.id;
        metric.like?.forEach((like) => {
            if (like.userId === userId) {
                return FeedbackStatus.Like;
            }
        });
        metric.dislike?.forEach((dislike) => {
            if (dislike.userId === userId) {
                return FeedbackStatus.Dislike;
            }
        });
        return undefined;
    };

    const updateOrCreateMetricData = async (metricId: string, updatedFields: Partial<Metric>, mode: string) => {
        setFilter('All');
        if (mode === 'create') {
            setMetrics((prevMetrics) => [{ id: metricId, ...updatedFields } as Metric, ...prevMetrics]);
            setTimeout(async () => {
                const updatedMetric = await metricService.getMetricDetails(metricId);
                setMetrics((prevMetrics) =>
                    prevMetrics.map((metric) =>
                        metric.id === metricId
                            ? { ...updatedMetric, isLoading: false, isEnabled: updatedMetric.isEnabled }
                            : metric,
                    ),
                );
                setMetricsData((prevMetricsData) => [{ ...updatedMetric, isLoading: false }, ...prevMetricsData]);
            });
        } else {
            setMetrics((prevMetrics) =>
                prevMetrics.map((metric) => (metric.id === metricId ? { ...metric, ...updatedFields } : metric)),
            );
            setTimeout(async () => {
                const updatedMetric = await metricService.getMetricDetails(metricId);
                setMetrics((prevMetrics) =>
                    prevMetrics.map((metric) =>
                        metric.id === metricId
                            ? { ...updatedMetric, isLoading: false, isEnabled: updatedMetric.isEnabled }
                            : metric,
                    ),
                );
                setMetricsData((prevMetricsData) =>
                    prevMetricsData.map((metric) =>
                        metric.id === metricId ? { ...updatedMetric, isLoading: false } : metric,
                    ),
                );
            });
        }
    };

    const toggleMetric = async (metricId: string) => {
        try {
            const relatedProtocols = await metricService.getMetricProtocols(metricId);
            updateOrCreateMetricData(metricId, { relatedProtocols: relatedProtocols }, 'update');
            const metricIndex = metrics.findIndex((metric) => metric.id === metricId);
            if (!metricIndex) {
                return;
            }
            const reorderedMetrics = [
                metrics[metricIndex], // Place the found metric first
                ...metrics.slice(0, metricIndex), // Add all metrics before it
                ...metrics.slice(metricIndex + 1), // Add all metrics after it
            ];
            setMetrics(reorderedMetrics);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    };

    if (addMetric || updateMetric) {
        return (
            <CreateNewMetric
                mode={updateMetric ? 'update' : 'create'}
                closeMetric={updateMetric ? closeUpdateMetric : closeAddMetric}
                metricId={metricId}
                updateMetric={updateOrCreateMetricData}
                deleteMetric={deleteMetric}
            />
        );
    }
    return (
        <div className='metrics-container no-scrollbar'>
            <div className='metrics-header'>
                <p>{t('metrics')}</p>
                <button className='add-metric' onClick={openAddMetric} data-title={t('createMetric')}>
                    <PlusIcon className='w-3 h-3' fill='#11113F' />
                </button>
            </div>
            <div>
                <Input
                    left={<SearchIcon className='h-4 ml-4' fill='#6FBFE180' />}
                    id='search-metric'
                    className='rounded-md metric-select-border'
                    background='bg-metric bg-opacity-10'
                    textColor='text-metric'
                    border='border border-metric border-opacity-20'
                    placeholder={t('searchMetric')}
                    onChange={(e) => {
                        handleSearch(e);
                        setMetricId(undefined);
                    }}
                />
            </div>
            <div className='metric-filters'>
                <div className='flex space-x-2'>
                    <button
                        className={clsx('metric-filter', filter === 'All' && 'metric-filter-selected')}
                        data-title={t('all')}
                        onClick={() => {
                            setFilter('All');
                            filteredMetrics('All');
                        }}
                    >
                        <AllIcon className='w-3 h-3' fill='#6FBFE1' fillOpacity={0.75} />
                    </button>
                    <button
                        className={clsx('metric-filter', filter === 'Fav' && 'metric-filter-selected')}
                        data-title={t('favorites')}
                        onClick={() => {
                            setFilter('Fav');
                            filteredMetrics('Fav');
                        }}
                    >
                        <FavIcon
                            className='w-3 h-3'
                            stroke='#6FBFE1'
                            strokeOpacity='0.75'
                            fill={filter === 'Fav' ? '#6FBFE1' : 'transparent'}
                        />
                    </button>
                    <button
                        className={clsx('metric-filter', filter === 'Lock' && 'metric-filter-selected')}
                        data-title={t('locked')}
                        onClick={() => {
                            setFilter('Lock');
                            filteredMetrics('Lock');
                        }}
                    >
                        {filter === 'Lock' ? (
                            <LockFilledIcon className='w-3 h-3' fill='#6FBFE1' />
                        ) : (
                            <LockIcon className='w-3 h-3' fill='#6FBFE1' fillOpacity={0.75} />
                        )}
                    </button>
                </div>
                <div>
                    <SortComponent handleSort={handleSort} />
                </div>
            </div>
            {isLoading ? (
                <div className='space-y-3 mt-3'>
                    {Array.from({ length: 7 }, (_, index) => (
                        <MetricCardSkeleton key={index} expanded={false} checked />
                    ))}
                </div>
            ) : (
                <div className='metric-responses metric-scrollbar'>
                    {metrics?.map((metric) =>
                        metric.isLoading ? (
                            <MetricCardSkeleton expanded />
                        ) : (
                            <MetricCard
                                key={metric.id}
                                metric={metric}
                                toggledMetricId={metricId}
                                toggleFavourite={(status) => metric.id && handleFavToggle(metric.id as string, status)}
                                toggleMetric={(status) =>
                                    metric.id && enableOrDisableMetric(metric.id as string, status)
                                }
                                toggleLock={(status) => metric.id && handleLockToggle(metric.id as string, status)}
                                deleteMetric={() => metric.id && deleteMetric(metric.id as string)}
                                feedbackStatus={getFeedbackStatus(metric)}
                                addFeedback={(status) => metric.id && handleAddFeedback(metric.id as string, status)}
                                deleteFeedback={() => metric.id && handleDeleteFeedback(metric.id as string)}
                                updateMetric={() => {
                                    setUpdateMetric(true);
                                    setMetricId(metric.id);
                                }}
                                handleToggleFilter={() => metric.id && toggleMetric(metric.id as string)}
                                toggle={metricId === metric.id}
                                onToggle={() =>
                                    metricId === metric.id ? setMetricId(undefined) : setMetricId(metric.id)
                                }
                                handleDlClick={handleDlClick}
                                protocolUpdated={protocolUpdated}
                                genMetricProtocol={genMetricProtocol}
                                updateMetricProtocols={updateOrCreateMetricData}
                                maxFavReached={favouritesCount === MAX_FAVOURITES}
                            />
                        ),
                    )}
                </div>
            )}
        </div>
    );
};

export default observer(Metrics);
