import React, { ChangeEvent, useRef } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import DefaultProfilePicture from '../../assets/profilePictures/img-profile.svg';
import { useProfile } from '../../hooks/useProfile';
import { useStore } from '../../store';
import { MAX_PROFILE_PICTURE_SIZE } from '../../utils/constants';
import { isFileValidation } from '../../utils/validation';
import { IconAlert } from './IconAlert.component';

type AccountPictureProps = {
    imageUrl?: string;
    edit?: boolean;
};

const AccountPicture : React.FC<AccountPictureProps> = observer(({ imageUrl, edit }: AccountPictureProps) => {
    const { t } = useTranslation();
    const [fileValidation, setFileValidation] = React.useState<string | null>(null);
    const { uploadProfilePicture } = useProfile();

    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;        
        if (file) {
            const validation = isFileValidation(file, MAX_PROFILE_PICTURE_SIZE, t);
            if(validation) {
                setFileValidation(validation);
                setTimeout(() => {
                    setFileValidation(null);
                }, 5000);
                return;
            }
            uploadProfilePicture(file);
        }
    };

    const handleOpenFilePrompt = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    };

    const store = useStore();
    const profilePicture = imageUrl || store.user.userData?.profilePictureUrl;
    return (
        <div
            className='p-1 rounded-full h-20 w-20 md:h-32 md:w-32 space-y-4 mb-4'
        >
            <div
                className='w-full h-full rounded-full relative bg-center bg-no-repeat bg-cover object-cover border-2 border-white'
                style={{
                    backgroundImage: profilePicture ? `url(${profilePicture})` : `url(${DefaultProfilePicture})`,
                }}
            >

                <div
                    onClick={handleOpenFilePrompt}
                    className='absolute flex rounded-full w-4 h-4 bottom-1 right-1 items-center justify-center
                    text-[6px] text-white bg-white  scale-150 cursor-pointer transition-all duration-500
                    md:w-5 md:h-5 md:text-xs md:bottom-3 md:right-2'
                >
                    <EditIcon fontSize='inherit' sx={{ color: 'rgba(15, 16, 68, 0.75)'}}/>
                </div>
                
                <input ref={inputRef} accept='image/*' onChange={handleUpload} className='hidden' type='file' />
            </div>
            {fileValidation && (
                <div className='w-full flex items-center justify-center whitespace-nowrap'>
                    <IconAlert message={fileValidation}/>
                </div>
            )}
         
        </div>
    )
});

export default AccountPicture