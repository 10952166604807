import { useTranslation } from 'react-i18next';

import starIconHoverBlack from '../../../src/assets/icons/black-star.svg';
import starIconHoverBlack1 from '../../../src/assets/icons/black-starIcon.svg';
import starIconHover from '../../../src/assets/icons/hover-star.svg';
import starIcon from '../../../src/assets/icons/star-icon.svg';

interface StarIconProps {
    isChangePlusIcon?: boolean;
    isHover?: boolean;
    blackIcon?: boolean;
}

export const StarIcon: React.FC<StarIconProps> = ({ isChangePlusIcon, isHover, blackIcon }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='main_star_button relative'>
                {isHover ? (
                    <div className='star_icon'>
                        {isChangePlusIcon ? (
                            <>
                                {blackIcon ? (
                                    <img className='img1' src={starIconHoverBlack1} alt='' />
                                ) : (
                                    <img className='img1' src={starIcon} alt='' />
                                )}

                                <img className='img2' src={starIconHoverBlack} alt='' />
                            </>
                        ) : (
                            <>
                                <img className='img1' src={starIcon} alt='' />
                                <img className='img2' src={starIconHover} alt='' />
                            </>
                        )}
                    </div>
                ) : (
                    <div className='starIcon'>
                        <img className='img1' src={starIcon} alt='' />
                    </div>
                )}
                <div className='onHover_msg'>
                    <span> {t('includedInOversightTier')}</span>
                </div>
            </div>
        </>
    );
};
