import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as TestUser } from '../../../../../../assets/icons/icon-test-user.svg';
import MoodSlider from './MoodSlider.component';

export enum UserMoodType {
    VeryNegative = 'Very Negative',
    Negative = 'Negative',
    Neutral = 'Neutral',
    Positive = 'Positive',
    VeryPositive = 'Very Positive',
}

export const userMood: { [key: number]: UserMoodType } = {
    0: UserMoodType.VeryNegative,
    25: UserMoodType.Negative,
    50: UserMoodType.Neutral,
    75: UserMoodType.Positive,
    100: UserMoodType.VeryPositive,
};

export const userMoodValues: { [key in UserMoodType]: number } = {
    [UserMoodType.VeryNegative]: 0,
    [UserMoodType.Negative]: 25,
    [UserMoodType.Neutral]: 50,
    [UserMoodType.Positive]: 75,
    [UserMoodType.VeryPositive]: 100,
};

// Function to check if an element is overflowing
export function isOverflowing(element: HTMLElement): boolean {
    return element.scrollWidth > element.clientWidth;
}

const UserMood = ({
    user1Name,
    user2Name,
    userMoodValue1,
    userMoodValue2,
    handleUserMood1,
    handleUserMood2,
}: {
    user1Name: string | null;
    user2Name: string | null;
    userMoodValue1: number;
    userMoodValue2: number;
    handleUserMood1: (value: number) => void;
    handleUserMood2: (value: number) => void;
}) => {
    const user1Ref = useRef<HTMLDivElement>(null);
    const user2Ref = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        // Check overflow for user1Name
        if (user1Ref.current && isOverflowing(user1Ref.current)) {
            user1Ref.current.classList.add('overflow');
        } else {
            user1Ref.current?.classList.remove('overflow');
        }

        // Check overflow for user2Name
        if (user2Ref.current && isOverflowing(user2Ref.current)) {
            user2Ref.current.classList.add('overflow');
        } else {
            user2Ref.current?.classList.remove('overflow');
        }
    }, [user1Name, user2Name]);

    return (
        <div className='w-full flex space-between'>
            <p className='text-sm font-medium mt-2'>{t('mood')}</p>
            <div className='w-full text-sm user-mood-container'>
                <div className='w-full flex flex-wrap items-center'>
                    <div className='flex items-center space-x-2 whitespace-nowrap'>
                        <TestUser className='w-4 h-4' fill='#B0E1FD' />
                        <div className='mood-user-1'>
                            <div className='mood-user-name' ref={user1Ref}>
                                <span>{user1Name}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 mood-slider'>
                        <MoodSlider userMoodValue={userMoodValue1} handleUserMood={handleUserMood1} />
                    </div>
                    <div className='flex-none mood-text'>
                        <p>{t(`userMood.${userMood[userMoodValue1]}`)}</p>
                    </div>
                </div>
                <div className='w-full flex flex-wrap items-center'>
                    <div className='flex items-center space-x-2 whitespace-nowrap'>
                        <TestUser className='w-4 h-4' fill='#8799FF' />
                        <div className='mood-user-2'>
                            <div className='mood-user-name' ref={user2Ref}>
                                <span>{user2Name}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex-1 mood-slider'>
                        <MoodSlider userMoodValue={userMoodValue2} handleUserMood={handleUserMood2} />
                    </div>
                    <div className='flex-none mood-text'>
                        <p>{t(`userMood.${userMood[userMoodValue2]}`)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserMood;
