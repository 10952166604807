import { makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { getCategoryIcon } from '../../../../../../utils/categoryUnicode';

export const ChangeResponseType = {
    Positive: 'Positive',
    Negative: 'Negative',
};

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        backgroundColor: '#0014FF',
        fontFamily: 'Poppins',
        color: 'white',
        fontSize: '10px',
        fontWeight: 500,
    },
}));

const ChangeResponse = ({
    type,
    posneg,
    setPosNeg,
    handleActionChange,
}: {
    type: string;
    posneg: boolean;
    setPosNeg: (value: boolean) => void;
    handleActionChange: () => void;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const handleChange = () => {
        setPosNeg(!posneg);
        handleActionChange();
    };
    return (
        <div className='flex'>
            <Tooltip
                title={
                    type === 'Positive' ? t('evaluateChangeResponse.positive') : t('evaluateChangeResponse.negative')
                }
                classes={{
                    tooltip: classes.customTooltip,
                }}
                placement='left-start'
            >
                <button className='change_response_btn' onClick={handleChange}>
                    <div className='flex items-center justify-center change-response'>
                        <p>{getCategoryIcon('Cross')}</p>
                        {type === 'Positive' ? (
                            <p>{getCategoryIcon('Thumbs Up')}</p>
                        ) : (
                            <p>{getCategoryIcon('Thumbs Down')}</p>
                        )}
                    </div>
                </button>
            </Tooltip>
        </div>
    );
};

export default ChangeResponse;
