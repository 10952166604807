import { makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { organisationTier } from './constant';

export type TierFeature = {
    title: string;
    tooltip?: string;
    children?: TierSubFeature[];
};

export type TierSubFeature = {
    title: string;
    tooltip?: string;
    sheild: string | React.ReactNode | null;
    oversight: string | React.ReactNode | null;
    upcoming?: boolean;
};

const useStyles = makeStyles((theme) => ({
    customTooltip: (props) => ({
        backgroundColor: '#0014FF',
        fontFamily: 'Poppins',
        color: 'white',
        fontSize: '10px',
        fontWeight: 500,
    }),
}));

const TierTable = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className='w-full space-y-2'>
            {organisationTier.map((tier, index) => (
                <div key={index} className='w-full space-y-4 tier_table'>
                    <div className='w-full bg-primary-darker  bg-opacity-3 p-2 flex items-center space-x-2'>
                        <Tooltip
                            title={t(tier.tooltip || '')}
                            classes={{
                                tooltip: classes.customTooltip,
                            }}
                            placement='right'
                        >
                            <h1>{t(tier.title)}</h1>
                        </Tooltip>
                    </div>
                    <div className='space-y-4'>
                        {tier.children?.map((subFeature, subIndex) => (
                            <div
                                key={subIndex}
                                className='w-full flex items-center text-xs text-darker text-opacity-75 font-medium cursor-default'
                            >
                                <div className='pl-2 flex space-x-2 w-1/2 items-center'>
                                    <Tooltip
                                        title={t(subFeature.tooltip || '')}
                                        classes={{
                                            tooltip: classes.customTooltip,
                                        }}
                                        placement='right'
                                    >
                                        <div className='flex space-x-2 items-center'>
                                            <p className='whitespace-nowrap sub-heading font-medium '>
                                                {t(subFeature.title)}
                                            </p>
                                            {subFeature.upcoming && (
                                                <div className='bg-primary-darker text-white p-1 rounded text-xs'>
                                                    {t('subscription_tier.upcoming')}
                                                </div>
                                            )}
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className='flex-1 flex justify-center'>
                                    {subFeature.sheild ? (
                                        <p className='sub-heading font-normal'>
                                            {typeof subFeature.sheild === 'string'
                                                ? t(subFeature.sheild)
                                                : subFeature.sheild}
                                        </p>
                                    ) : (
                                        <p>-</p>
                                    )}
                                </div>
                                <p className='pr-4 flex w-1/4 justify-end text-right sub-heading font-normal'>
                                    {typeof subFeature.oversight === 'string'
                                        ? t(subFeature.oversight)
                                        : subFeature.oversight}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TierTable;
