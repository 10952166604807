import dayjs from 'dayjs';
import _ from 'lodash';

import { ReactComponent as CrossIcon } from '../../assets/icons/icon-cross-fill.svg';
import { useSession } from '../../hooks/useSession';
import { Session } from '../../store/SessionStore';
import DomainIcon from './DomainIcon.component';
import SessionDomainCard from './SessionDomainCard.component';

export const renderDate = (date: string) => {
    const today = dayjs();
    const yesterday = dayjs().subtract(1, 'day');
    const timestampDate = dayjs(date).format('DD MMM YYYY');

    if (dayjs(date).isSame(today, 'day')) {
        return 'Today';
    } else if (dayjs(date).isSame(yesterday, 'day')) {
        return 'Yesterday';
    } else {
        return timestampDate;
    }
};
const SessionDomain = ({
    domain,
    data,
    handleDeleteSessionsByDomain,
    handleDeleteSession,
}: {
    domain: string;
    data: Session[];
    handleDeleteSessionsByDomain: (domain: string) => void;
    handleDeleteSession: (domain: string, id: number) => void;
}) => {
    const sessionByDate = _.groupBy(data, (item) => dayjs(item.date).format('YYYY-MM-DD'));
    const mappedData = _.map(sessionByDate, (value, key) => ({
        date: key,
        data: value,
    }));
    const { deleteSessionsByDomain } = useSession();

    return (
        <div className='w-full items-center justify-center space-y-2'>
            <div className='w-full flex justify-between text-darker text-opacity-75 text-sm'>
                <div className='flex space-x-2 font-semibold items-center'>
                    <DomainIcon domain={domain} />
                    <p className='text-sm font-semibold'>{domain}</p>
                    <button
                        className='flex items-center justify-center bg-white bg-opacity-75 rounded-full p-1 w-4 h-4 hover:bg-primary-darker hover:bg-opacity-5'
                        onClick={() => {
                            deleteSessionsByDomain(domain);
                            handleDeleteSessionsByDomain(domain);
                        }}
                    >
                        <CrossIcon fill='rgba(15, 16, 68, 0.75)' className='w-3 h-3' />
                    </button>
                </div>
                {/* <div className="flex space-x-2 items-center font-normal">
                    <p>monitor</p>
                    <Switch checked={checked} onChange={() => setCkecked(!checked)}/>
                </div> */}
            </div>
            <div>
                <div className='overflow-y-auto scrollbar overflow-x-hidden pr-1 max-h-[250px]'>
                    <div
                        className='overflow-hidden'
                        style={{
                            border: '1px solid #5A44FF1A',
                            borderRadius: '10px',
                        }}
                    >
                        {mappedData.map((item, index) => (
                            <div className='space-y-4' key={index}>
                                {/* <p className='text-sm text-darker text-opacity-50'>{renderDate(item.date)}</p> */}
                                <SessionDomainCard
                                    data={item.data}
                                    key={index}
                                    handleDeleteSession={handleDeleteSession}
                                    isLastIndex={mappedData.length == index + 1 ? true : false}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionDomain;
