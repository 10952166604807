import { useEffect, useState } from 'react';

import { Menu } from '@material-ui/core';
import { useTour } from '@reactour/tour';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as DownArrowIcon } from '../../../assets/icons/icon-down-arrow.svg';
import blankState from '../../../assets/logo/logo_b_dark.png';
import blueberryDark from '../../../assets/logo/logo_blankstate_beta_dark.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useSpaces } from '../../../hooks/useSpaces';
import { setRemoveArchiveEvaIdConfirmation, setSaveConfirmModal } from '../../../Redux/EvaInfo';
import { RootState } from '../../../Redux/store';
import { useStore } from '../../../store';
import { SpaceInfo } from '../../../store/SpacesStore';
import { SIZES } from '../../../utils/constants';
import { getDocumentUrl } from '../../../utils/getDocumentUrl';
import SpaceLogo from '../../basic/SpaceLogo.component';
import { Loader } from '../../Loader';
import BottomSideMenu from '../../sideMenu/BottomSideMenu';
import DisableHeader from './DisableHeader';
import NavbarSpaceMenu from './NavbarSpaceMenu.component';

const NavbarBackground = () => {
    const { t } = useTranslation();
    const { setIsOpen, setCurrentStep, currentStep, isOpen } = useTour();
    const location = useLocation();
    const dispatch = useDispatch();
    const [anchorWorkspace, setAnchorWorkspace] = useState<null | HTMLElement>(null);
    const archiveEvaId = useSelector((state: RootState) => state.EvaInfo.archiveEvaId);
    const isChangeProtocolData = useSelector((state: RootState) => state.EvaInfo.isChangeProtocolData);

    const spaceOpen = Boolean(anchorWorkspace);
    const spaceClose = () => {
        setIsOpen(false);
        setAnchorWorkspace(null);
    };
    const spaceClick = (event: React.MouseEvent<HTMLElement>) => {
        if (archiveEvaId !== null) {
            dispatch(setRemoveArchiveEvaIdConfirmation(true));
        } else {
            setAnchorWorkspace(event.currentTarget);
        }
    };

    const store = useStore();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    const spinner = document.getElementById('spinner');
    const history = useHistory();
    const { getSpaceDetails } = useSpaces();

    useEffect(() => {
        console.log('store.initiated', store.initiated);
    }, [store.initiated]);

    const showSideMenu = true;

    useEffect(() => {
        setTimeout(() => {
            const currentSpaceId = localStorage.getItem('spaceId');
            if (currentSpaceId && location.pathname.includes(currentSpaceId)) {
                getSpaceDetails(currentSpaceId).then((res: SpaceInfo) => {
                    if (res) {
                        store.spaces.updateSelectedSpaceId(currentSpaceId);
                        store.spaces.setCurrentSelectedWorkSpaceInfo({
                            workspaceBanner: res.spaceBanner,
                            workspaceIcon: res.spaceIcon,
                            workspaceName: res.spaceName,
                            workspaceColor: res.spaceColor,
                            workspaceLogo: res.spaceLogo,
                            isOrganisation: res.organisationSpace?.length ? true : false,
                        });
                    }
                });
            }
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    if (!store.initiated && spinner !== null) {
        return (
            <div className='grid items-center'>
                <Loader />
            </div>
        );
    }

    if (isMobile) {
        return (
            <nav
                className='fixed top-0 right-0 left-0 bg-backgroundColor overflow-hidden w-full max-w-[100vw] z-20 h-14
                    flex flex-wrap items-center justify-between px-4'
            >
                <div className='h-6 w-6 mr-5 flex items-center justify-center'>
                    {/*<img src={businessLogo} alt='logo' />*/}
                </div>
            </nav>
        );
    }
    const hideLogo = () => {
        if (location.pathname.includes('organisation')) return true;
    };

    return (
        <nav className={clsx('flex justify-start top_header fixed top-0 right-0  w-full z-20 h-14', 'px-6')}>
            {store.initiated && (
                <div
                    className={clsx(
                        'absolute flex top_menu z-20 flex-row space-x-3 cursor-pointer items-center justify-start',
                        'rounded py-2 my-1 hover:bg-primary-darker hover:bg-opacity-3',
                    )}
                    onClick={(e) => {
                        if (!isChangeProtocolData) {
                            spaceClick(e);
                        }
                    }}
                >
                    <div className='flex text-sm text-darker text-opacity-75 space-x-2 items-center cursor-pointer'>
                        <>
                            {store.spaces.currentSelectedWorkSpaceInfo.workspaceName !== '' ? (
                                <div className='flex items-center space-x-2'>
                                    <SpaceLogo
                                        className='w-8 h-8 capitalize'
                                        // @ts-ignore
                                        variant={
                                            store.spaces.currentSelectedWorkSpaceInfo.workspaceColor
                                                ? store.spaces.currentSelectedWorkSpaceInfo.workspaceColor
                                                : 'workSpace'
                                        }
                                        title={
                                            store.spaces.currentSelectedWorkSpaceInfo.workspaceName !== ''
                                                ? store.spaces.currentSelectedWorkSpaceInfo.workspaceName[0]
                                                : `${store.user.userData?.firstName[0]}`
                                        }
                                        preview={
                                            store.spaces.currentSelectedWorkSpaceInfo.workspaceLogo &&
                                            getDocumentUrl(+store.spaces.currentSelectedWorkSpaceInfo.workspaceLogo)
                                        }
                                        organization={store.spaces.currentSelectedWorkSpaceInfo.isOrganisation}
                                        borderColor={
                                            store.spaces.currentSelectedWorkSpaceInfo.isOrganisation
                                                ? 'border-blue-dark border-opacity-10'
                                                : ''
                                        }
                                        imgSize={6}
                                    />
                                    <div className='text-sm capitalize text-darker text-opacity-75 whitespace-nowrap w-24 truncate'>
                                        {store.spaces.currentSelectedWorkSpaceInfo.workspaceName !== ''
                                            ? store.spaces.currentSelectedWorkSpaceInfo.workspaceName
                                            : t('navbar.home')}
                                    </div>
                                </div>
                            ) : (
                                <div className='flex items-center space-x-4 cursor-pointer'>
                                    <div className='bg-white p-2 border rounded-lg border-blue-light border-opacity-10'>
                                        {/* <BberryIcon className='w-3 h-3' fill='rgba(15, 16, 68, 0.75)' /> */}
                                        <img src={blankState} alt='logo' className='w-3 h-3' />
                                    </div>
                                    <div className='flex-1 text-sm capitalize text-darker text-opacity-75 whitespace-nowrap overflow-hidden'>
                                        <p className='truncate'>{t('navbar.home')}</p>
                                    </div>
                                </div>
                            )}
                        </>
                        <DownArrowIcon className='w-2' />
                    </div>
                </div>
            )}

            <Menu
                id='workspace-menu'
                anchorEl={anchorWorkspace}
                open={spaceOpen}
                onClose={spaceClose}
                MenuListProps={{
                    style: {
                        backgroundColor: 'white',
                    },
                }}
                PaperProps={{
                    style: {
                        width: '396px',
                        borderRadius: '7px',
                        boxShadow: '0px 4px 20px rgba(15, 16, 68, 0.18)',
                        marginTop: '40px',
                        backgroundColor: 'white',
                    },
                }}
            >
                <NavbarSpaceMenu handleClose={spaceClose} />
            </Menu>

            <div className='w-full flex items-center justify-center relative'>
                {showSideMenu && (
                    <div className={clsx('w-16', location.pathname === '/plans' ? 'bg-primary-darker' : '')}>
                        <BottomSideMenu />
                    </div>
                )}

                <div className='w-full flex items-center justify-center'>{!hideLogo() && <DisableHeader />}</div>
            </div>
        </nav>
    );
};

export default observer(NavbarBackground);
