import { TFunction } from 'i18next';

export type Validator = (value: string, values: Record<string, string>) => string | undefined;
export const required = (value: string, t: TFunction): string | undefined => {
    return value ? undefined : t('validation.required');
};
export const isSelected = (value: string) => (value === 'Select' ? ('An item must be selected' as string) : undefined);
export const isEmail = (value: string, t: TFunction) =>
    !isEmailValid(value) ? t('validation.invalidEmail') : undefined;
export const isUrl = (value: string, t: TFunction) => (!isValidUrl(value) ? t('validation.validUrl') : undefined);
export const isIban = (value: string) => (!isValidIban(value) ? ('Invalid IBAN' as string) : undefined);
export const isNumber = (value: string) => (!value.match(/^-?\d*\.?\d*$/) ? 'Must be a valid number' : undefined);
export const isPhoneNumber = (value: string) =>
    !value.match(/^[0-9]{10}$/) ? 'Must be a valid phone number' : undefined;
export const isValidPassword = (value: string, t: TFunction) =>
    value.length < 8
        ? t('validation.passwordMinLength')
        : !/(?:(?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value)
        ? t('validation.passwordPattern')
        : value.length > 40
        ? t('validation.passwordMaxLength')
        : undefined;
export const confirmPassword = (value: string, values: Record<string, string>, t: TFunction) =>
    value !== values.password ? t('validation.passwordMatch') : undefined;
export const and = (...validators: Validator[]): Validator => {
    return (value: string, values: Record<string, string>) =>
        validators.reduce((error, validator) => error || validator(value, values), undefined as string | undefined);
};

function isEmailValid(email: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const isValidUrl = (url: string) => {
    if (url === '') return true;
    const re =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return re.test(url);
};

export const isValidIban = (iban: string) => {
    const re = /[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){3}([0-9]{2})\s?/;

    return re.test(iban);
};

export const isPasswordMatch = (password: string, confirmPassword: string, t: TFunction) =>
    password !== confirmPassword ? t('validation.passwordMatch') : undefined;

export const isFileValidation = (file: File, size: number, t: TFunction) => {
    if (!file.type.startsWith('image/')) return t('validation.invalidFile');
    if (file.size > size) return t('validation.invalidFileSize', { size: (size / 1024 / 1024).toFixed(2) });
    return null;
};

export const isPasswordContainsSpace = (value: string, t: TFunction) =>
    value && !value.includes(' ') ? undefined : t('validation.passwordNoSpaces');
