import Binary from '../../../../../../src/assets/icons/BinaryIcon.svg';
import Forecast from '../../../../../../src/assets/icons/ForecastIcon.svg';
import GrowthRate from '../../../../../../src/assets/icons/GrowthRateIcon.svg';
import Index from '../../../../../../src/assets/icons/IndexIcon.svg';
import Number from '../../../../../../src/assets/icons/NumberIcon.svg';
import Rank from '../../../../../../src/assets/icons/RankIcon.svg';
import Ratio from '../../../../../../src/assets/icons/RatioIcon.svg';
import RiskLevel from '../../../../../../src/assets/icons/RiskLevelIcon.svg';
import Percentage from '../../../../../../src/assets/icons/ScoreEnable.svg';
import Score from '../../../../../../src/assets/icons/ScoreIcon.svg';
import Trend from '../../../../../../src/assets/icons/TrendIcon.svg';
import Velocity from '../../../../../../src/assets/icons/VelocityIcon.svg';
import { ChartType, MetricType } from '../types';

// Data structure to store metrics options based on specific types;
// used to retrieve data filtered by metric type
export const MetricsOptionData = [
    {
        name: MetricType.Percentage,
        icon: Percentage,
        chartType: ChartType.LineChart,
    },
    {
        name: MetricType.Ratio,
        icon: Ratio,
        chartType: ChartType.LineChart,
    },
    {
        name: MetricType.Number,
        icon: Number,
        chartType: ChartType.LineChart,
    },

    {
        name: MetricType.Score,
        icon: Score,
        chartType: ChartType.LineChart,
    },

    {
        name: MetricType.Trend,
        icon: Trend,
        chartType: ChartType.LineChart,
    },
    {
        name: MetricType.GrowthRate,
        icon: GrowthRate,
        chartType: ChartType.BarChart,
    },
    {
        name: MetricType.Velocity,
        icon: Velocity,
        chartType: ChartType.LineChart,
    },
    {
        name: MetricType.Forecast,
        icon: Forecast,
        chartType: ChartType.LineChart,
    },
    // {
    //     name: MetricType.Rank,
    //     icon: Rank,
    //     chartType: ChartType.BarChart,
    // },
    {
        name: MetricType.Index,
        icon: Index,
        chartType: ChartType.LineChart,
    },
    {
        name: MetricType.RiskLevel,
        icon: RiskLevel,
        chartType: ChartType.GaugeChart,
    },
    {
        name: MetricType.Binary,
        icon: Binary,
        chartType: ChartType.LineChart,
    },
];

export const AverageMetricGraph = '#6fbfe1';

export const MetricTypeIcon = (metricType: string) => {
    const icon = MetricsOptionData.find((item) => item.name === metricType);
    return icon?.icon;
};
