import { useSelector } from 'react-redux';

import { RootState } from '../../Redux/store';

interface AnimatedCircleProps {}
export const AnimatedCircle: React.FC<AnimatedCircleProps> = () => {
    const isEvaluateMode = useSelector((state: RootState) => state.EvaInfo.isEvaluateMode);
    const isThinking = useSelector((state: RootState) => state.EvaInfo.orbitLoader);

    return (
        <>
            {isEvaluateMode ? (
                <div className='evaluateMode_img'>
                    <div className={`${isThinking ? 'evaluateMode_orbit thinking' : 'evaluateMode_orbit'} `} />
                </div>
            ) : (
                <div className={`${isThinking ? 'thinking' : ''} neon-circle idle  `} />
            )}
        </>
    );
};
