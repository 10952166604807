import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { appInsights } from '../AppInsights';
import { useServices } from '../components/ServiceProvider';
import { useStore } from '../store';
import { GetStartedValues } from '../store/SignUpStore';
import { countryList } from '../utils/constants';
import { mockable } from '../utils/mockable';
import { useForm } from '../utils/useForm';
import { useQuery } from '../utils/useQuery';
import { and, isEmail, isValidPassword, required } from '../utils/validation';

export const useToGetStartedForm = mockable((initialValues?: GetStartedValues) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const history = useHistory();
    const store = useStore();
    const { users, auth } = useServices();
    const queryData = useQuery();
    const inviteEmail = queryData.get('email');

    const { fields, isValid, setAllVisited, setValues, values } = useForm({
        fields: {
            firstName: { initialValue: initialValues?.firstName || '', validation: (value: string) => required(value, t) },
            lastName: { initialValue: initialValues?.lastName || '', validation: (value: string) => required(value, t) },
            email: {
                initialValue: inviteEmail ? inviteEmail : '',
                validation: and((value: string) => required(value, t), (value:string) => isEmail(value, t)),
            },
            country: { initialValue: initialValues?.country || countryList[0] },
            publicAccess: { initialValue: initialValues?.publicAccess || '' },
            password: {
                initialValue: initialValues?.password || '',
                validation: and(
                    (value: string) => required(value, t),
                    (value) => isValidPassword(value, t),
                ),
            },
        },
    });

    const onSubmit = async () => {
        setIsLoading(true);
        setAllVisited();
        try {
            if (!isValid()) throw new Error('Some fields are not valid, please check again');
            const isEmailTaken = await users.exists(fields.email.value);
            if (isEmailTaken) {
                setValues({
                    ...values,
                    email: '',
                });
                throw new Error(`${fields.email.value} is already associated with an account`);
            }
            await auth.signup({ ...store.signUpStore.getStartedValues });
            await auth.sendConfirmationEmail(store.signUpStore.getStartedValues?.email as string);
            goTo(`/confirm-email/`);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
        setIsLoading(false);
    };

    const goTo = (path: string) => {
        history.push(path);
    };

    const isEmailValid = () => {
        try {
            if (!isValid()) {
                throw new Error(t('validation.invalidEmail'));
            } else {
                setError(null);
                return true;
            }
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
                appInsights.trackException({ exception: err });
                Sentry.captureException(err);
                return false;
            }
            return true;
        }
    };

    return { onSubmit, setError, fields, isLoading, error, isEmailValid };
});
