import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';

import { useSettings } from '../../hooks/useSettings';
import { getCategoryIcon } from '../../utils/categoryUnicode';
import { Button } from '../basic/Button.component';

const Welcome = () => {
    const { t } = useTranslation();
    const { setIsOpen, setCurrentStep } = useTour();
    const { updateOnboardingTour } = useSettings();

    return (
        <div className='flex flex-col text-darker text-opacity-75 items-center justify-center'>
            <div className='flex space-x-2 justify-center'>
                <p className='font-medium text-xl'>
                    {t('home.welcome')}
                    {getCategoryIcon('Raised Hand')}
                </p>
            </div>
            <p className='text-xs mt-2 font-medium'>{t('home.description')}</p>
            <div className='flex justify-center mt-4'>
                <Button
                    variant='bberryBlue'
                    size='oval'
                    onClick={async () => {
                        await updateOnboardingTour(false, 'showWelcome');
                        await updateOnboardingTour(true, 'startTour');
                        setCurrentStep(0);
                        await updateOnboardingTour(true, 'extension');
                        setIsOpen(true);
                    }}
                >
                    {t('home.explore')}
                </Button>
            </div>
            <div className='flex space-x-2 mt-8'>
                <p className='text-xs'>{t('home.experienced')}</p>
                <button
                    className='text-xs text-bberry-blue'
                    onClick={() => {
                        updateOnboardingTour(false, 'showWelcome');
                        updateOnboardingTour(false, 'startTour');
                    }}
                >
                    {t('home.skip')}
                </button>
            </div>
        </div>
    );
};

export default Welcome;
