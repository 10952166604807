import './ThreeDotAnimation.css';

const ThreeDotAnimation = () => {
    return (
        <div className='three-dot-animation'>
            <div className='dot' />
            <div className='dot' />
            <div className='dot' />
        </div>
    );
};

export default ThreeDotAnimation;
