import { useState } from 'react';

import './style.css';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useOrganisationForm } from '../../../../hooks/useOrganisation';
import { CustomLoader } from '../../../basic/CustomeLoader/CustomLoader';
import SubHeader from '../eva/components/SubHeader';
import { EvaGraphAnalyticsEvaList } from '../eva/types';
import AnalyticsLogs from './AnalyticsLogs/AnalyticsLogs.component';
import ActiveEvaSelect from './risk/ActiveEvaSelect.component';
import Risk from './risk/Risk.component';
import Trends from './Trends/Trends.component';

const Analytics = () => {
    const [evaId, setEvaId] = useState('');
    const [titleList, setTitleList] = useState<EvaGraphAnalyticsEvaList[]>();
    const { getEvaListByOrganisationId } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();

    const { isFetching, isLoading } = useQuery(['evaData', organisationId], async () => {
        try {
            const eva = await getEvaListByOrganisationId(organisationId);
            const publishedEva = eva.find((obj: EvaGraphAnalyticsEvaList) => obj.isPublished);
            const evaIdToSet = publishedEva ? publishedEva.id : eva[0]?.id;
            setEvaId(evaIdToSet);

            setTitleList(eva);
        } catch (e) {
            console.error(e);
        }
    });

    const handleEvaChange = (id: string) => {
        setEvaId(id);
    };

    if (isFetching || isLoading) {
        return <CustomLoader />;
    }

    return (
        <div className='analytics-page-container'>
            <SubHeader
                labelName=''
                component={
                    <ActiveEvaSelect
                        evaId={evaId}
                        handleEvaChange={handleEvaChange}
                        titleList={titleList}
                        isShield={false}
                    />
                }
            />

            <div className='w-full flex space-x-6 analytics_container justify-between'>
                <div className='trends h-full'>
                    <Trends titleList={titleList} evaId={evaId} />
                </div>
                <div className='risk'>
                    <div className='risk_box'>
                        <Risk evaId={evaId} />
                    </div>
                    <AnalyticsLogs evaId={evaId} />
                </div>
            </div>
        </div>
    );
};

export default observer(Analytics);
