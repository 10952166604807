import { ChangeEvent, useState } from 'react';

import { Typography, makeStyles } from '@material-ui/core';
import { TextField } from '@mui/material';

const useStyles = makeStyles(() => ({
    evaField: {
        borderRadius: 7,
        backgroundColor: 'transparent',
        width: '100%',
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiInput-underline input': {
            textAlign: 'center',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
        },
    },
    placeHolder: {
        color: '#fff',
        fontSize: 12,
        position : "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    star: { color: '#d20812' },
}));

const textFieldStyle = {
    zIndex: 9999,
    color: 'white',
    fontFamily: ['Poppins'],
    '& .MuiInput-root': {
        textarea: {
            textAlign: 'center',
        },
    },
    '& .MuiInputLabel-root': { fontFamily: 'Poppins, sans-serif' },
    '& .MuiOutlinedInput-root': {
        fontSize: '12px',
        '&.Mui-focused, &.Mui-filled': {
            borderBottom: 'none',
        },
        '& > fieldset': {
            fontFamily: 'Poppins',
        },
    },
};

interface StyledTextFieldProps {
    id?: string;
    placeHolder: string;
    value: string;
    setValue: (value: string) => void;
    font: 'small' | 'medium' | 'large';
    fontWeight: 'normal' | 'medium' | 'semibold' | 'bold' | 'black';
    className?: string;
    multiline?: boolean;
    maxLength?: number;
    onBlur?: () => void;
    style?: React.CSSProperties;
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    showRequiredStar?: boolean;
    textColor?: string;
    autoFocus?: boolean;
    setCount?: (count: number) => void;
    textFieldPlaceholder?: string;
}

export const StyledTextField = ({
    id,
    placeHolder,
    value,
    setValue,
    font,
    fontWeight: propFontWeight,
    className,
    multiline,
    maxLength,
    onBlur,
    style,
    onKeyDown,
    showRequiredStar = true,
    textColor,
    autoFocus = false,
    setCount,
    textFieldPlaceholder,
}: StyledTextFieldProps) => {
    const classes = useStyles();
    const [isFocused, setIsFocused] = useState(false);
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (setCount) setCount(e.target.value.length);
        const inputValue = e.target.value;
        if (maxLength && inputValue.length > maxLength) {
            setValue(inputValue.slice(0, maxLength));
        } else {
            setValue(inputValue);
        }
    };
    const handleFocus = () => {
        setIsFocused(!isFocused);
    };

    let fontSize = 12;

    switch (font) {
        case 'small':
            fontSize = 12;
            break;
        case 'medium':
            fontSize = 16;
            break;
        case 'large':
            fontSize = 20;
            break;
        default:
            fontSize = 12;
            break;
    }

    let textFontWeight = 400;

    switch (propFontWeight) {
        case 'normal':
            textFontWeight = 400;
            break;
        case 'medium':
            textFontWeight = 500;
            break;
        case 'semibold':
            textFontWeight = 600;
            break;
        case 'bold':
            textFontWeight = 800;
            break;
        case 'black':
            textFontWeight = 900;
            break;
        default:
            textFontWeight = 400;
            break;
    }

    return (
        <div style={{ position: 'relative' }} className='description_input_box'>
            <TextField
                id={id}
                className={ `${className} input_field_box` }
                multiline={multiline}
                variant='standard'
                size='small'
                sx={textFieldStyle}
                autoComplete='off'
                InputProps={{
                    sx: {
                        color: textColor ? textColor : 'white',
                        fontSize,
                        fontWeight: textFontWeight,
                        fontFamily: 'Poppins',
                        '.css-nz481w-MuiInputBase-input-MuiInput-input': {
                            textAlign: 'center',
                        },
                    },
                    style: { textAlign: 'center' },
                }}
                placeholder={textFieldPlaceholder}
                value={value}
                onChange={handleOnChange}
                onBlur={onBlur}
                onFocus={handleFocus}
                onKeyDown={onKeyDown}
                inputProps={{ maxLength: maxLength }}
                autoFocus={autoFocus}
            />
            {!isFocused && value === '' && (
                <>
                    <Typography variant='inherit' component='div' className={classes.placeHolder} style={style}>
                        {placeHolder}{' '}
                        {showRequiredStar && (
                            <Typography variant='inherit' component='span' className={classes.star}>
                                *
                            </Typography>
                        )}
                    </Typography>
                </>
            )}
        </div>
    );
};
