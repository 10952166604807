import CloseIcon from '@mui/icons-material/Close';
import { TextField, Autocomplete, Chip, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    backgroundColor: '#60F4C81A',
                    borderRadius: '10px',
                    border: '1px solid rgba(96, 244, 200, 0.5)',
                    '& .MuiAutocomplete-inputRoot': {
                        padding: '0px 2px', // Adjust the padding to remove unnecessary space
                    },
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: '12px',
                        fontFamily: 'Poppins',
                        height: '16px',
                    },
                    '.MuiSvgIcon-root ': {
                        fill: '#60F4C8BF !important',
                    },
                    '.MuiChip-root': {
                        backgroundColor: '#60F4C81A',
                        color: '#60F4C8BF',
                        fontSize: 12,
                        fontFamily: 'Poppins',
                        borderRadius: '10px',
                        padding: '2px',
                        height: '24px',
                        '& .MuiChip-deleteIcon': {
                            color: '#60F4C8BF',
                            backgroundColor: 'transparent',
                            width: 16,
                            height: 16,
                        },
                        '&:hover': {
                            backgroundColor: '#60F4C833', // Change the background color on hover
                            color: '#60F4C8', // Change the text color on hover
                            '& .MuiChip-deleteIcon': {
                                color: '#60F4C8', // Change the delete icon color on hover
                            },
                        },
                    },
                },
                noOptions: {
                    color: '#60F4C8BF',
                    fontSize: 12,
                    fontFamily: 'Poppins',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        padding: '10px 14px', // Adjust padding to fit height
                        color: '#60F4C8BF', // Text color
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: '#60F4C8BF', // Placeholder text color
                    },
                },
            },
        },
    },
});

const Keywords = ({
    keywords,
    setKeywords,
    error,
    setError,
}: {
    keywords: string[];
    setKeywords: (keywords: string[]) => void;
    error?: boolean;
    setError?: (key: string, value: boolean) => void;
}) => {
    const { t } = useTranslation();
    return (
        <div className='w-full flex space-between items-center'>
            <p className='w-14 text-sm font-medium'>{t('keywords')}</p>
            <div className='w-full evaluate-keywords'>
                <ThemeProvider theme={theme}>
                    <Autocomplete
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderStyle: error ? '' : 'none',
                                borderRadius: '10px',
                            },
                        }}
                        fullWidth
                        multiple
                        id='tags-filled'
                        options={keywords}
                        value={keywords}
                        open={false}
                        freeSolo
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return <Chip label={option} key={key} {...tagProps} deleteIcon={<CloseIcon />} />;
                            })
                        }
                        onChange={(event, value) => {
                            setKeywords(value);
                            if(setError) setError('keywordError', value.length === 0);
                        }}
                        renderInput={(params) => <TextField {...params} placeholder={t('keywords')} error={error} />}
                    />
                </ThemeProvider>
                {error && (
                    <Typography
                        color='error'
                        variant='body2'
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: 12,
                        }}
                    >
                        {t('please_enter_keywords')}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default Keywords;
