import { useTranslation } from 'react-i18next';

export const ErrorComponent = () => {
    const { t } = useTranslation();
    return (
        <div className='flex no_protocol gap-5 flex-col w-full h-full justify-center items-center'>
            <div className='img_box' />
            <div>
                <>
                    <h6>{t('protocolDetail.whoops')}</h6>
                    <p>{t('protocolDetail.loadProtocol')}</p>
                </>
            </div>
        </div>
    );
};
