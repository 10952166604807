import { useState } from 'react';

import ClickAwayListener from '@mui/base/ClickAwayListener';
import { PaymentMethod } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as CardIcon } from '../../../assets/icons/icon-card.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-delete-fill.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/icon-edit-fill.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/icon-more2.svg';
import { ReactComponent as VisaIcon } from '../../../assets/icons/icon-visa2.svg';
import { usePaymentMethods } from '../../../hooks/usePaymentMethods';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import RadioButton from '../../basic/RadioButton.component';

const menuItemStyle = 'py-2 px-6 space-x-4 w-full flex items-center h-8 hover:bg-primary-darker hover:bg-opacity-3';

const PaymentMethodCard = ({
    card,
    isDefault,
    isExpired,
    edit = true,
    radioButton,
    checked,
    setChecked,
    navigation,
}: {
    card: PaymentMethod;
    isDefault?: boolean;
    isExpired?: boolean;
    edit?: boolean;
    radioButton?: boolean;
    checked?: boolean;
    setChecked?: () => void;
    navigation?: string;
}) => {
    const {
        deletePaymentMethod,
        setPaymentMethodAsDefault,
        setOrganisationDefaultPayment,
        deleteOrganisationPaymentMethod,
    } = usePaymentMethods();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const { id: organisationId } = useParams<{ id: string }>();
    const { t } = useTranslation();

    return (
        <div
            className='rounded-2xl border border-blue-ryb bg-white shadow-payment-method
            flex flex-row justify-between items-center px-4 py-2'
        >
            <div className='flex items-center'>
                {radioButton && (
                    <RadioButton
                        checked={isExpired ? false : checked}
                        onClick={() => !isExpired && setChecked && setChecked()}
                        className='mr-6'
                    />
                )}
                {card.card?.brand === 'visa' && <VisaIcon width={60} fill='#0014FF' />}
                <div className='space-y-2 text-darker text-opacity-75 ml-16'>
                    <div className='flex space-x-2 items-center'>
                        <p className='font-medium text-sm'>
                            <span className='capitalize text-darker text-opacity-75'>{card.card?.brand} </span>
                            {t('billing.cardEnding')} {card.card?.last4}
                        </p>
                        {isDefault && (
                            <div className='py-0 px-1 bg-blue-RYB text-white items-center flex h-3 rounded'>
                                <p className='capitalize text-[8px] font-semibold'>{t('billing.default')}</p>
                            </div>
                        )}
                    </div>
                    {isExpired && isDefault ? (
                        <p className='text-xs text-card-error'>
                            Your default card has expired. Please update your payment information
                        </p>
                    ) : isExpired ? (
                        <p className='text-xs text-card-error'>
                            Your card has expired. Please update your payment information
                        </p>
                    ) : (
                        <p className='text-xs text-darker text-opacity-50 font-medium'>
                            {t('billing.expire')}{' '}
                            {`${card.card?.exp_month.toString().padStart(2, '0')}/${card.card?.exp_year
                                .toString()
                                .slice(-2)}`}
                        </p>
                    )}
                </div>
            </div>

            <div className='relative'>
                <button onClick={() => setShowMenu(!showMenu)} className='flex self-center'>
                    <MoreIcon className='w-4 h-4 cursor-pointer' fill='#0F104480' />
                </button>

                {showMenu && (
                    <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                        <div className='absolute bg-white w-60 py-4 shadow-message-container rounded-xl left-3 top-1 text-sm z-10'>
                            <Link
                                className={menuItemStyle}
                                to={navigation ? `${navigation}/${card.id}` : `edit-payment/${card.id}`}
                            >
                                <EditIcon /> <p>{t('btnNames.edit')}</p>
                            </Link>
                            <button
                                className={`${menuItemStyle} ${isDefault ? 'cursor-default opacity-50' : ''}`}
                                disabled={isDefault}
                                onClick={() => {
                                    if (organisationId) setOrganisationDefaultPayment(card.id, organisationId);
                                    else setPaymentMethodAsDefault(card.id);
                                    window.location.reload();
                                }}
                            >
                                <CardIcon className='w-3 h-3' /> <p>{t('btnNames.setDefault')}</p>
                            </button>
                            <HorizontalSeparator className='my-2' />
                            <button
                                className={menuItemStyle}
                                onClick={() => {
                                    if (organisationId) deleteOrganisationPaymentMethod(card.id, organisationId);
                                    else deletePaymentMethod(card.id);
                                    window.location.reload();
                                }}
                            >
                                <DeleteIcon className='w-3 h-3' /> <p>{t('btnNames.delete')}</p>
                            </button>
                        </div>
                    </ClickAwayListener>
                )}
            </div>
        </div>
    );
};

export default PaymentMethodCard;
