import { useState } from 'react';

import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { appInsights } from '../../../AppInsights';
import { ReactComponent as BackIcon } from '../../../assets/icons/icon-arrow-left-long.svg';
import { store } from '../../../store';
import { ORGANISATION_TIERS, SUBSCRIPTION_STATUS } from '../../../store/OrganisationStore';
import { SUBSCRIPTION_TYPE } from '../../../utils/constants';
import { useServices } from '../../ServiceProvider';
import NorthStarAddon from './NorthStarAddon.component';
import TierCard from './TierCard';
import TierTable from './TierTable';

const OrgainisationTier = ({ subscriptionExist }: { subscriptionExist?: boolean }) => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { organisation } = useServices();
    const history = useHistory();
    //call this only if subscriptionExist is true
    const { data: subscription } = useQuery(
        ['getSubscription', id],
        async () => {
            try {
                return await organisation.getSubscription(id);
            } catch (e) {
                console.error(e);
                if (e instanceof Error) {
                    Sentry.captureException(e);
                    appInsights.trackException({ error: e });
                }
            }
        },
        { enabled: subscriptionExist },
    );
    const [selectedTierType, setSelectedTierType] = useState(subscription?.type || SUBSCRIPTION_TYPE.YEARLY);

    const handleSelectTier = (tier: string) => {
        store.organisation.setSelectedTier(tier);
        if (subscriptionExist) {
            history.push(`/organisation/${id}/${selectedTierType}/confirm-plan`);
        } else history.push(`/create-organisation/${id}/${selectedTierType}/confirm-plan`);
    };

    const handleSubscriptionButtonName = (name: string, type: string) => {
        if (subscriptionExist) {
            if (
                subscription?.productName === name &&
                subscription.type === selectedTierType &&
                subscription.status !== SUBSCRIPTION_STATUS.CANCELED
            ) {
                return t(`SUBSCRIPTION_BUTTON_LABEL.CURRENT_TIER`);
            } else if (
                subscription?.productName === name &&
                subscription.type === selectedTierType &&
                subscription.status === SUBSCRIPTION_STATUS.CANCELED
            ) {
                return t(`SUBSCRIPTION_BUTTON_LABEL.SUBSCRIBE`);
            } else if (
                subscription?.productName === ORGANISATION_TIERS.OVERSIGHT &&
                name === ORGANISATION_TIERS.SHIELD
            ) {
                return t('SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE');
            } else if (
                subscription?.type === SUBSCRIPTION_TYPE.YEARLY &&
                subscription?.productName === ORGANISATION_TIERS.SHIELD &&
                name === ORGANISATION_TIERS.SHIELD
            ) {
                return t('SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE');
            } else if (
                subscription?.type === SUBSCRIPTION_TYPE.YEARLY &&
                subscription?.productName === ORGANISATION_TIERS.OVERSIGHT &&
                name === ORGANISATION_TIERS.OVERSIGHT
            ) {
                return t('SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE');
            } else {
                return t('SUBSCRIPTION_BUTTON_LABEL.UPGRADE');
            }
        } else {
            return undefined;
        }
    };

    const handleDisable = (name: string) => {
        if (subscriptionExist) {
            if (
                subscription?.productName === name &&
                subscription.type === selectedTierType &&
                subscription.status !== SUBSCRIPTION_STATUS.CANCELED
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <div className='w-full h-full flex justify-center'>
            <button
                onClick={() => history.goBack()}
                className='absolute top-10 mt-10 right-6 flex items-center space-x-2 ml-auto h-8'
            >
                <BackIcon />
                <p className='text-sm text-darker text-opacity-75'>{t('btnNames.back')}</p>
            </button>
            <div className='mx-auto max-w-5xl w-full mt-10 md:mt-0'>
                <div className='mt-5 mb-10 flex flex-col justify-center items-center'>
                    <div className='text-4xl text-royal-blue text-center font-bold'>
                        <p>{t('subscription_tier.title')}</p>
                    </div>
                    <div className='text-sm text-darker text-opacity-75 font-normal'>
                        <p>{t('subscription_tier.description')}</p>
                    </div>
                </div>
                <div className='flex justify-end text-darker text-opacity-75 text-sm'>
                    <div className='bg-white rounded-md px-1 flex space-x-2 items-center'>
                        <button
                            className={clsx(
                                'flex space-x-2 items-center font-medium py-2 px-6 rounded-md',
                                selectedTierType === SUBSCRIPTION_TYPE.YEARLY && 'text-white bg-primary-darker',
                            )}
                            onClick={() => setSelectedTierType(SUBSCRIPTION_TYPE.YEARLY)}
                        >
                            <p>{t('subscription_tier.annual')}</p>
                            <div className='text-xs bg-warning-100 p-1 rounded-md'>
                                <p className='text-orange'>20% off</p>
                            </div>
                        </button>
                        <button
                            className={clsx(
                                'rounded-md py-2 px-6 font-medium items-center',
                                selectedTierType === SUBSCRIPTION_TYPE.MONTHLY && 'text-white bg-primary-darker',
                            )}
                            onClick={() => setSelectedTierType(SUBSCRIPTION_TYPE.MONTHLY)}
                        >
                            <p>{t('subscription_tier.monthly')}</p>
                        </button>
                    </div>
                </div>
                <div className='flex justify-end space-x-4 mt-4'>
                    <TierCard
                        title={ORGANISATION_TIERS.SHIELD}
                        description={t('subscription_tier.shield_description')}
                        price={selectedTierType === SUBSCRIPTION_TYPE.MONTHLY ? 195 : 156}
                        onClick={() => handleSelectTier(ORGANISATION_TIERS.SHIELD)}
                        disabled={handleDisable(ORGANISATION_TIERS.SHIELD)}
                        buttonName={handleSubscriptionButtonName(ORGANISATION_TIERS.SHIELD, selectedTierType)}
                        showAnnualBilling={selectedTierType === SUBSCRIPTION_TYPE.YEARLY}
                    />
                    <TierCard
                        title={ORGANISATION_TIERS.OVERSIGHT}
                        description={t('subscription_tier.oversight_description')}
                        bgColor='linear-gradient(0deg, rgba(0, 20, 255, 0.03), rgba(0, 20, 255, 0.03)), linear-gradient(298.12deg, #0014FF 3.68%, #73DEE1 106.98%)'
                        borderColor='bg-white bg-opacity-10'
                        price={selectedTierType === SUBSCRIPTION_TYPE.MONTHLY ? 395 : 316}
                        buttonClass='oversight-button'
                        onClick={() => handleSelectTier(ORGANISATION_TIERS.OVERSIGHT)}
                        buttonName={handleSubscriptionButtonName(ORGANISATION_TIERS.OVERSIGHT, selectedTierType)}
                        disabled={handleDisable(ORGANISATION_TIERS.OVERSIGHT)}
                        showAnnualBilling={selectedTierType === SUBSCRIPTION_TYPE.YEARLY}
                        isOverSight
                    />
                </div>
                <div className='w-full mt-5'>
                    {/* <NorthStarAddon type={selectedTierType}/> */}
                    <TierTable />
                </div>
            </div>
        </div>
    );
};

export default OrgainisationTier;
