import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as PopoutIcon } from '../../assets/blueberry/icon-popout.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/icon-cross-fill.svg';
import { useSession } from '../../hooks/useSession';
import { Session } from '../../store/SessionStore';
import HorizontalSeparator from '../basic/HorizontalSeparator';
import { protocolTypeIcon } from '../organisation/copilotOrganisation/eva/components/ProtocolTypeList';
import DomainIcon from './DomainIcon.component';

const SessionDomainCard = ({
    data,
    showDomainIcon = false,
    handleDeleteSession,
    isLastIndex,
}: {
    data: Session[];
    showDomainIcon?: boolean;
    handleDeleteSession?: (domain: string, id: number) => void;
    isLastIndex: boolean;
}) => {
    const { deleteSession } = useSession();
    return (
        <div className='w-full space-y-2'>
            <div className='space-y-4 text-darker text-opacity-75 text-sm'>
                <div className='bg-white'>
                    {data.map(
                        (item, index) =>
                            item.hint && (
                                <>
                                    <Tooltip
                                        id={item.organisationName}
                                        offset={5}
                                        place='top'
                                        className='tooltip-box show_arrow'
                                    >
                                        <p> {item.organisationName} </p>
                                    </Tooltip>
                                    <div
                                        key={index}
                                        className={`hover:bg-primary-darker hover:bg-opacity-3 ${
                                            isLastIndex ? 'lastChildBorder' : 'childBorder'
                                        }`}
                                    >
                                        <div className='group space-y-2 p-4'>
                                            <div className='relative flex space-x-4'>
                                                <div className='w-full text-sm text-darker text-opacity-50'>
                                                    <div className='flex text-darker text-opacity-75 font-medium justify-between'>
                                                        <div className='underline-custom overflow-hidden mr-6'>
                                                            <div className='hover-auto-scroll whitespace-nowrap'>{item.tabTitle}</div>
                                                        </div>
                                                        <div className='text-xs flex justify-end space-x-2 text-darker text-opacity-50 mr-5'>
                                                            <p className='whitespace-nowrap font-normal'>
                                                                {dayjs(item.date).format('DD/MM/YY')}
                                                            </p>
                                                            <p className='whitespace-nowrap font-normal'>
                                                                {dayjs(item.date).format('h:mm A')}
                                                            </p>
                                                            {/* <div
                                                            className='mt-0.5 cursor-pointer'
                                                            onClick={() => window.open(item.sessionUrl, '_blank')}
                                                            >
                                                            <PopoutIcon className='w-2.5 h-2.5' />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    {item.hint && (
                                                        <div className='flex items-center space-x-2 mt-2'>
                                                            <div
                                                                className='flex space_logo_box items-center justify-center bg-primary-darker bg-opacity-5'
                                                                style={{
                                                                    backgroundColor:
                                                                        item.spaceColor != ''
                                                                            ? item.spaceColor
                                                                            : '#F4F3F4',
                                                                    border: '.75px solid #0F104433',
                                                                    borderRadius: '7px',
                                                                }}
                                                                data-tooltip-id={item.organisationName}
                                                            >
                                                                {item.spaceLogo && item.spaceLogo != '' ? (
                                                                    <img
                                                                        src={item.spaceLogo}
                                                                        alt='logo'
                                                                        className='w-full h-full object-cover rounded-sm'
                                                                    />
                                                                ) : (
                                                                    <p className='text-xxs text-center self-center text-darker text-opacity-75 font-normal'>
                                                                        logo
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className='nsa-wrapper'>
                                                                <div className='flex nsa-container space-x-2'>
                                                                    {item.protocolType && (
                                                                        <img
                                                                            src={protocolTypeIcon(item.protocolType)}
                                                                            // className='w-6 h-6'
                                                                            alt='hint'
                                                                            style={{ height: '16px', width: '16px' }}
                                                                        />
                                                                    )}
                                                                    <div className='nsa-text-container text-xs text-darker text-opacity-75 cursor-default'>
                                                                        <span
                                                                            className='nsa-text'
                                                                            style={{ color: '#2C91C0' }}
                                                                        >
                                                                            {item.hint}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {handleDeleteSession && (
                                                    <div
                                                        className='right-0 absolute group-hover:opacity-100 opacity-0 cursor-pointer mt-0.5'
                                                        onClick={() => {
                                                            deleteSession(item.id);
                                                            handleDeleteSession(item.domain, item.id);
                                                        }}
                                                    >
                                                        <CrossIcon
                                                            fill='rgba(15, 16, 68, 0.5)'
                                                            className='w-2.5 h-2.5'
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {/* <div className='flex justify-end'>
                                            {showDomainIcon && (
                                                <DomainIcon domain={item.domain} size={4} className='w-6 h-6' />
                                                )}
                                                </div> */}
                                        </div>
                                        {/* {index < data.length - 1 && <HorizontalSeparator />} */}
                                    </div>
                                </>
                            ),
                    )}
                </div>
            </div>
        </div>
    );
};

export default SessionDomainCard;
