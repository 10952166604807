import { ChangeEvent, useEffect, useRef, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import banner from '../../../../assets/blueberry/space-header.svg';
import { useSpaces } from '../../../../hooks/useSpaces';
import { SpaceInfo } from '../../../../store/SpacesStore';
import { MAX_HEADER_PICTURE_SIZE } from '../../../../utils/constants';
import { getDocumentUrl } from '../../../../utils/getDocumentUrl';
import { isFileValidation } from '../../../../utils/validation';
import PublicAccess from '../../../basic/PublicAccess.component';
import SpaceLogo from '../../../basic/SpaceLogo.component';

const SpaceHeader = ({
    space,
    edit = false,
    onChange,
    spaceId,
    name,
    spaceUrl,
    className,
}: {
    space?: SpaceInfo | undefined;
    edit?: boolean;
    onChange?: (value: string) => void;
    spaceId?: string;
    name?: string;
    spaceUrl?: string;
    className?: string;
}) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [preview, setPreview] = useState<string>(banner);
    const [logoPreview, setLogoPreview] = useState<string>('');
    const [spaceName, setSpaceName] = useState<string>('');
    const { uploadSpaceBanner } = useSpaces();

    const handleOpenFilePrompt = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current.click();
        }
    };

    const uploadHeader = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const validation = isFileValidation(file, MAX_HEADER_PICTURE_SIZE, t);
            if (validation) {
                alert(validation);
                return;
            }
            setPreview(URL.createObjectURL(file));
            uploadSpaceBanner(file, spaceId);
        }
    };

    useEffect(() => {
        if (space) {
            if (space.spaceBanner !== '') setPreview(getDocumentUrl(+space.spaceBanner));
            else setPreview(banner);
            if (space.spaceLogo !== '') setLogoPreview(getDocumentUrl(+space.spaceLogo));
            else setLogoPreview('');
            setSpaceName(space.spaceName);
        }
    }, [space]);

    return (
        <div
            className={clsx(
                'relative flex justify-end max-w-4xl z-10',
                className ? className : 'left-1/2 -translate-x-1/2',
            )}
        >
            <div className='absolute top-0 h-28 md:h-[169px]'>
                <img
                    src={preview}
                    style={{ height: '100%', width: '100vw', objectFit: 'cover', display: 'block' }}
                    alt='background'
                />
                {!edit && (
                    <div className='absolute top-5 px-4'>
                        {logoPreview === '' ? (
                            <SpaceLogo
                                className='w-8 h-8'
                                // @ts-ignore
                                textColor={space?.spaceColor ? space?.spaceColor : 'blue'}
                                title={space?.spaceName[0]}
                                size='lg'
                            />
                        ) : (
                            <div
                                className='bg-center bg-no-repeat bg-cover w-8 h-8 rounded-lg border border-white'
                                style={{
                                    backgroundImage: `url(${logoPreview})`,
                                }}
                            />
                        )}
                    </div>
                )}
                {edit ? (
                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div
                            className={clsx(
                                'input-placeholder space-y-2 p-3 bg-white bg-opacity-50 rounded-lg text-sm',
                                spaceName.length > 50 && 'ring-error-900 ring-2',
                            )}
                        >
                            <input
                                id='spaceName'
                                name='spaceName'
                                className={clsx(
                                    `focus:outline-none flex-grow w-full text-center`,
                                    'bg-transparent',
                                    'text-darker text-opacity-75 font-semibold',
                                )}
                                required
                                value={spaceName}
                                onChange={(e) => {
                                    setSpaceName(e.target.value);
                                    if (onChange) onChange(e.target.value);
                                }}
                                maxLength={100}
                            />
                            <div className='space-placeholder'>
                               {t('spaces.spaceName')}<span>*</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='space-y-2 px-4 py-2 bg-primary-darker bg-opacity-50 rounded-lg text-white text-sm'>
                            <p className='text-center capitalize'>{space?.spaceName}</p>
                        </div>
                    </div>
                )}
                <div
                    className='absolute top-0 md:bottom-0 md:top-auto w-full h-8 bg-primary-darker bg-opacity-50
                    flex items-center justify-between px-4'
                >
                    {spaceUrl && <PublicAccess className='text-white' url={spaceUrl} />}
                </div>
            </div>
            {edit && (
                <div className='absolute flex space-x-4 mr-5 mt-5 items-center'>
                    <div className='bg-primary-darker bg-opacity-50 rounded-md text-white px-2 py-1'>
                        <p className='text-sm font-normal'>{t('spaces.2MB')}</p>
                    </div>
                    <div
                        onClick={handleOpenFilePrompt}
                        className='hidden rounded-full w-5 h-5 items-center justify-center
                            text-xs text-white bg-white bg-opacity-75 hover:bg-opacity-60 scale-150 cursor-pointer
                            transition-all duration-500 md:flex'
                    >
                        <EditIcon fontSize='inherit' sx={{ color: 'rgba(15, 16, 68, 0.75)' }} />
                    </div>
                </div>
            )}

            <input ref={inputRef} accept='image/*' onChange={uploadHeader} className='hidden' type='file' />
        </div>
    );
};

export default observer(SpaceHeader);
