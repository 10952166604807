// src/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import EvaInfo from './EvaInfo';
import protocolReducer from './SessionsProtocols';
import TrendMetric from './TrendMetric';

export const store = configureStore({
    reducer: {
        SessionsProtocols: protocolReducer, // Add protocol reducer to store
        TrendMetric: TrendMetric, //Add trend metric reducer to store
        EvaInfo: EvaInfo, //Add trend metric reducer to store
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disable serializable check
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Custom hooks to use throughout the app for dispatch and state selector
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
