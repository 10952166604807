import { useState } from 'react';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../Redux/store';
import { categoryList } from '../../../eva/components/metrics/metricsType';

interface HeatmapData {
    x: string;
    y: number;
}

export type ScatterData = {
    name: string;
    x: string;
    y: number;
};

interface Series {
    name: string;
    data: HeatmapData[];
}

//default heat map chart parameters
const MetricHeatMapChart = () => {
    const chartData = useSelector((state: RootState) => state.TrendMetric.chartData);
    const category = categoryList?.find((obj) => obj.key === chartData[0]?.category)?.key;

    const kriColor = [
        { from: 0, to: 20, name: 'low', color: '#7FDBFF' },
        { from: 21, to: 40, name: 'medium', color: '#4DCEFF' },
        { from: 41, to: 60, name: 'high', color: '#00B8FF' },
        { from: 61, to: 80, name: 'extreme', color: '#A6E6FF' },
        { from: 81, to: 100, name: 'critical', color: '#D9F5FF' },
    ];

    const iopColor = [
        { from: 0, to: 20, name: 'low', color: '#A2EB8A' },
        { from: 21, to: 40, name: 'medium', color: '#89E171' },
        { from: 41, to: 60, name: 'high', color: '#61DC36' },
        { from: 61, to: 80, name: 'extreme', color: '#C5F2B4' },
        { from: 81, to: 100, name: 'critical', color: '#E1F7D8' },
    ];

    const lIColor = [
        { from: 0, to: 20, name: 'low', color: '#FFB39B' },
        { from: 21, to: 40, name: 'medium', color: '#FA9F82' },
        { from: 41, to: 60, name: 'high', color: '#FF7043' },
        { from: 61, to: 80, name: 'extreme', color: '#FFD1C2' },
        { from: 81, to: 100, name: 'critical', color: '#FFE9E2' },
    ];

    const sampleData: Series[] = [
        {
            name: 'Insignificant',
            data: [
                { x: 'Very Low', y: 100 },
                { x: 'Low', y: 100 },
                { x: 'Moderate', y: 100 },
                { x: 'High', y: 20 },
                { x: 'Very High', y: 20 },
            ],
        },
        {
            name: 'Minor',
            data: [
                { x: 'Very Low', y: 100 },
                { x: 'Low', y: 100 },
                { x: 'Moderate', y: 80 },
                { x: 'High', y: 20 },
                { x: 'Very High', y: 40 },
            ],
        },
        {
            name: 'Moderate',
            data: [
                { x: 'Very Low', y: 100 },
                { x: 'Low', y: 80 },
                { x: 'Moderate', y: 20 },
                { x: 'High', y: 40 },
                { x: 'Very High', y: 60 },
            ],
        },
        {
            name: 'Major',
            data: [
                { x: 'Very Low', y: 80 },
                { x: 'Low', y: 20 },
                { x: 'Moderate', y: 40 },
                { x: 'High', y: 60 },
                { x: 'Very High', y: 60 },
            ],
        },
        {
            name: 'Critical',
            data: [
                { x: 'Very Low', y: 20 },
                { x: 'Low', y: 26 },
                { x: 'Moderate', y: 60 },
                { x: 'High', y: 60 },
                { x: 'Very High', y: 60 },
            ],
        },
    ];

    const [hoveredPoint, setHoveredPoint] = useState<{
        x: string;
        y: number;
        name: string;
        top: number;
        left: number;
    } | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const handleMouseEnter = (dataPoint: ScatterData, event: any) => {
    //     const { clientX: x, clientY: y } = event;
    //     setHoveredPoint({ x: dataPoint.x, y: dataPoint.y, name: dataPoint.name, top: y - 5, left: x });
    // };

    const getAnnotations = () => {
        const annotations: {
            x: string;
            y: number;
            marker: {
                size: number;
                fillColor: string;
                strokeColor: string;
                strokeWidth: number;
                shape: string;
                offsetX?: number;
                offsetY?: number;
            };
            label?: {
                borderColor: string;
                offsetX: number;
                offsetY: number;
                style: { color: string; background: string };
                text: string;
            };
            seriesIndex?: number;
            mouseEnter?: () => void;
            mouseLeave?: () => void;
        }[] = [];

        // scatterPlotData.forEach((s, seriesIndex) => {
        //     if (s.y > 0) {
        //         annotations.push({
        //             x: s.x,
        //             y: s.y,
        //             marker: {
        //                 size: 5,
        //                 fillColor: 'rgba(15, 16, 68, 0.75)',
        //                 strokeColor: '#fff',
        //                 strokeWidth: 1,
        //                 shape: 'circle',
        //             },
        //             seriesIndex,
        //             mouseEnter: () => handleMouseEnter(s, event),
        //         });
        //     }
        // });

        return annotations;
    };

    //default options
    const options: ApexOptions = {
        chart: {
            type: 'heatmap',
            height: 250,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            heatmap: {
                shadeIntensity: 0,
                colorScale: {
                    ranges: category === 'LI' ? lIColor : category == 'OPI' ? iopColor : kriColor,
                },
                distributed: false,
                radius: 5,
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels if you only want tooltips
        },
        legend: {
            show: false,
        },
        xaxis: {
            type: 'category',
            categories: ['Very Low', 'Low', 'Moderate', 'High', 'Very High'],
            title: {
                text: 'Breach Severity Index (BSI)',
                style: {
                    color: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontWeight: '500',
                    fontFamily: 'Poppins',
                },
            },
            labels: {
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontWeight: '400',
                    fontFamily: 'Poppins',
                },
            },
            axisBorder: {
                show: false,
                color: '#000',
            },
            axisTicks: {
                show: false,
                color: '#000',
            },
        },
        yaxis: {
            title: {
                offsetX: -5,
                text: 'Impact',
                style: {
                    color: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontWeight: '500',
                    fontFamily: 'Poppins',
                },
            },

            labels: {
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontWeight: '400',
                    fontFamily: 'Poppins',
                },
            },
            axisBorder: {
                show: false,
                color: '#000',
            },
            axisTicks: {
                show: false,
                color: '#000',
            },
        },
        tooltip: {
            enabled: false, // Disable default tooltips
        },
        annotations: {
            points: getAnnotations(),
        },
        states: {
            hover: {
                filter: {
                    type: 'none', // Disable the filter on hover
                },
            },
        },
    };

    return (
        <div className='relative w-full heatmap h-full' onMouseOver={() => setHoveredPoint(null)}>
            <ReactApexChart id='mysvg' options={options} series={sampleData} type='heatmap' height={'100&'} />
            {hoveredPoint && (
                <div
                    className='hitmaptooltip flex items-center'
                    style={{
                        fontSize: '10px',
                        position: 'fixed',
                        top: hoveredPoint.top,
                        left: hoveredPoint.left,
                        background: '#0014FF',
                        color: '#fff',
                        padding: '8px',
                        borderRadius: '7px',
                        pointerEvents: 'none',
                        transform: 'translate(-50%, -100%)',
                    }}
                >
                    {hoveredPoint.name}
                </div>
            )}
        </div>
    );
};

export default MetricHeatMapChart;
