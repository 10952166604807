import Cookies from 'js-cookie';

import history from '../history';
import { store } from '../store';
import { handleLogout, handleRenewToken, handleTokenExpiry } from './chromeExtension';

const ENV_BASE_URL = process.env.REACT_APP_BACK_END_URL || 'http://localhost:3000';
const BASE_URL = ENV_BASE_URL[ENV_BASE_URL.length - 1] === '/' ? ENV_BASE_URL.slice(0, -1) : ENV_BASE_URL;

const logout = () => {
    localStorage.removeItem('jwt');
    Cookies.remove('bberry-token');
    Cookies.remove('bberry-user');
    handleLogout();
};

export const getFullUrl = (path: string) => {
    return BASE_URL + (path[0] === '/' ? '' : '/') + path;
};

const UNAUTHORIZED = 401;
const NOT_FOUND = 404;

export const api = async (url: string, method: string, headers: RequestInit['headers'], body?: string | FormData) => {
    const bearer = localStorage.getItem('jwt') ?? undefined;
    const fullUrl = getFullUrl(url);

    // Check if token is expired
    try {
        if (bearer) {
            const decodedToken = JSON.parse(atob(bearer.split('.')[1]));
            if (decodedToken.exp * 1000 < Date.now()) {
                console.warn('Token expired, logging out...');
                handleTokenExpiry();
                logout();
                history.push('/sign-in');
                return Promise.reject(new Error('Token expired'));
            }
        }
    } catch (error) {
        console.error('Invalid token format:', error);
        logout();
        history.push('/sign-in');
        return Promise.reject(new Error('Invalid token'));
    }

    // Perform API request
    const res = await fetch(fullUrl, {
        method,
        headers: {
            ...headers,
            Authorization: `Bearer ${bearer}`,
        },
        body,
    });

    // Handle errors before parsing JSON
    if (res.status === 503) {
        history.push(`/error/${res.status}`);
        return;
    }

    if (res.status === NOT_FOUND) {
        history.push(`/error/${res.status}`);
        return;
    }

    // Handle Unauthorized (401) before parsing response JSON
    if (res.status === UNAUTHORIZED) {
        console.warn('Unauthorized request, attempting token renewal...');

        if (store.user && store.user.userData?.email && store.user.userData?.id) {
            const renewBody = {
                email: store.user.userData.email,
                userId: store.user.userData.id,
                userType: store.user.userData.userType,
            };

            try {
                const renewRes = await fetch(getFullUrl('/auth/renew-pub-token'), {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(renewBody),
                });

                if (renewRes.ok) {
                    const renewJson = await renewRes.json();
                    store.user.setToken(renewJson.access_token);
                    handleRenewToken(renewJson.access_token);
                    window.location.reload();
                    return;
                } else {
                    console.error('Token renewal failed, logging out.');
                    logout();
                }
            } catch (error) {
                console.error('Token renewal request failed:', error);
                logout();
            }
        }

        return Promise.reject(new Error('Unauthorized access'));
    }

    // Parse response JSON
    let resJson;
    try {
        resJson = await res.json();
    } catch (error) {
        console.error('Error parsing JSON response:', error);
        return Promise.reject(new Error('Invalid server response'));
    }

    // If response is OK, navigate & return response
    if (res.ok) {
        if (history.location.pathname === '/' || history.location.pathname === '/sign-in') {
            history.push('/home');
        }
        return resJson;
    }

    return Promise.reject(new Error(resJson.message ?? resJson.error ?? 'Unknown API error'));
};

export const post = (
    url: string,
    params: Record<string, unknown> = {},
    headers: RequestInit['headers'] = {
        'Content-Type': 'application/json',
    },
) => api(url, 'POST', headers, JSON.stringify(params));

export const postFile = (url: string, formData: FormData, headers: RequestInit['headers'] = {}) =>
    api(url, 'POST', headers, formData);

export const get = (url: string, headers: RequestInit['headers'] = {}) => api(url, 'GET', headers);

export const put = (
    url: string,
    params: Record<string, unknown> = {},
    headers: RequestInit['headers'] = { 'Content-Type': 'application/json' },
) => api(url, 'PUT', headers, JSON.stringify(params));

export const patch = (
    url: string,
    params: Record<string, unknown> = {},
    headers: RequestInit['headers'] = { 'Content-Type': 'application/json' },
) => api(url, 'PATCH', headers, JSON.stringify(params));

export const del = (url: string, headers: RequestInit['headers'] = {}) => api(url, 'DELETE', headers, '');
