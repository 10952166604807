import { useEffect, useState } from 'react';

import { useTour } from '@reactour/tour';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '../../../assets/icons/icon-plus.svg';
import blankstate from '../../../assets/logo/logo_b_dark.png';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { useSettings } from '../../../hooks/useSettings';
import { useSpaces } from '../../../hooks/useSpaces';
import { store } from '../../../store';
import { SpaceInfo } from '../../../store/SpacesStore';
import { SIZES } from '../../../utils/constants';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import WorkspaceSkeleton from '../../skeleton/WorkspaceSkeleton.component';
import SpaceMenuIcon from './SpaceMenuIcon.component';
import './style.css';

const NavbarSpaceMenu = ({ handleClose }: { handleClose: () => void }) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    const [spaceId, setSpaceId] = useState('');
    const [spacesData, setSpacesData] = useState<SpaceInfo[]>();
    const [organisationData, setOrganisationData] = useState<SpaceInfo[]>();
    const [loading, setLoading] = useState(false);

    const { getSpacesWithoutOrg } = useSpaces();
    const { getOrganisationWithInvites } = useOrganisationForm();
    const { setIsOpen, setCurrentStep } = useTour();
    const onboardingTour = store.user.userData?.onboardingTour;
    const { updateOnboardingTour } = useSettings();

    useEffect(() => {
        const id = nanoid();
        setSpaceId(id);
        setLoading(true);
        (async () => {
            const data: SpaceInfo[] = await getSpacesWithoutOrg();
            const organisationData: SpaceInfo[] = await getOrganisationWithInvites();
            setSpacesData(data);
            setOrganisationData(organisationData);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (onboardingTour?.startTour && !onboardingTour?.navbarMenu) {
            setCurrentStep(1);
            setTimeout(() => {
                setIsOpen(true);
                updateOnboardingTour(true, 'navbarMenu');
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='flex flex-col pt-2 pb-3 max-h-[544px] text-secondary rounded bg-white z-auto' data-tour='menu'>
            <div>
                <div
                    className='flex flex-row items-center justify-between hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        localStorage.setItem('spaceId', '');
                        handleClose();
                    }}
                >
                    <Link
                        to='/home'
                        className='flex flex-row items-center space-x-2 capitalize px-5 py-2 '
                        onClick={() => {
                            store.spaces.updateSelectedSpaceId('');
                            store.spaces.setCurrentSelectedWorkSpaceInfo({
                                workspaceBanner: '',
                                workspaceIcon: '',
                                workspaceName: '',
                                workspaceColor: '',
                                workspaceLogo: '',
                            });
                        }}
                    >
                        <div className='bg-white p-2 border rounded-lg border-blue-light border-opacity-10'>
                            <img src={blankstate} className='w-3 h-3' />
                        </div>
                        <div className='text-sm capitalize text-darker text-opacity-75 whitespace-nowrap w-24 truncate'>
                            {t('navbar.home')}
                        </div>
                    </Link>
                </div>
                <HorizontalSeparator />
                <div className=' px-5 py-2'>
                    {/* organisation list */}
                    <div className='flex flex-col'>
                        <p className='text-darker text-opacity-35 text-sm font-medium'>{t('navbar.organisation')}</p>
                        {loading ? (
                            <WorkspaceSkeleton number={1} className='py-2' />
                        ) : (
                            <div className='maxHeight overflow-y-auto  overflow-x-hidden scrollbar pr-1 mt-2'>
                                {organisationData &&
                                    organisationData.length > 0 &&
                                    organisationData.map((organisation: SpaceInfo) => (
                                        <SpaceMenuIcon
                                            key={organisation.spaceId}
                                            space={organisation}
                                            handleClose={handleClose}
                                            type='organisation'
                                        />
                                    ))}
                            </div>
                        )}
                        {!isMobile && (
                            <div>
                                <CreateButton
                                    url='/create-organisation'
                                    spaceId={spaceId}
                                    handleClose={handleClose}
                                    buttonTitle={t('navbar.createOrg')}
                                />
                            </div>
                        )}
                    </div>
                    <HorizontalSeparator className='my-3' />
                    {/* spaces list */}
                    <div className='flex flex-col'>
                        <p className='text-darker text-opacity-35 text-sm font-medium'>{t('navbar.spaces')}</p>
                        {loading ? (
                            <WorkspaceSkeleton number={2} className='py-2' />
                        ) : (
                            <div className='maxHeightSpace max-h-12 overflow-y-auto  overflow-x-hidden scrollbar pr-1 mt-2'>
                                {spacesData &&
                                    spacesData.length > 0 &&
                                    spacesData.map((space: SpaceInfo) => (
                                        <SpaceMenuIcon
                                            key={space.spaceId}
                                            space={space}
                                            handleClose={handleClose}
                                            type='space'
                                        />
                                    ))}
                            </div>
                        )}
                        {!isMobile && (
                            <div>
                                <CreateButton
                                    url='/workspace/add-space'
                                    spaceId={spaceId}
                                    handleClose={handleClose}
                                    buttonTitle={t('navbar.createSpace')}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(NavbarSpaceMenu);

export const CreateButton = ({
    url,
    spaceId,
    handleClose,
    buttonTitle,
}: {
    url: string;
    spaceId: string;
    handleClose: () => void;
    buttonTitle: string;
}) => {
    return (
        <div className='px-2'>
            <Link to={`${url}/${spaceId}`} className='flex-col create_button mt-2 w-full flex space-y-4'>
                <button
                    className='flex w-full items-start justify-start'
                    onClick={() => {
                        localStorage.setItem('spaceId', spaceId);
                        handleClose();
                    }}
                >
                    <div className='flex items-center space-x-4 text-darker text-opacity-75 text-sm font-normal ml-1 '>
                        <PlusIcon className='w-3.5 h-3.5' fill='rgba(15,29,68, 0.75)' />
                        <p>{buttonTitle}</p>
                    </div>
                </button>
            </Link>
        </div>
    );
};
