import * as Sentry from '@sentry/react';

import { appInsights } from "../../AppInsights";
import { get } from "../../utils/network";

export class StripeWebhookService {
    async getEvents() {
        try {
            return await get(`/stripewebhook/events`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }
}