import { useCallback, useEffect, useRef, useState } from 'react';

import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as FavIcon } from '../../../../../../assets/icons/icon-fav.svg';
import { setMetricProtocolLoading } from '../../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../../Redux/store';
import { MetricService } from '../../../../../../services/metric/metric.service';
import { Switch } from '../../../../../basic/Switch.component';
import MetricCardAction from './MetricCardAction.component';
import {
    FeedbackStatus,
    getMetricCategory,
    Metric,
    MetricGraph,
    MetricProtocol,
    MetricProtocolType,
    ProtocolRequirementLevel,
} from './metricsType';
import MetricType from './MetricType.component';
import Protocol from './Protocol.component';
import ProtocolSkeleton from './ProtocolSkeleton.component';

export const clearSvg = () => {
    const svg = document.querySelector('.lines-container');
    const square = document.querySelectorAll('.square') as NodeListOf<HTMLElement>;
    if (svg) {
        svg.innerHTML = '';
    }
    if (square) {
        square.forEach((element) => {
            element.style.visibility = 'hidden';
        });
    }
};

const MetricCard = ({
    metric,
    toggledMetricId,
    feedbackStatus,
    toggleFavourite,
    toggleMetric,
    toggleLock,
    deleteMetric,
    addFeedback,
    deleteFeedback,
    updateMetric,
    handleToggleFilter,
    toggle,
    onToggle,
    handleDlClick,
    protocolUpdated,
    genMetricProtocol,
    updateMetricProtocols,
    maxFavReached,
}: {
    metric: Metric;
    toggledMetricId?: string;
    feedbackStatus?: FeedbackStatus;
    toggleFavourite?: (value: boolean) => void;
    toggleMetric?: (value: boolean) => void;
    toggleLock?: (value: boolean) => void;
    deleteMetric: () => void;
    addFeedback?: (status: FeedbackStatus) => void;
    deleteFeedback?: () => void;
    updateMetric?: () => void;
    handleToggleFilter: () => void;
    toggle: boolean;
    onToggle: () => void;
    handleDlClick: (event: React.MouseEvent<HTMLElement>, index?: number | null) => void;
    protocolUpdated: boolean;
    genMetricProtocol: (mode: string, title: string, inputValues: { nuance: string; threshold: number }[]) => void;
    updateMetricProtocols: (metricId: string, metricData: Partial<Metric>, mode: string) => void;
    maxFavReached: boolean;
}) => {
    const { t } = useTranslation();
    const [isDisabled, setEnableMetric] = useState(!metric?.isEnabled);
    const [favouriteProtocol, setFavourite] = useState(metric.isFavorite);
    const [metricUnavailable, setMetricUnavailable] = useState(false);
    const [metricGraph, setMetricGraph] = useState<MetricGraph[]>([]);
    const [showWarning, setShowWarning] = useState(false);
    const toggledMetricIdRef = useRef(toggledMetricId);
    const dispatch = useDispatch();
    const metricService = new MetricService();

    const protocolIndex = useSelector((state: RootState) => state.EvaInfo.protocolIndex);

    const category = getMetricCategory(t(metric.category));

    const updateLines = (protocols: MetricGraph[], actionType: 'add' | 'remove', kpiId: string) => {
        const svg = document.querySelector('.lines-container');
        if (svg) {
            protocols.forEach((protocol, index) => {
                const centerCircle = document.querySelector(`.${protocol.protocolClass}`) as HTMLElement;
                if (centerCircle) {
                    const circleRect = centerCircle.getBoundingClientRect();
                    const circleCenterX = circleRect.left + 20; // get center X of the circle
                    const circleCenterY = circleRect.top - 5; // get center Y of the circle

                    const kpiElement = document.querySelector(`.metric-card[data-kpi="${kpiId}"]`);
                    if (kpiElement) {
                        const kpiRect = kpiElement.getBoundingClientRect();
                        const kpiCenterX = kpiRect.x + 30;
                        const kpiCenterY = kpiRect.y + 14;

                        const existingPath = svg.querySelector(`path[data-kpi="${kpiId}-${index}"]`);
                        if (actionType === 'add' && !existingPath) {
                            centerCircle.style.visibility = 'visible';
                            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');

                            // Calculate control points: middle point between KPI and circle
                            const controlPointX = (kpiCenterX + circleCenterX) / 2; // X midpoint for a smoother curve
                            const controlPointY = (kpiCenterY + circleCenterY) / 2; // Y midpoint for a smoother curve

                            // Create a cubic Bezier curve connecting the two centers
                            const d = `M${kpiCenterX},${kpiCenterY} C${controlPointX},${kpiCenterY} ${controlPointX},${circleCenterY} ${circleCenterX},${circleCenterY}`;
                            path.setAttribute('d', d);
                            path.setAttribute('stroke', protocol.strokeColor);
                            path.setAttribute('data-kpi', `${kpiId}-${index}`);
                            if (protocol.strokeType) path.setAttribute(protocol.strokeType, '4');
                            path.setAttribute('fill', 'transparent');
                            svg.appendChild(path);
                        } else if (actionType === 'remove' && existingPath) {
                            centerCircle.style.visibility = 'hidden';
                            svg.removeChild(existingPath);
                        }
                    }
                }
            });
        }
    };

    // Handle metric card selection and collapsing
    const handleMetricSelection = (kpiId: string, isSelected: boolean) => {
        if (isSelected) {
            setTimeout(() => {
                updateLines(metricGraph, 'add', kpiId);
            }, 500);
        } else {
            updateLines(metricGraph, 'remove', kpiId);
        }
    };

    const updateMetricGraph = useCallback((metricProtocols: MetricProtocol[]) => {
        const newMetricGraph = metricProtocols.flatMap((protocolGroup) => {
            const { status, protocolArray } = protocolGroup;

            return protocolArray.map((protocol: MetricProtocolType) => {
                let strokeColor = '';
                let strokeType = '';
                if (protocol.requirementLevel === ProtocolRequirementLevel.Required && status !== 'available') {
                    setMetricUnavailable(true);
                }

                // Determine strokeColor and strokeType based on status
                switch (status) {
                    case 'available':
                        strokeColor = '#6FBFE180';
                        break;
                    case 'update':
                        strokeColor = '#6FBFE180';
                        strokeType = 'stroke-dasharray';
                        break;
                    case 'create':
                        strokeColor = '#6FBFE180';
                        strokeType = 'stroke-dasharray';
                        break;
                    default:
                        strokeColor = 'grey';
                        strokeType = 'solid';
                }
                // Return the mapped protocol as MetricGraph
                return {
                    protocolName: protocol.protocolName,
                    protocolClass: `protocol_${protocol.protocolIndex}`,
                    strokeColor,
                    strokeType,
                };
            });
        });
        // Update the state with the new metric graph data
        setMetricGraph(newMetricGraph);
    }, []);

    useEffect(() => {
        if (metric) {
            setEnableMetric(!metric?.isEnabled || false);
            setFavourite(metric.isFavorite);
            if (metric.relatedProtocols) {
                setMetricGraph([]);
                updateMetricGraph(metric.relatedProtocols);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metric]);

    const throttledResize = throttle((metricId, toggle) => {
        const latestToggledMetricId = toggledMetricIdRef.current;
        if (latestToggledMetricId !== metricId) return;
        handleMetricSelection(metricId, toggle);
    }, 500);

    // Toggle logic for collapsing/expanding card
    const handleToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        if (metric.isDefault) return;
        const newToggle = !toggle; // Use the prop toggle value
        if (newToggle && handleToggleFilter) {
            handleToggleFilter();
        }
        const kpiId = metric.id || '';
        handleMetricSelection(kpiId, newToggle);
        onToggle(); // Use the prop setter function
    };

    const handleProtocolClick = async (metricId: string, action: string, protocolName: string) => {
        try {
            dispatch(setMetricProtocolLoading(true));
            const nuances = await metricService.generateMetricProtocol(metricId, action, protocolName);
            if (!nuances) {
                dispatch(setMetricProtocolLoading(false));

                return;
            }
            genMetricProtocol(action, protocolName, nuances);
            dispatch(setMetricProtocolLoading(false));
        } catch (e) {
            console.error(e);
            dispatch(setMetricProtocolLoading(false));
            if (e instanceof Error) {
                Sentry.captureException(e);
            }
        }
    };

    useEffect(() => {
        clearSvg();
        toggledMetricIdRef.current = toggledMetricId;
    }, [toggledMetricId]);

    useEffect(() => {
        const kpiId = metric.id || '';
        const newToggle = !toggle;

        window.addEventListener('resize', () => throttledResize(kpiId, newToggle));

        // Cleanup: Remove the event listener
        return () => {
            window.removeEventListener('resize', () => throttledResize(kpiId, newToggle));
        };
    }, [metric.id, throttledResize, toggle]);

    const { isLoading } = useQuery(
        ['updateProtocolStatus', metric.id, protocolUpdated],
        async () => {
            try {
                if (protocolIndex !== undefined) {
                    if (!metric.id || protocolIndex === null) return;
                    const allProtocols = metric.relatedProtocols?.flatMap((item) => item.protocolArray);

                    const protocol = allProtocols?.find((protocol) => protocol.protocolIndex === protocolIndex + 1);
                    if (!protocol) return;
                    const data = await metricService.updateProtocolStatus(metric.id, protocol.protocolName);
                    updateMetricProtocols(metric.id, data, 'update');
                    return data;
                }
            } catch (e) {
                console.error(e);
                if (e instanceof Error) {
                    Sentry.captureException(e);
                }
            }
        },
        { enabled: !!metric.id && protocolUpdated && toggle && protocolIndex !== null },
    );

    return (
        <div className='w-full'>
            <button
                data-kpi={`${metric.id}`}
                className='metric-card w-full'
                style={{
                    pointerEvents: 'auto',
                    opacity: isDisabled ? 0.6 : 1, // Use opacity to indicate disabled state
                    cursor: isDisabled ? 'not-allowed' : 'pointer', // Change cursor style
                    background: favouriteProtocol || metric.isDefault ? '#FFFFFF1A' : '',
                }}
                onClick={handleToggle}
            >
                <div className='w-full flex justify-between p-2'>
                    {/* Container div with pointer-events disabled for all except the Switch */}
                    <div
                        className='flex space-x-2 items-center'
                        style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
                    >
                        {/* protocol line start */}
                        {toggle && metricGraph.length > 0 && (
                            <div className='relative'>
                                <div className='metric-protocol-mapping metric-to-update' />
                                <div className='metric-protocol-mapping metric-to-create' />
                                <div className='metric-protocol-mapping metric-available' />
                            </div>
                        )}
                        <div
                            className='metric-category'
                            style={{ backgroundColor: category?.color }}
                            data-title={t(category?.name)}
                        />
                        <div className='metric-name-card'>
                            <MetricType type={metric.format} />
                            <p>{metric.name}</p>
                        </div>
                    </div>
                    <div className='relative flex space-x-2 items-center' style={{ pointerEvents: 'none' }}>
                        {metric.newMetric && (
                            <div className='new-metric'>
                                <p>{t('new')}</p>
                            </div>
                        )}

                        <button
                            className={clsx('fav-btn', favouriteProtocol && 'fav-btn-selected')}
                            style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
                            data-title={t('favorite')}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowWarning(false);
                                if (maxFavReached && !favouriteProtocol) {
                                    setShowWarning(true);
                                    setTimeout(() => {
                                        setShowWarning(false);
                                    }, 3000);
                                    return;
                                }
                                setFavourite(!favouriteProtocol);
                                if (toggleFavourite) toggleFavourite(!favouriteProtocol);
                            }}
                        >
                            <FavIcon className='w-3 h-3' stroke='#FFFFFFBF' fill='#FFFFFF' fillOpacity={0} />
                        </button>
                        {/* Enable pointer events only for the Switch */}
                        <div style={{ pointerEvents: 'auto' }} onClick={(e) => e.stopPropagation()}>
                            <Switch
                                checked={!isDisabled}
                                onChange={() => {
                                    if (metricUnavailable) return;
                                    setEnableMetric(!isDisabled);
                                    if (toggleMetric) toggleMetric(isDisabled);
                                }}
                                checkedColor='bg-metric bg-opacity-100'
                                uncheckedColor='bg-white bg-opacity-10'
                                showTooltip
                            />
                        </div>
                    </div>
                </div>
                {toggle && (
                    <div className='w-full metric-card-expanded text-white text-opacity-75'>
                        <p className='text-left'>{metric.description}</p>
                        {isLoading ? (
                            <ProtocolSkeleton />
                        ) : (
                            metric.relatedProtocols &&
                            metric.relatedProtocols.map(
                                (protocol, index) =>
                                    protocol.protocolArray.length > 0 && (
                                        <Protocol
                                            key={index}
                                            protocolStatus={protocol.status}
                                            protocolArray={protocol.protocolArray}
                                            handleClick={(action, protocolName) =>
                                                metric.id && handleProtocolClick(metric.id, action, protocolName)
                                            }
                                            handleDlClick={handleDlClick}
                                        />
                                    ),
                            )
                        )}
                        <div>
                            <MetricCardAction
                                setToggle={onToggle}
                                metricName={metric.name}
                                lockMetric={metric.isLocked || false}
                                toggleLock={toggleLock}
                                handleDelete={deleteMetric}
                                isDefault={metric.isDefault || false}
                                likeFeedback={metric.like?.length || 0}
                                dislikeFeedback={metric.dislike?.length || 0}
                                feedbackStatus={feedbackStatus}
                                addFeedback={addFeedback}
                                deleteFeedback={deleteFeedback}
                                showFeedback={metric.feedback}
                                updateMetric={updateMetric}
                            />
                        </div>
                    </div>
                )}
            </button>
            {toggle && <hr className='horizontal-line' />}
            {showWarning && (
                <div className='fav-warning-container'>
                    <p>{t('favorite_metric_warning')}</p>
                </div>
            )}
        </div>
    );
};

export default observer(MetricCard);
