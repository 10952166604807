import { useEffect, useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { ReactComponent as CheckboxIcon } from '../../../../../../../../src/assets/icons/checkbox_Icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../src/assets/icons/Delete_Icon.svg';
import { ReactComponent as DownArrow } from '../../../../../../../../src/assets/icons/DownArrow.svg';
import { ReactComponent as BackArrow } from '../../../../../../../../src/assets/icons/leftArrow.svg';
import { ReactComponent as SortIcon } from '../../../../../../../../src/assets/icons/SortArrow_Icon.svg';
import {
    setChildScreen,
    setClickProtocol,
    setNewMetrics,
    setNewProtocols,
    setPrvMetrics,
    setPrvProtocols,
    setSelectMetrics,
    setSessionsId,
    setVersionsHistoryId,
} from '../../../../../../../Redux/SessionsProtocols';
import { RootState, useAppDispatch } from '../../../../../../../Redux/store';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import FormSelect from '../../../../../../profile/Form/FormSelect';
import { VersionHistory } from '../../../../evaAnalytics/components/analyticsChart/types';

interface VersionsHistoryProps {}

const VersionsHistory: React.FC<VersionsHistoryProps> = () => {
    const { t } = useTranslation();
    const [sortVal, setSortVal] = useState('');
    const [history, setHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [remainingRecords, setRemainingRecords] = useState([]);

    const sortType = ['Newest First', 'Older First', 'Sort A-Z', 'Sort Z-A'];
    const [showSortList, setShowSortList] = useState(false);

    const handleChangeSortType = (name: string) => {
        setSortVal(name);
        setPage(1);
    };

    const dispatch = useAppDispatch();

    const sessionIds = useSelector((state: RootState) => state.SessionsProtocols.SessionsId);

    const evaService = new EvaService();

    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    //multiple version report select
    const handleCheckboxChange = (id: string) => {
        setCheckedItems(
            (prevCheckedItems) =>
                prevCheckedItems.includes(id)
                    ? prevCheckedItems.filter((item) => item !== id) // Remove if already checked
                    : [...prevCheckedItems, id], // Add if not checked
        );
    };
    const evaId = useSelector((state: RootState) => state.SessionsProtocols.EvaId);

    const {
        data: historyReport,
        isFetching,
        refetch: refetchHistory,
    } = useQuery(
        ['reportData', sessionIds, sortVal, page], // Use a distinct key for report fetching
        async () => {
            if (sessionIds) {
                const reportData = await evaService.getReportVersionHistory(evaId, sessionIds, sortVal, String(page));
                dispatch(setClickProtocol(false));
                return reportData;
            }
            return [];
        },
        {
            enabled: true,
        },
    );

    //when more records in history list
    useEffect(() => {
        if (historyReport) {
            if (page == 1) {
                setHistory(historyReport?.history?.slice(0, 30));
                setRemainingRecords(historyReport?.history?.slice(30, 60));
            } else {
                setHistory([...history, ...remainingRecords]);
                setRemainingRecords(historyReport?.history);
            }
        }
        dispatch(setPrvMetrics([]));
        dispatch(setSelectMetrics([]));
        dispatch(setNewMetrics([]));
        dispatch(setNewProtocols([]));
        dispatch(setPrvProtocols([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyReport, page]);

    useEffect(() => {
        refetchHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const HistoryList = document.querySelector('.executive_summaries_detail');
        // Define the scroll handler with debounce
        const handleScroll = debounce(() => {
            if (HistoryList) {
                const { scrollTop, scrollHeight, clientHeight } = HistoryList;
                // Check if the user has scrolled near the bottom of the container
                setPage((pre: number) => {
                    if (historyReport?.totalPage > pre) {
                        if (Math.abs(scrollTop) + clientHeight > scrollHeight - 10) {
                            return pre + 1;
                        }
                    }
                    return pre;
                });
            }
        }, 0);

        // Attach the scroll event listener
        if (HistoryList) {
            HistoryList.addEventListener('scroll', handleScroll);
        }
        // Cleanup: Remove the scroll event listener when the component unmounts
        return () => {
            if (HistoryList) {
                HistoryList.removeEventListener('scroll', handleScroll);
            }
        };
    }, [historyReport, page, isFetching]);

    // Debounce utility function to limit the rate of function execution
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
        let timeoutId: NodeJS.Timeout | null;

        return function (...args: Parameters<T>): void {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const { isLoading: deleteLoading, refetch: deleteRefetch } = useQuery(
        ['deleteReportData', checkedItems, sortVal], // Separate key for deletion
        async () => {
            if (checkedItems) {
                const isParentReport = false;
                const reportData = await evaService.DeleteHistory(evaId, checkedItems, isParentReport);
                if (reportData.status === 200) {
                    return reportData;
                }
            }
        },
        {
            enabled: false, // Disable auto-fetching
            onSuccess: () => {
                refetchHistory(); // Refetch the history data after successful deletion
            },
        },
    );

    // Function to trigger the deletion
    const DeleteHistory = () => {
        deleteRefetch(); // Trigger the delete query manually
    };

    return (
        <>
            <div className='summaries_heading flex justify-between items-center'>
                <div className='flex items-center gap-3'>
                    <button
                        className='flex History_btn items-center relative justify-center cursor-pointer '
                        onClick={() => {
                            dispatch(setChildScreen('Report'));
                            dispatch(setSessionsId(sessionIds));
                        }}
                    >
                        <BackArrow />
                    </button>
                    <h1> {t('versions_history')} </h1>
                </div>

                {history?.length > 0 && checkedItems.length > 0 && (
                    <button className='flex Delete_btn items-center relative justify-center' onClick={DeleteHistory}>
                        <DeleteIcon />
                        <div className='tooltip_box '>
                            <p> {t('btnNames.delete')} </p>
                        </div>
                    </button>
                )}
            </div>

            <div className='versions_history'>
                {history?.length > 0 && (
                    <div className=' panel_top_bar '>
                        <div
                            className={`sort hovered flex relative justify-center gap-2 items-center cursor-pointer  ${
                                showSortList ? 'active' : ''
                            }`}
                            onClick={() => setShowSortList(!showSortList)}
                        >
                            <SortIcon />
                            <DownArrow className='arrow' />

                            {showSortList && (
                                <div
                                    className={`sort_list absolute ${showSortList ? 'active' : ''}`}
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    <div className='sort_head flex gap-4 items-center '>
                                        <SortIcon />
                                        {t('sort_by')}
                                    </div>
                                    <ul>
                                        {sortType.map((item) => {
                                            return (
                                                <li
                                                    className={`${sortVal === item ? 'active' : ''}`}
                                                    onClick={() => {
                                                        setShowSortList(false);
                                                        handleChangeSortType(item);
                                                    }}
                                                >
                                                    {t(`sortOptions.${item}`)}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className='scrollbar main_version_list'>
                    <ul className='mt-2 executive_summaries_detail '>
                        {history?.length > 0 ? (
                            history?.map((item: VersionHistory, index: number) => {
                                return (
                                    <li className='flex items-center gap-2 mb-2'>
                                        <div>
                                            {!checkedItems.includes(item.session_id) && (
                                                <div
                                                    className='check_input cursor-pointer '
                                                    onClick={() => handleCheckboxChange(item.session_id)}
                                                />
                                            )}

                                            {checkedItems.includes(item.session_id) && (
                                                <div
                                                    onClick={() => handleCheckboxChange(item.session_id)}
                                                    className='cursor-pointer'
                                                >
                                                    <CheckboxIcon />
                                                </div>
                                            )}
                                        </div>

                                        <div
                                            className='report_box flex justify-between items-center'
                                            onClick={() => {
                                                dispatch(setVersionsHistoryId(item.session_id));
                                                dispatch(setChildScreen('ViewHistoryReport'));
                                            }}
                                        >
                                            <p className='report_name report_heading'> {item?.report_name} </p>
                                            <p className='report_time'>
                                                {' '}
                                                {moment(item?.created_at).format('DD.MM.YY')}{' '}
                                            </p>
                                        </div>
                                    </li>
                                );
                            })
                        ) : (
                            <p className='no_data_found my-5 py-5'> {t('no_history_available')} </p>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default VersionsHistory;
