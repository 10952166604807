import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../../assets/icons/icon-arrow-left-long.svg';
import { usePaymentMethods } from '../../../hooks/usePaymentMethods';
import CardCheckButton from '../../basic/CardCheckButton.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import CreditCardDetails from './CreditCardDetails';

const EditPaymentMethod = () => {
    const { t } = useTranslation();
    const { cardId } = useParams<{ cardId: string }>();
    const { id: organisationId } = useParams<{ id: string }>();
    const { currentPaymentMethod, cardTypeCheck, setCardTypeCheck } = usePaymentMethods(cardId);
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    return (
        <div className='flex flex-col w-full max-w-5xl mx-auto'>
            <button
                onClick={() => history.back()}
                className='absolute right-6 space-x-2 ml-auto h-8 flex items-center mt-8'
            >
                <BackIcon /> <p className='text-sm text-darker text-opacity-75'>Back</p>
            </button>
            <div className='w-full mx-auto flex flex-col text-sm text-darker text-opacity-75 max-w-5xl'>
                <p className='mb-6 mt-10 text-center'>{t('billing.editPayment')}</p>
                <CardCheckButton checked={cardTypeCheck} onClick={() => setCardTypeCheck(!cardTypeCheck)} />
                <HorizontalSeparator className='mt-6 mb-4' />
                <CreditCardDetails currentCreditCard={currentPaymentMethod?.card} isCardTypeChecked={cardTypeCheck} />
            </div>
        </div>
    );
};

export default EditPaymentMethod;
