import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LongLength } from '../../../../../../assets/icons/icon-long-length.svg';
import { ReactComponent as MediumLength } from '../../../../../../assets/icons/icon-medium-length.svg';
import { ReactComponent as ShortLength } from '../../../../../../assets/icons/icon-short-length.svg';

export const ConversationType = {
    Short: 'Short',
    Medium: 'Medium',
    Long: 'Long',
};
const ConversationLength = ({ type, setType }: { type: string; setType: (type: string) => void }) => {
    const { t } = useTranslation();
    const handleClick = (type: string) => {
        setType(type);
    };
    return (
        <div className='w-full flex items-center space-between'>
            <p className='w-14 text-sm font-medium'>{t('length')}</p>
            <div className='converstaion-length-container'>
                <button
                    className={clsx(
                        type === ConversationType.Short && 'selected-conversation-type',
                        'conversation-type',
                    )}
                    onClick={() => handleClick(ConversationType.Short)}
                >
                    <ShortLength />
                </button>
                <button
                    className={clsx(
                        type === ConversationType.Medium && 'selected-conversation-type',
                        'conversation-type',
                    )}
                    onClick={() => handleClick(ConversationType.Medium)}
                >
                    <MediumLength />
                </button>
                <button
                    className={clsx(
                        type === ConversationType.Long && 'selected-conversation-type',
                        'conversation-type',
                    )}
                    onClick={() => handleClick(ConversationType.Long)}
                >
                    <LongLength />
                </button>
            </div>
        </div>
    );
};

export default ConversationLength;
