import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { timestampToDate } from '../../../../utils/dateUtils';
import { Button } from '../../../basic/Button.component';
import { Dialog } from '../../../basic/Dialog.component';

const Downgrade = ({
    organisationName,
    endDate,
    handleDowngrade,
}: {
    organisationName: string;
    endDate: number;
    handleDowngrade: () => void;
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);
    const { id: organisationId } = useParams<{ id: string }>();
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    const handleConfirm = async () => {
        setLoading(true);
        handleDowngrade();
        setTimeout(async () => {
            setLoading(false);
            close();
        }, 2000);
    };

    return (
        <div className='w-full h-full'>
            {showDialog && (
                <Dialog
                    handleConfirm={handleConfirm}
                    title={t('billing.cancelConfirmHeader')}
                    close={close}
                    open={open}
                    loading={loading}
                    content={
                        <div
                            className='h-14 text-sm text-darker text-opacity-75'
                            dangerouslySetInnerHTML={{
                                __html: t('billing.cancelConfirmMsg', {
                                    endDate: timestampToDate(endDate),
                                }),
                            }}
                        />
                    }
                    confirmText={t('billing.cancel')}
                    buttonVariant='primaryCancel'
                />
            )}
            <div className='w-full'>
                <div className='w-full p-4 flex items-center justify-between'>
                    <div className='max-w-3xl text-darker text-opacity-75 mr-14'>
                        <h3 className='font-medium text-sm mb-2'>
                            {t('btnNames.cancel')} {organisationName}
                        </h3>
                        <p
                            className='text-darker text-opacity-75 text-xs whitespace-pre-line font-normal'
                            dangerouslySetInnerHTML={{
                                __html: t('billing.canceledMsg', {
                                    endDate: timestampToDate(endDate),
                                }),
                            }}
                        />
                    </div>
                    <Button onClick={open} variant='primaryCancel' size='oval' className='whitespace-nowrap'>
                        {t('billing.cancel')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Downgrade;
