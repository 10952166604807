import React, { createContext, useContext, useEffect, useState } from 'react';

interface CreateEvaModalContext {
    isCreateEvaModalOpen: boolean;
    setCreateEvaModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

    setSelectedProtocol: React.Dispatch<React.SetStateAction<string | []>>;
    selectedProtocol: string | [];
}

const CreateEvaModalContext = createContext<CreateEvaModalContext | undefined>(undefined);

export const CreateEvaModalContextProvider: React.FC = ({ children }) => {
    const [isCreateEvaModalOpen, setCreateEvaModalOpen] = useState<boolean>(() => {
        const item = window.localStorage.getItem('checkOpenModal');
        return item ? JSON.parse(item) : false;
    });

    const [selectedProtocol, setSelectedProtocol] = useState<string | []>([]);

    useEffect(() => {
        // Save to local storage whenever isCreateEvaModalOpen changes
        window.localStorage.setItem('checkOpenModal', JSON.stringify(isCreateEvaModalOpen));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateEvaModalOpen]);

    return (
        <CreateEvaModalContext.Provider
            value={{
                isCreateEvaModalOpen,
                setCreateEvaModalOpen,

                setSelectedProtocol,
                selectedProtocol,
            }}
        >
            {children}
        </CreateEvaModalContext.Provider>
    );
};

export const CreateEvaModalOpen = (): CreateEvaModalContext => {
    const context = useContext(CreateEvaModalContext);
    if (!context) {
        throw new Error(' CreateEvaModalOpen must be used within an CreateEvaModalContextProvider');
    }
    return context;
};
