import moment from 'moment';
import 'moment/locale/pt-br';

import i18n from '../../../../../../i18n';

export function calculateMaxYValue(maxValue: number) {
    // Default step sizes for the y-axis
    const stepSizes = [0, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000];

    // Minimum value for the chart
    const minValue = 0;

    // Calculate the data range
    const dataRange = maxValue - minValue;

    // Number of maximum ticks on the y-axis
    const maxTicks = 10;

    // Determine the suitable step size
    let suitableStepSize: number;

    if (maxValue >= 0) {
        // Handle the special case when maxValue is 0
        suitableStepSize = 2;
    } else {
        const stepSize = dataRange / maxTicks;
        suitableStepSize = stepSizes.find((size) => stepSize <= size) || stepSizes[stepSizes.length - 1];
    }

    // Calculate and return the new maximum y-axis value
    const maxYValue =
        maxValue === 0
            ? 4 // Default upper limit when all values are zero
            : Math.ceil(maxValue / suitableStepSize) * suitableStepSize + suitableStepSize;

    return maxYValue;
}

export function getCategories(timeFilter: string, filterFormat: string[]): string[] {
    let timeFormat = '';
    let categories: string[] = [];

    const currentYear = new Date().getFullYear();

    switch (timeFilter) {
        case 'Year':
        case 'All':
            timeFormat = 'MMM YYYY';
            categories = filterFormat?.map((date) => {
                if (date.includes('-')) {
                    const [month, year] = date.split('-');
                    return moment(`${year}-${month}-01`, 'YYYY-MM-DD')
                        .locale(i18n.language || 'en')
                        .format(timeFormat);
                } else {
                    return moment(`${date}-01-01`, 'YYYY-MM-DD')
                        .locale(i18n.language || 'en')
                        .format('YYYY');
                }
            });
            break;

        case 'Month':
            timeFormat = 'D'; // Day of the month
            categories = filterFormat?.map((date) => {
                const [day, month] = date.split('-');
                return moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD')
                    .locale(i18n.language || 'en')
                    .format(timeFormat);
            });
            break;

        case 'Week':
            timeFormat = 'DD MMM';
            categories = filterFormat?.map((date) => {
                const [day, month] = date.split('-');
                return moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD')
                    .locale(i18n.language || 'en')
                    .format(timeFormat);
            });
            break;

        case 'Day':
            timeFormat = 'HH:mm';
            categories = filterFormat;
            break;

        default:
            categories = [];
            break;
    }

    return categories;
}

export const getTimeIntervalWithFilter = (timeFilter: string): number => {
    switch (timeFilter) {
        case 'All':
            return 12;
        case 'Year':
            return 13;
        case 'Day':
            return 25;
        case 'Week':
            return 7;
        case 'Month':
            return 30;
        default:
            return 0;
    }
};
