/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Signal from '../../../../../../../src/assets/icons/Signal.svg';
import { ReactComponent as FlagIcon } from '../../../../../../assets/icons/icon-metamarker-trigger-flag-black.svg';
import { useSubscriptionDetail } from '../../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../../hooks/useOrganisation';
import { RootState, useAppSelector } from '../../../../../../Redux/store';
import { EvaType, Nuances, SubScriptionType } from '../../types';
import { getRgbColorCode } from '../ColorCode';
import { OptionData } from '../ProtocolTypeList';

interface OrbitProps {
    labelShow: boolean;
    isCreateEvaModalOpen: boolean;
    isEnableEva: boolean;
}

const Orbit: React.FC<OrbitProps> = ({ labelShow, isCreateEvaModalOpen, isEnableEva }) => {
    const { id: organisationId } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const protocolIndex = useAppSelector((state: RootState) => state.EvaInfo.protocolIndex);

    const { getEvaByOrganisationId } = useOrganisationForm();
    const [orbitsArray, setOrbitsArray] = useState<
        {
            id: number;
            name: string;
            triggerNumber: number;
            triggerState: string;
            nuances: any;
            color: any;
            protocolType: string;
        }[]
    >([]);
    const [hoveredDot, setHoveredDot] = useState<number | null>(null);
    const [metaValue, setMetaValue] = useState<number | null>(null);
    const [openModel, setOpenModel] = useState(false);
    const [hoverLable, setHoverLable] = useState<number | null>(null);
    const [subscriptionLimit, setSubscriptionLimit] = useState<null | number>();

    const svgRef = useRef<SVGSVGElement>(null);
    const [orbitDotsPosition, setOrbitDotsPosition] = useState({ x: 0, y: 0 });

    const subscription = useSubscriptionDetail();

    useEffect(() => {
        if (subscription) {
            if (subscription?.productName === SubScriptionType.SHIELD) {
                setSubscriptionLimit(10);
            }

            if (subscription?.productName === SubScriptionType.OVERSIGHT) {
                setSubscriptionLimit(40);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);

    useEffect(() => {
        const fetchEvaData = async () => {
            const eva = await getEvaByOrganisationId(organisationId, EvaType.publish);

            if (eva.id !== null) {
                const dataArray = (
                    eva.metaMarker?.length > 10 ? eva?.metaMarkers?.slice(0, subscriptionLimit) : eva?.metaMarkers
                ).map((ob: any, index: number) => {
                    return {
                        id: index,
                        name: ob.metaMarker,
                        nuances: ob.nuances,
                        triggerNumber: ob.triggerNumber,
                        triggerState: ob.triggerState,
                        color: ob.color,
                        protocolType: ob.protocolType,
                    };
                });
                setOrbitsArray(dataArray);
            }
        };

        if (!isCreateEvaModalOpen) {
            fetchEvaData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateEvaModalOpen, organisationId, subscriptionLimit]);

    const handleDotHover = (index: number | null) => {
        setHoveredDot(index);
    };

    const handleDotClick = (index: number, event: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
        setMetaValue((prev) => (prev === index ? null : index));
        const svgElement = svgRef.current;
        if (svgElement) {
            const svgRect = svgElement.getBoundingClientRect();
            const mouseX = event.clientX - svgRect.left;
            const mouseY = event.clientY - svgRect.top;
            setOrbitDotsPosition({ x: mouseX, y: mouseY });
        }
    };
    // Create a map to store the event handlers

    const handleLineClick = useCallback((index: number | null) => {
        setMetaValue((prev) => (prev === index ? null : index));
        const circle = document.querySelector(`.circle-${index}`) as HTMLElement;
        if (circle) {
            const circleRect = circle.getBoundingClientRect();
            const circlePositionX = circleRect.left;
            const circlePositionY = circleRect.top;
            const svgElement = svgRef.current;
            if (svgElement) {
                const svgRect = svgElement.getBoundingClientRect();
                const mouseX = circlePositionX - svgRect.left;
                const mouseY = circlePositionY - svgRect.top;
                setOrbitDotsPosition({ x: mouseX, y: mouseY + 5 });
            }
        } else {
            console.warn(`Circle with index ${index} not found.`);
        }
    }, []);
    useEffect(() => {
        if (protocolIndex !== null && protocolIndex !== undefined) {
            handleLineClick(protocolIndex);
        } else {
            handleLineClick(null);
        }
    }, [protocolIndex, handleLineClick]);
    useEffect(() => {
        if (metaValue !== null) {
            setMetaValue(metaValue);
            setOpenModel(true);
        }
    }, [metaValue, openModel]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const svgElement = svgRef.current;
            if (!svgElement) return;
            if (svgElement && !svgElement.contains(event.target as Node)) {
                setMetaValue(null);
                setOpenModel(false);
            }
        };

        if (openModel) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            if (openModel) {
                document.removeEventListener('click', handleOutsideClick);
            }
        };
    }, [svgRef, openModel, metaValue]);

    const maxDotsPerOrbit = 10;
    const dots: JSX.Element[] = [];

    const calculateOrbits = () => {
        let remainingDots = orbitsArray && orbitsArray.length;

        while (remainingDots > 0) {
            const dotsInThisOrbit = Math.min(remainingDots, maxDotsPerOrbit);
            // Pass the current orbit number to renderOrbit
            dots.push(renderOrbit(dotsInThisOrbit, dots.length));
            remainingDots -= dotsInThisOrbit;
        }

        return dots;
    };
    const orbitModels: JSX.Element[] = [];

    const renderOrbit = (dotsInThisOrbit: number, orbitNumber: number) => {
        // Calculate the rotation angle for this orbit
        const totalOrbits = Math.ceil(orbitsArray.length / maxDotsPerOrbit);
        const rotationAngle = 180 - orbitNumber * (180 / totalOrbits);

        const desireHorizontalDiameter = 771.1;
        const desireVerticalDiameter = 284.23;

        const horizontalRadius = desireHorizontalDiameter / 28.5;
        const verticalRadius = desireVerticalDiameter / 24;
        const orbitDots = [];
        const orbitLabels: JSX.Element[] = [];
        const orbitLines: JSX.Element[] = [];
        const MaxCharacterLength = 8;

        for (let i = 0; i < dotsInThisOrbit; i++) {
            // Calculate index for label in orbitsArray
            const labelIndex = orbitNumber * maxDotsPerOrbit + i;

            // circle higLight
            const higLight = labelIndex === metaValue || hoveredDot == labelIndex || labelIndex == hoverLable;
            const lineStroke = hoveredDot === labelIndex || labelIndex == hoverLable || labelIndex === metaValue;
            const higLightText = labelIndex === hoverLable || hoveredDot === labelIndex;

            const angle = (Math.PI * 2 * i) / maxDotsPerOrbit;

            // Ensure a minimum distance of 5 units. If the distance is equal to or less than 5,
            // the dot position will be adjusted to be before or after the previous dot accordingly.
            const minDistance = 5; // Don't change it.
            // Adjust the distance from the planet for specific indices to control dot positioning.
            // A smaller distance moves dots closer to the planet, while a larger distance moves them farther away.
            const distanceFromPlanet = 35;

            const adjustedDistance = Math.max(distanceFromPlanet, minDistance);

            // Determine the direction of distance adjustment based on specific indices.
            const distanceAdjustmentDirection = i === 2 || i === 7 ? -1 : i === 3 || i === 8 ? 1 : 0;
            const adjustedAngle = angle + (distanceAdjustmentDirection * Math.PI) / adjustedDistance;
            const x: number = 50 + horizontalRadius * Math.cos(adjustedAngle);
            const y = 50 + verticalRadius * Math.sin(adjustedAngle);

            // Add dot
            orbitDots.push(
                <svg width='100%' height='100%'>
                    <defs>
                        <filter id='shadow' x='-50%' y='-50%' width='200%' height='200%'>
                            <feGaussianBlur in='SourceAlpha' stdDeviation='2' result='blur' />
                            <feOffset in='blur' dx='0' dy='0' result='offsetBlur' />
                            <feFlood floodColor='rgb(0, 123, 255)' result='color' />
                            <feComposite in2='offsetBlur' operator='in' in='color' result='shadow' />
                            <feMerge>
                                <feMergeNode in='shadow' />
                                <feMergeNode in='SourceGraphic' />
                            </feMerge>
                        </filter>
                    </defs>
                    <circle
                        key={labelIndex}
                        cx={`${x}%`}
                        cy={`${y}%`}
                        className={`circle-${labelIndex} cursor-pointer`}
                        r={`${higLight || protocolIndex === labelIndex ? '4' : '2'}`}
                        stroke={`${higLight || protocolIndex === labelIndex ? 'white' : ''}`}
                        strokeWidth={`${higLight || protocolIndex === labelIndex ? '1' : ''}`}
                        fill={`${
                            isEnableEva
                                ? orbitsArray[labelIndex].color ??
                                  getRgbColorCode(orbitsArray[labelIndex].nuances.map((ob: Nuances) => ob.threshold))
                                : '#E2E3E8'
                        }`}
                        filter={`${higLight || protocolIndex === labelIndex ? 'url(#shadow)' : ''}`}
                        onClick={(event) => handleDotClick(labelIndex, event)}
                        onMouseEnter={() => handleDotHover(labelIndex)}
                        onMouseLeave={() => handleDotHover(null)}
                        style={{ pointerEvents: 'auto', zIndex: 20 }}
                    />
                    ,
                </svg>,
            );

            if (labelIndex < orbitsArray.length) {
                // Add label
                const labelAnchor = x < 50 ? 'end' : 'start';

                const labelStyle = {
                    fontSize: 12,
                    fontWeight: 500,
                    transform: `rotate(${rotationAngle}deg)`,
                    transformOrigin: `${x}% ${y}%`,
                };

                orbitLabels.push(
                    <g key={`${labelIndex}`} style={{ pointerEvents: 'auto' }}>
                        <foreignObject
                            key={labelIndex}
                            x={`${orbitNumber === 0 ? (x < 50 ? x : x - 17.4) : i <= 4 ? x : x - 17.4}%`}
                            y={`${y - 2.8}%`}
                            width='164px'
                            height='18px'
                            textAnchor={labelAnchor}
                            style={{
                                transform: `rotate(${rotationAngle}deg)`,
                                transformOrigin: `${x}% ${y}%`,
                                lineHeight: '15px',
                                textAlign: orbitNumber === 0 ? (x < 50 ? 'end' : 'start') : i <= 4 ? 'end' : 'start',
                            }}
                            onPointerEnter={() => setHoverLable(labelIndex)}
                            onPointerLeave={() => setHoverLable(null)}
                            onClick={() => handleLineClick(labelIndex)}
                        >
                            {labelIndex === metaValue ? (
                                ''
                            ) : (
                                <div
                                    className='orbit-labels'
                                    style={{
                                        transformOrigin: `${x}% ${y}%`,
                                        color:
                                            higLightText || protocolIndex === labelIndex
                                                ? '#4d78ff'
                                                : 'rgba(15, 16, 68, 0.2)',
                                        margin:
                                            orbitNumber === 0
                                                ? x < 50
                                                    ? ' 0 0 0 -4px'
                                                    : '0'
                                                : i <= 4
                                                ? ' 0 0 0 -4px'
                                                : '0',
                                    }}
                                >
                                    {labelIndex === hoverLable ||
                                    hoveredDot === labelIndex ||
                                    (!labelShow && hoveredDot !== null)
                                        ? orbitsArray[labelIndex].name
                                        : orbitsArray[labelIndex].name.length > MaxCharacterLength
                                        ? orbitsArray[labelIndex].name.substring(0, MaxCharacterLength) + '...'
                                        : orbitsArray[labelIndex].name}
                                </div>
                            )}
                        </foreignObject>
                    </g>,
                );

                const lineStyle = {
                    fontSize: 8,
                    transform: `rotate(${rotationAngle}deg)`,
                    transformOrigin: `${x}% ${y}%`,
                };

                orbitLines.push(
                    <line
                        key={labelIndex}
                        x1={`${x}%`}
                        y1={`${y}%`}
                        x2={`${orbitNumber === 0 ? (x < 50 ? x + 17.4 : x - 17.4) : i <= 4 ? x + 17.4 : x - 17.4}%`}
                        y2={`${y}%`}
                        stroke={lineStroke || protocolIndex === labelIndex ? '#0F104459' : ' rgba(15, 16, 68, 0.1)'}
                        style={lineStyle}
                    />,
                );

                const orbitX = orbitDotsPosition?.x;
                const orbitY = orbitDotsPosition.y > 130 ? orbitDotsPosition.y - 150 : orbitDotsPosition.y - 20;

                if (metaValue === labelIndex) {
                    const getPrototypeDetail = OptionData.find(
                        (item) => item.name === orbitsArray[labelIndex].protocolType,
                    );
                    const customColor = 'linear-gradient(0deg, rgba(108, 109, 184, 1), rgba(108, 109, 184, 1))';
                    orbitModels.push(
                        <g key={`${labelIndex}`} id='popup' style={{ pointerEvents: 'auto' }}>
                            <foreignObject
                                style={{ height: '140px' }}
                                x={
                                    orbitNumber === 0
                                        ? x < 50
                                            ? orbitX + 10
                                            : orbitX - 150
                                        : i <= 4
                                        ? orbitX + 10
                                        : orbitX - 150
                                }
                                y={`${orbitY}`}
                                width='150px'
                            >
                                <div className=' boxStyle'>
                                    <p className='boxHeading'> {orbitsArray[labelIndex]?.name} </p>
                                    <hr className='boxBorder' />

                                    <p
                                        className='protocolType_text flex items-center '
                                        style={{
                                            backgroundImage:
                                                getPrototypeDetail == undefined
                                                    ? customColor
                                                    : getPrototypeDetail?.txtColor,
                                        }}
                                    >
                                        {' '}
                                        <img
                                            src={getPrototypeDetail == undefined ? Signal : getPrototypeDetail?.icon}
                                            alt='Icons'
                                        />{' '}
                                        {orbitsArray[labelIndex].protocolType === undefined
                                            ? t('protocolTypes.Signal')
                                            : t(`protocolTypes.${orbitsArray[labelIndex].protocolType}`)}
                                    </p>
                                    <div className='boxNucences'>
                                        {orbitsArray[labelIndex]?.nuances?.length}{' '}
                                        <span className='boxNucenceText'>{t('nuances')}</span>
                                    </div>
                                    <div className='boxTrigger'>
                                        <FlagIcon />
                                        <span>{orbitsArray[labelIndex]?.triggerState}</span>
                                        <span> {orbitsArray[labelIndex]?.triggerNumber} </span>
                                    </div>
                                </div>
                            </foreignObject>
                        </g>,
                    );
                }
            }
        }

        return (
            <g
                key={dots.length}
                className={`orbit-${dots.length} `}
                style={{
                    transform: `rotate(-${rotationAngle}deg)`,
                    transformOrigin: 'center',
                }}
            >
                <g>
                    <ellipse
                        cx='50%'
                        cy='50%'
                        rx={`${horizontalRadius}%`}
                        ry={`${verticalRadius}%`}
                        fill='none'
                        stroke='#0F10441A'
                    />

                    {labelShow && orbitLines}
                    {labelShow && orbitLabels}
                </g>

                {/* Group for dots */}
                <g>{orbitDots}</g>

                {/* Render labels for the selected dot */}
                {!labelShow && hoveredDot !== null && (
                    <>
                        {orbitLabels.map((label, index) => {
                            const keyNumber = parseInt(label.key as string, 10);
                            if (keyNumber === hoveredDot) {
                                return <React.Fragment key={label.key}>{orbitLabels[index]}</React.Fragment>;
                            }
                            return null;
                        })}
                    </>
                )}

                {!labelShow && hoveredDot !== null && (
                    <>
                        {orbitLines.map((label, index) => {
                            const keyNumber = parseInt(label.key as string, 10);
                            if (keyNumber === hoveredDot) {
                                return <React.Fragment key={label.key}>{orbitLines[index]}</React.Fragment>;
                            }
                            return null;
                        })}
                    </>
                )}
            </g>
        );
    };

    return (
        <div className='absolute inset-0 orbit_bots'>
            <svg ref={svgRef} className='w-full h-full'>
                {calculateOrbits()}
                {openModel && metaValue !== null && (
                    <>
                        {orbitModels.map((label, index) => {
                            const keyNumber = parseInt(label.key as string, 10);
                            if (keyNumber === metaValue) {
                                return <React.Fragment key={label.key}>{orbitModels[index]}</React.Fragment>;
                            }
                            return null;
                        })}
                    </>
                )}
            </svg>
        </div>
    );
};

export default Orbit;
