import React from 'react';

import { Box, Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/icon-cross-fill.svg';
import { Button, buttonVariants } from '../../../../basic/Button.component';
import './style.css';

const EvaDialog = ({
    open,
    setClose,
    onConfirm,
    disabled,
    title,
    content,
    confirmButtonText,
    buttonVariant,
    isConfirmDisabled,
    isCloseModal = false,
}: {
    open: boolean;
    setClose: (value: boolean) => void;
    onConfirm: () => void;
    disabled: boolean;
    title: string;
    content: React.ReactNode;
    confirmButtonText: string | React.ReactNode;
    buttonVariant: keyof typeof buttonVariants;
    isConfirmDisabled?: boolean;
    isCloseModal?: boolean | undefined;
}) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={() => setClose(false)} className='dialogPaper'>
            <Box className='bg-white text-black dialogBox'>
                <DialogTitle className='dialogHeader'>
                    <p className='text-base font-medium text-darker text-opacity-75'>{title}</p>
                    <IconButton edge='end' color='primary' onClick={() => setClose(false)} aria-label='close'>
                        <CloseIcon fill='#8787a1' />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='dialogContent'>{content}</DialogContent>
                <DialogActions className='dialog-button-wrap'>
                    <Button
                        variant='outlinedGray'
                        className='hover:border-blue-dark dialogButton'
                        size='oval'
                        disabled={disabled}
                        onClick={() => setClose(false)}
                    >
                        {t('btnNames.cancel')}
                    </Button>
                    <Button
                        variant={buttonVariant}
                        className='hover:bg-opacity-75  dialogButton !border-none'
                        size='oval'
                        disabled={disabled || isConfirmDisabled}
                        onClick={() => {
                            onConfirm();
                            if (!isCloseModal) {
                                setClose(false);
                            }
                        }}
                    >
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default EvaDialog;
