export const getCategoryIcon = (category: string): string => {
    switch(true){
        case category === 'Agriculture':
            return String.fromCodePoint(parseInt('1F33D', 16));
        case category === 'Anthropology':
            return String.fromCodePoint(parseInt('1F9EC', 16));
        case category === 'Art':
            return String.fromCodePoint(parseInt('1F3A8', 16));
        case category === 'Astronomy':
            return String.fromCodePoint(parseInt('1F52D', 16));
        case category === 'Biology':
            return String.fromCodePoint(parseInt('1F9EB', 16));
        case category === 'Business':
            return String.fromCodePoint(parseInt('1F4BC', 16));
        case category === 'Chemistry':
            return String.fromCodePoint(parseInt('1F9EA', 16));
        case category === 'Computer Science':
            return String.fromCodePoint(parseInt('1F4BB', 16));
        case category === 'Economics':
            return String.fromCodePoint(parseInt('1F4C8', 16));
        case category === 'Education':
            return String.fromCodePoint(parseInt('1F4DA', 16));
        case category === 'Engineering':
            return String.fromCodePoint(parseInt('1F3D7', 16));
        case category === 'Environmental Science':
            return String.fromCodePoint(parseInt('1F30D', 16));
        case category === 'Geography':
            return String.fromCodePoint(parseInt('1F5FA', 16));
        case category === 'History':
            return String.fromCodePoint(parseInt('1F3DB', 16));
        case category === 'Law':
            return String.fromCodePoint(parseInt('2696', 16));
        case category === 'Linguistics':
            return String.fromCodePoint(parseInt('1F520', 16));
        case category === 'Literature':
            return String.fromCodePoint(parseInt('1F4D6', 16));
        case category === 'Mathematics':
            return String.fromCodePoint(parseInt('1F9EE', 16));
        case category === 'Medicine':
            return String.fromCodePoint(parseInt('1F48A', 16));
        case category === 'Philosophy':
            return String.fromCodePoint(parseInt('1F4A1', 16));
        case category === 'Physics':
            return String.fromCodePoint(parseInt('269B', 16));
        case category === 'Political Science':
            return String.fromCodePoint(parseInt('1F5F3', 16));
        case category === 'Psychology':
            return String.fromCodePoint(parseInt('1F531', 16));
        case category === 'Public Health':
            return String.fromCodePoint(parseInt('1FA7A', 16));
        case category === 'Social Science':
            return String.fromCodePoint(parseInt('1F468', 16));
        case category === 'Sports':
            return String.fromCodePoint(parseInt('26BD', 16));
        case category === 'Technology':
            return String.fromCodePoint(parseInt('1F4F1', 16));
        case category === 'Theology':
            return String.fromCodePoint(parseInt('26EA', 16));
        case category === 'Veterinary Science':
            return String.fromCodePoint(parseInt('1F429', 16));
        case category === 'Session Tracking':
            return String.fromCodePoint(parseInt('1F4AC', 16));
        case category === 'Flow':
            return String.fromCodePoint(parseInt('1F30A', 16));
        case category === 'Ethos':
            return String.fromCodePoint(parseInt('2693', 16));
        case category === 'North Star':
            return String.fromCodePoint(parseInt('1F9ED', 16));
        case category === 'Liability':
            return String.fromCodePoint(parseInt('1F6A9', 16));
        case category === 'Sync-up':
            return String.fromCodePoint(parseInt('1F503', 16));
        case category === 'Peacekeeper':
            return String.fromCodePoint(parseInt('1F54A', 16));
        case category === 'Target':
            return String.fromCodePoint(parseInt('1F3AF', 16));
        case category === 'Perspective':
            return String.fromCodePoint(parseInt('1F4A1', 16));
        case category === 'Hint':
            return String.fromCodePoint(parseInt('2728', 16));
        case category === 'Metatags':
            return String.fromCodePoint(parseInt('1F50D', 16));
        case category === '4k tokens':
            return String.fromCodePoint(parseInt('1F4AC', 16));
        case category === 'Sensitivity':
            return String.fromCodePoint(parseInt('1FAB6', 16));
        case category === 'Display':
            return String.fromCodePoint(parseInt('1F4BB', 16));
        case category === 'Human Support':
            return String.fromCodePoint(parseInt('1F477', 16));
        case category === '2k tokens':
            return String.fromCodePoint(parseInt('1F41F', 16));
        case category === 'Follow-up':
            return String.fromCodePoint(parseInt('21AA', 16));
        case category === 'Wellness':
            return String.fromCodePoint(parseInt('1F9D8', 16));
        case category === 'Flash':
            return String.fromCodePoint(parseInt('26A1', 16));
        case category === 'Hourglass':
            return String.fromCodePoint(parseInt('231B', 16));
        case category === 'Raised Hand':
            return String.fromCodePoint(parseInt('270B', 16));
        case category === 'Thumbs Up':
            return String.fromCodePoint(parseInt('1F44D', 16));
        case category === 'Thumbs Down':
            return String.fromCodePoint(parseInt('1F44E', 16));
        case category === 'Cross':
            return String.fromCodePoint(parseInt('274C', 16));
        case category === 'Waving Hand':
            return String.fromCodePoint(parseInt('1F44B', 16));
        default:
            return String.fromCodePoint(parseInt('2753', 16));
    }
}