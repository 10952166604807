import * as Sentry from '@sentry/react';

import { appInsights } from '../../AppInsights';
import { store } from '../../store/index';
import { SpaceInfo, WorkspaceBanner } from '../../store/SpacesStore';
import { get, post, put, del } from '../../utils/network';

export class SpacesService {
    async createCopilotSpace(data: SpaceInfo) {
        try {
            return await post('spaces/create-copilot-space', data);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
            throw e;
        }
    }

    async updateSpace(data: SpaceInfo, id: string) {
        try {
            return await post(`spaces/${id}`, data);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async getSpaces() {
        try {
            return await get('spaces');
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
            console.log('get spaces error', err);
        }
    }

    async getSpacesById(id: string | number) {
        try {
            return await get(`spaces/get-by-id/${id}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async getWorkspaceDetails() {
        try {
            const workspaceDetails = await get(`spaces/wksp-details/${store.user.userData?.id}`);
            return workspaceDetails;
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async deleteSpace(id: string) {
        try {
            return await del(`spaces/${id}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async getUserPermission(spaceId: string) {
        try {
            return await get(`spaces/get-user-permission/${spaceId}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async getSpaceDetails(spaceId: string) {
        try {
            return await get(`spaces/space-details/${spaceId}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async updateWorkspaceBanner(id: number, workspaceBanner: WorkspaceBanner) {
        try {
            return await post(`spaces/workspace-banner/${id}`, workspaceBanner);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async getUserProfilePicture(id: number) {
        try {
            return await get(`spaces/profile-pic/${id}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async checkUserInvitationExists(spaceId: string) {
        try {
            return await get(`spaces/check-user-invite-exists/${spaceId}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async acceptSpaceInvite(spaceId: string) {
        try {
            return await post(`spaces/accept-space-invite/${spaceId}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async declineSpaceInvite(spaceId: string) {
        try {
            return await post(`spaces/decline-space-invite/${spaceId}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async deleteSpaceLogo(spaceId: string) {
        try {
            return await put(`spaces/delete-space-logo/${spaceId}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async getSpacesWithoutOrg() {
        try {
            return await get(`spaces/get-spaces-without-org`);
        } catch (err) {
            console.log(err);
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }

    async updateSpaceVisitedAt(spaceId: string) {
        try {
            return await put(`spaces/update-visited-at/${spaceId}`);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ exception: err });
            }
        }
    }
}
