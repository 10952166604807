import { useTranslation } from 'react-i18next';

import Cross from '../../assets/icons/WhiteCross.svg';

export type AlertProps = {
    email: string;
    onClick: () => void;
};

export const Alert = ({ email, onClick }: AlertProps) => {
    const { t } = useTranslation();
    return (
        <div className='w-screen  text-sm text-secondary bg-warning-100 flex py-6 top-0 items-center justify-center ml-16'>
            <p
                className='w-screen flex justify-center items-center text-center text-darker text-opacity-75'
                dangerouslySetInnerHTML={{
                    __html: t('signIn&Up.sentConfirmationMsg', {
                        email: `${email}`,
                    }),
                }}
            />
            <a onClick={onClick} href='#'>
                <img src={Cross} className='mr-16' />
            </a>
        </div>
    );
};
