import { useCallback } from 'react';

import { ReactComponent as AnalyticsIcon } from '../../../assets/icons/icon-analytics.svg';
import { ReactComponent as EvaIcon } from '../../../assets/icons/icon-eva.svg';
import { ReactComponent as LibraryIcon } from '../../../assets/icons/icon-library.svg';
import { ReactComponent as MembersIcon } from '../../../assets/icons/icon-members.svg';
import VerticalTabLayout from '../../spaces/home/Workspace/VeritcalTabLayout.component';

type VerticalTab = {
    title: string;
    path: string;
    exactPath?: string[];
    icon?: React.ReactElement;
    onClick?: () => void;
    tour?: string;
    disabled?: boolean;
    hidden?: boolean;
};

const DisableHeader = () => {
    const tabs = useCallback(() => {
        const tabArr: VerticalTab[] = [
            {
                title: 'EVA',
                path: `#`,
                icon: <EvaIcon className='w-3 h-3 tab_svg' />,
                disabled: true,
            },
            {
                title: 'Analytics',
                path: `#`,
                icon: <AnalyticsIcon className='w-3 h-3 tab_svg' />,
                disabled: true,
            },
            {
                title: '',
                path: `#`,
                icon: <LibraryIcon className='w-3 h-3 tab_svg' />,
                disabled: true,
            },
            {
                title: '',
                path: `#`,
                icon: <MembersIcon className='w-3 h-3 tab_svg' />,
                disabled: true,
            },
        ];
        return tabArr;
    }, []);

    return (
        <div className='h-full mx-auto space-x-6'>
            <div className='flex justify-center disabled_menu'>
                <VerticalTabLayout tabs={tabs()} />
            </div>
        </div>
    );
};

export default DisableHeader;
