import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Tooltip } from 'react-tooltip';

import { appInsights } from '../../../../../AppInsights';
import { EvaService } from '../../../../../services/eva/eva.service';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import HeatmapChart from '../Trends/HeatmapChart';
import { ReactComponent as InfoIcon } from './../../../../../assets/icons/info_Iocn.svg';
import EmptyRisk from './EmptyRisk.component';

const Risk = ({ evaId }: { evaId: string }) => {
    const { t } = useTranslation();
    const evaService = new EvaService();

    const {
        data,
        isFetching: isRiskAnalyticsFetching,
        isLoading: isRiskAnalyticsLoading,
    } = useQuery(['riskAnlaytics', evaId], async () => {
        try {
            if (!evaId) return;
            const response = await evaService.getAnalyticsRisk(evaId);
            return response;
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });

    if (isRiskAnalyticsFetching || isRiskAnalyticsLoading) {
        return <CustomLoader />;
    }

    return (
        <>
            <div className='trends_box scroll_div'>
                <Tooltip id='risk-tooltip' offset={5} place='right' className='tooltip-box show_arrow'>
                    <p> {t('risk.protocolsPolicyHeatmap')}</p>
                    <ul className='risk_tooltip'>
                        <li>{t('risk.bsi')}</li>
                        <li>{t('risk.impact')}</li>
                    </ul>
                </Tooltip>
                <div className='trends_protocol'>
                    <div className='trends_head flex justify-between items-center'>
                        <h2 className='flex items-center gap-1'>
                            {t('risk.riskSeverityHeatmap')}
                            <InfoIcon className='ml-2 cursor-pointer' data-tooltip-id='risk-tooltip' />
                        </h2>
                    </div>
                    {data && data.length > 0 ? (
                        <div className='trends_protocol_list scrollbar risk_heatmap'>
                            <HeatmapChart scatterPlotData={data} />
                        </div>
                    ) : (
                        <EmptyRisk
                            isRisk={true}
                            title={t('risk.noRiskAnalysisYet')}
                            description={t('risk.createPolicyProtocol')}
                            buttonName={t('risk.addProtocol')}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Risk;
