import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DashedCircle } from './icons/icon-dashed-circle.svg';

const useStyles = makeStyles((theme) => ({
    skeletonProtocolName: {
        fontSize: '16px',
        borderRadius: '50px',
        background: '#FFFFFF0D',
    },
    skeletonProtocolType: {
        fontSize: '16px',
        borderRadius: '3px',
        background: '#FFFFFF0D',
    },
}));

const ProtocolSkeleton = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <div className='w-full flex flex-col items-start space-y-2 mt-2'>
            <div className='w-full space-y-2'>
                <div className='flex items-center space-x-1'>
                    <div className='available-icon' />
                    <p>{t('available')}</p>
                </div>
                <div className='protocol-loading'>
                    <Skeleton variant='rectangular' className={classes.skeletonProtocolType} height={15} width={16} />
                    <Skeleton variant='rectangular' className={classes.skeletonProtocolName} height={10} width='100%' />
                </div>
            </div>
            <div className='w-full space-y-2'>
                <div className='flex items-center space-x-1'>
                    <DashedCircle className='w-2.5 h-2.5' fill='#6FBFE1' />
                    <p>{t('toUpdate')}</p>
                </div>
                <div className='protocol-loading'>
                    <Skeleton variant='rectangular' className={classes.skeletonProtocolType} height={15} width={16} />
                    <Skeleton variant='rectangular' className={classes.skeletonProtocolName} height={10} width='100%' />
                </div>
            </div>
            <div className='w-full space-y-2'>
                <div className='flex items-center space-x-1'>
                    <DashedCircle className='w-2.5 h-2.5' fill='#257CFF' />
                    <p>{t('toCreate')}</p>
                </div>
                <div className='protocol-loading'>
                    <Skeleton variant='rectangular' className={classes.skeletonProtocolType} height={15} width={16} />
                    <Skeleton variant='rectangular' className={classes.skeletonProtocolName} height={10} width='100%' />
                </div>
            </div>
        </div>
    );
};

export default ProtocolSkeleton;
