import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CreateEvaModalContextProvider } from '../../../../../contexts/CreateEvaModalContext';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { RootState } from '../../../../../Redux/store';
import { PublishedPlanet } from './publishedPlanet/publishedPlanet.component';

import './style.css';

interface PublishedEvaProps {
    setPublishedEvaExist: (value: boolean) => void;
    setDraftEvaExist: (value: boolean) => void;
    labelShow: boolean;
    setCreateEvaModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isCreateEvaModalOpen: boolean;
}

export const PublishedEva: React.FC<PublishedEvaProps> = ({
    labelShow,
    setPublishedEvaExist,
    setDraftEvaExist,
    isCreateEvaModalOpen,
    setCreateEvaModalOpen,
}) => {
    return (
        <>
            <CreateEvaModalContextProvider>
                <PublishedPlanetComponent
                    isCreateEvaModalOpen={isCreateEvaModalOpen}
                    setCreateEvaModalOpen={setCreateEvaModalOpen}
                    labelShow={labelShow}
                    setPublishedEvaExist={setPublishedEvaExist}
                    setDraftEvaExist={setDraftEvaExist}
                />
            </CreateEvaModalContextProvider>
        </>
    );
};

export const PublishedPlanetComponent: React.FC<PublishedEvaProps> = ({
    labelShow,
    setPublishedEvaExist,
    setCreateEvaModalOpen,
    setDraftEvaExist,
    isCreateEvaModalOpen,
}) => {
    const { getEvaGraphAndLog } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();

    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);

    const { data: evaData, isLoading } = useQuery(
        ['evaData', organisationId, evaId],
        () => {
            if (evaId) {
                return getEvaGraphAndLog(organisationId, evaId);
            }
        },
        {
            enabled: !!organisationId && !!evaId && !isCreateEvaModalOpen,
        },
    );

    return (
        <>
            <div className='flex w-full justify-center publish_eva'>
                <PublishedPlanet
                    isCreateEvaModalOpen={isCreateEvaModalOpen}
                    setCreateEvaModalOpen={setCreateEvaModalOpen}
                    setPublishedEvaExists={setPublishedEvaExist}
                    setDraftEvaExists={setDraftEvaExist}
                    labelShow={labelShow}
                />
            </div>
        </>
    );
};
