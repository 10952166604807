import React from 'react';

import clsx from 'clsx';

import { Select, SelectProps } from '../../basic/Select.component';
import { SelectInput, SelectInputProps } from '../../basic/SelectInput.component';

type FormSelectProps = (SelectProps<string> | SelectInputProps<string>) & {
    edit: boolean;
    addComponent?: React.ReactNode;
    errot?: boolean;
    required?: boolean;
    startIcon?: React.ReactNode;
    disabledBackground?: boolean;
    marginTop?: number;
    handleFilterChange?: () => void;
    selectedShortFilters?: {
        sortBy: string;
        order: string;
    };
    endIcon?: React.ReactNode;
    rounded?: string;
    isUseV2?: boolean;
    showSearchBar?: boolean;
};

const FormSelect: React.FC<FormSelectProps> = ({
    isUseV2 = false,
    edit,
    value,
    label,
    onChange,
    placeholder,
    className,
    disabled,
    addComponent,
    entries,
    icons,
    error,
    required,
    startIcon,
    disabledBackground,
    endIcon,
    marginTop,
    selectedShortFilters,
    handleFilterChange,
    rounded,
    getName,
    showSearchBar,
}) => {
    return (
        <div>
            <div className='flex'>
                {label && <p className='text-sm font-medium text-darker text-opacity-75 mb-2'>{label}</p>}
                {required && <span className='text-error'>*</span>}
            </div>
            {edit ? (
                <div className='relative m_select'>
                    {startIcon && (
                        <div className='absolute top-1/2 transform -translate-y-1/2 start_icon left-4'>{startIcon}</div>
                    )}
                    {isUseV2 ? (
                        <SelectInput
                            entries={entries as unknown as Array<{ label: string; value: string }>}
                            disabled={disabled}
                            className={clsx(className, !className?.includes('w-') && 'md:max-w-[290px] w-full')}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            icons={icons}
                            error={error}
                            disabledBackground={disabledBackground}
                            marginTop={marginTop}
                            selectedShortFilters={selectedShortFilters}
                            handleFilterChange={handleFilterChange}
                            rounded={rounded}
                        />
                    ) : (
                        <Select
                            entries={entries as Array<string>}
                            disabled={disabled}
                            className={clsx(className, !className?.includes('w-') && 'md:max-w-[290px] w-full')}
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            icons={icons}
                            error={error}
                            disabledBackground={disabledBackground}
                            marginTop={marginTop}
                            selectedShortFilters={selectedShortFilters}
                            handleFilterChange={handleFilterChange}
                            rounded={rounded}
                            getName={getName}
                            showSearchBar={showSearchBar}
                        />
                    )}

                    {endIcon && (
                        <div className='absolute top-1/2 transform -translate-y-1/2 end_icon right-4'>{endIcon}</div>
                    )}

                    {addComponent}
                </div>
            ) : (
                <>
                    <p className='text-normal'>{value ?? placeholder}</p>
                </>
            )}
        </div>
    );
};

export default FormSelect;
