import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import blueberryDark from '../../../src/assets/logo/logo_blankstate_beta_dark.png';
import { setRemoveArchiveEvaIdConfirmation } from '../../Redux/EvaInfo';
import { RootState } from '../../Redux/store';
import { useStore } from '../../store';

type FooterProps = {
    whiteClass?: string;
    lightTextClass?: string;
};

export const Footer = ({ whiteClass, lightTextClass }: FooterProps) => {
    const date = new Date();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const archiveEvaId = useSelector((state: RootState) => state.EvaInfo.archiveEvaId);

    const isChangeProtocolData = useSelector((state: RootState) => state.EvaInfo.isChangeProtocolData);
    const store = useStore();

    const navigateHome = () => {
        if (archiveEvaId !== null) {
            dispatch(setRemoveArchiveEvaIdConfirmation(true));
        } else {
            store.spaces.updateSelectedSpaceId('');
            store.spaces.setCurrentSelectedWorkSpaceInfo({
                workspaceBanner: '',
                workspaceIcon: '',
                workspaceName: '',
                workspaceColor: '',
                workspaceLogo: '',
            });
            history.push('/home');
        }
    };

    return (
        <div
            className={clsx(
                'flex bg-backgroundColor footer flex-row items-center relative justify-center text-xs h-14 py-5 space-x-1',
                whiteClass,
            )}
        >
            <span> ©{date.getFullYear()} blankstate.ai </span>
            <span className={clsx('flex text-neutral-400 space-x-1', lightTextClass)}>
                <p>- {t('footer.companyNo')}: 12302383</p>
                <p>|</p>
                <a
                    className='hover:text-darker hover:text-opacity-75 underline transition-all duration-75'
                    href='https://blankstate.ai/terms-conditions/'
                    target='_blank'
                >
                    {t('footer.terms')}
                </a>
                <p>|</p>
                <a
                    className='hover:text-darker hover:text-opacity-75 underline transition-all duration-75'
                    href='https://blankstate.ai/privacy-policy/'
                    target='_blank'
                >
                    {t('footer.privacy')}
                </a>
                <p>|</p>
                <a
                    className='hover:text-darker hover:text-opacity-75 underline transition-all duration-75'
                    href='https://blankstate.ai/contact-us/'
                    target='_blank'
                >
                    {t('footer.contact')}
                </a>
            </span>

            <img
                src={blueberryDark}
                alt='logo'
                className='w-24 cursor-pointer logo absolute'
                onClick={() => {
                    if (!isChangeProtocolData) {
                        navigateHome();
                    }
                }}
            />
        </div>
    );
};
