import { useEffect } from 'react';

import i18n from './i18n';

const BrowserLanguageDetector = () => {
    useEffect(() => {
        let currentLanguage = navigator.language;

        const checkLanguageChange = () => {
            const browserLanguage = navigator.language;

            if (browserLanguage !== currentLanguage) {
                currentLanguage = browserLanguage;
                i18n.changeLanguage(browserLanguage);
            }
        };

        const intervalId = setInterval(checkLanguageChange, 1000); // Check every second

        return () => clearInterval(intervalId);
    }, []);

    return null;
};

export default BrowserLanguageDetector;
