import React, { useCallback, useEffect, useState } from 'react';

import './LeftSideProtocolList.css';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as DownArrow } from '../../../../../../../../src/assets/icons/DownArrow.svg';
import { ReactComponent as SearchIcon } from '../../../../../../../../src/assets/icons/serachIocn.svg';
import { ReactComponent as SortIcon } from '../../../../../../../../src/assets/icons/SortArrow_Icon.svg';
import { appInsights } from '../../../../../../../AppInsights';
import { setHoverProtocolIndex, setProtocolIndex } from '../../../../../../../Redux/EvaInfo';
import { setClickProtocol, setNewSelectedProtocols } from '../../../../../../../Redux/SessionsProtocols';
import { RootState, useAppSelector } from '../../../../../../../Redux/store';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import { Protocol } from '../../../../evaAnalytics/components/analyticsChart/types';
import { EvaType, MetaMarkerItem, protocolListData } from '../../../types';
import { ErrorComponent } from '../../error';

interface LeftSideProtocolListProps {}

const LeftSideProtocolList: React.FC<LeftSideProtocolListProps> = () => {
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState('');
    const [sortVal, setSortVal] = useState('');
    const sortType = ['Newest First', 'Older First', 'Sort A-Z', 'Sort Z-A'];
    const [showSortList, setShowSortList] = useState(false);
    const [showSearch, setShowSearch] = useState(false);

    const publishProtocols = useAppSelector((state: RootState) => state.EvaInfo.publishProtocols);
    const draftProtocols = useSelector((state: RootState) => state.EvaInfo.draftProtocols);
    const isOpenBluePrintModal = useSelector((state: RootState) => state.EvaInfo.isOpenBluePrintModal);
    const evaId = useSelector((state: RootState) => state.EvaInfo.evaId);
    const evaService = new EvaService();

    useEffect(() => {
        if (isOpenBluePrintModal) {
            document.documentElement.id = 'edit-mode';
        } else {
            document.documentElement.id = 'ctrl';
        }
    }, [isOpenBluePrintModal]);

    const searchChange = (value: string) => {
        setSearchVal(value);
    };

    const dispatch = useDispatch();

    const protocolsData = isOpenBluePrintModal ? draftProtocols : publishProtocols;
    const evaType = isOpenBluePrintModal ? EvaType.draft : EvaType.publish;

    const { data: protocolList, isError } = useQuery(['eva-protocol-list', evaId, sortVal, evaType, searchVal], () => {
        try {
            if (evaId) {
                return evaService.getEvaProtocolList(evaId, evaType, [], sortVal, searchVal);
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });

    const [activeProtocol, setActiveProtocol] = useState<string[]>([]);
    const [isHoveringLastProtocol, setIsHoveringLastProtocol] = useState(false);

    const newProtocolsList = useSelector((state: RootState) => state.SessionsProtocols.NewProtocols);
    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);
    const ReportLoading = useSelector((state: RootState) => state.SessionsProtocols.ReportLoading);

    useEffect(() => {
        if (newProtocolsList) {
            const filteredNames = newProtocolsList.map((item: Protocol) => item?.name);
            setActiveProtocol(filteredNames);
        }
    }, [newProtocolsList]); // Use `newProtocolsList` only once as a dependency

    useEffect(() => {
        if (activeProtocol && protocolsData) {
            const filteredResults = protocolsData
                .filter((item) => activeProtocol.includes(item.metaMarker))
                .map((item) => {
                    // Check if the metaMarker exists in newProtocolsList
                    const existingProtocol = newProtocolsList?.find((obj) => obj.name === item.metaMarker);

                    return {
                        name: item.metaMarker,
                        protocolType: item.protocolType ?? 'Signal',
                        isManual: existingProtocol ? existingProtocol.isManual : true,
                    };
                });

            dispatch(setNewSelectedProtocols(filteredResults));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeProtocol, protocolsData, newProtocolsList]);

    const toggleProtocol = useCallback(
        (name: string) => {
            setActiveProtocol((prev) => {
                return prev?.includes(name) ? prev?.filter((i) => i !== name) : [...prev, name];
            });
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setActiveProtocol],
    );

    let clickTimeout: NodeJS.Timeout | null = null;
    const clickDelay = 300;

    const handleSingleClick = (getIndexNum: number) => {
        if (typeof getIndexNum === 'number') {
            dispatch(setProtocolIndex(getIndexNum));
        }
    };

    const handleDoubleClick = (getIndexNum: number, obj: protocolListData) => {
        if (!isHoveringLastProtocol && typeof getIndexNum === 'number') {
            if (!ReportLoading) {
                toggleProtocol(obj.protocolName);
                setTimeout(() => {
                    if (childScreen === 'Report' || childScreen === 'ViewHistoryReport' || childScreen === 'History') {
                        dispatch(setClickProtocol(true));
                    }
                }, 500);
            }
        }
    };

    if (isError) {
        return <ErrorComponent />;
    }

    return (
        <>
            <div className='left_side_panel w-full '>
                <div className='panel_top_bar flex justify-between items-center gap-2 w-full m-auto h-[32px]'>
                    <div
                        className={` search_box flex pr-3 gap-1 items-center relative ${
                            showSearch ? 'show_search' : ''
                        }`}
                    >
                        <div
                            className='search-icon cursor-auto flex items-center justify-center'
                            onClick={() => setShowSearch(!showSearch)}
                        >
                            <SearchIcon />
                        </div>
                        <input
                            type='text'
                            onChange={(e) => searchChange(e.target.value)}
                            value={searchVal}
                            placeholder={t('search')}
                        />
                    </div>
                    <div
                        className={`sort hovered flex relative justify-center gap-2 items-center cursor-pointer  ${
                            showSortList ? 'active' : ''
                        }`}
                        onClick={() => setShowSortList(!showSortList)}
                    >
                        <SortIcon />
                        <DownArrow />

                        {showSortList && (
                            <div
                                className={`sort_list absolute ${showSortList ? 'active' : ''}`}
                                onClick={(event) => event.stopPropagation()}
                            >
                                <div className='sort_head flex gap-4 items-center '>
                                    <SortIcon />
                                    {t('sort_by')}
                                </div>
                                <ul>
                                    {sortType.map((item) => {
                                        return (
                                            <li
                                                className={`${sortVal === item ? 'active' : ''}`}
                                                onClick={() => {
                                                    setShowSortList(false);
                                                    setSortVal(item);
                                                }}
                                            >
                                                {t(`sortOptions.${item}`)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                {protocolList?.isProtocolExist === true ? (
                    <>
                        <div className='protocol_list mt-3'>
                            <ul>
                                {protocolList?.protocols?.length > 0 ? (
                                    protocolList?.protocols?.map((obj: protocolListData, index: number) => {
                                        // const isActive = activeProtocol?.includes(obj.metaMarker);

                                        const isActive = activeProtocol?.includes(obj.protocolName);

                                        const metaMarkersData = protocolsData?.map(
                                            (item: MetaMarkerItem, index: number) => {
                                                return {
                                                    ...item,
                                                    indexNum: index, // Add the indexNum key here
                                                };
                                            },
                                        );
                                        const getIndexNum = metaMarkersData.find(
                                            (item, index) => item.metaMarker === obj?.protocolName,
                                        )?.indexNum;

                                        return (
                                            <li
                                                className={`${
                                                    isActive ? 'active_protocol text-[red]' : ''
                                                } flex  gap-1 cursor-pointer items-center `}
                                                key={index}
                                                onClick={(e) => {
                                                    if (clickTimeout) {
                                                        clearTimeout(clickTimeout);
                                                        clickTimeout = null;
                                                        if (typeof getIndexNum === 'number' && !isOpenBluePrintModal) {
                                                            handleDoubleClick(getIndexNum, obj);
                                                        }
                                                    } else {
                                                        clickTimeout = setTimeout(() => {
                                                            if (typeof getIndexNum === 'number') {
                                                                handleSingleClick(getIndexNum);
                                                            }
                                                            clickTimeout = null;
                                                        }, clickDelay);
                                                    }
                                                }}
                                                onMouseEnter={() => {
                                                    if (isOpenBluePrintModal && typeof getIndexNum === 'number') {
                                                        dispatch(setHoverProtocolIndex(getIndexNum));
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    if (isOpenBluePrintModal) {
                                                        dispatch(setHoverProtocolIndex(null));
                                                    }
                                                }}
                                            >
                                                <div className='round flex justify-center items-center'>
                                                    <div className='circle' style={{ backgroundColor: obj?.color }} />
                                                </div>
                                                <div className='flex justify-between items-center w-full'>
                                                    <span className='flex'> {obj?.protocolName} </span>
                                                    <div className='flex gap-1 items-center'>
                                                        {isOpenBluePrintModal && (
                                                            <div className='count flex justify-center items-center'>
                                                                {' '}
                                                                {obj.triggerNumber}{' '}
                                                            </div>
                                                        )}

                                                        <div className='count flex justify-center items-center'>
                                                            {' '}
                                                            {obj.triggerNumber}{' '}
                                                        </div>
                                                    </div>
                                                </div>

                                                {isOpenBluePrintModal && (
                                                    <div className='switch_btn active'>
                                                        <div className='switch_round' />
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })
                                ) : (
                                    <>
                                        {protocolList?.protocols?.length === 0 && (
                                            <p className='no_data'>{t('no_protocol_found')} </p>
                                        )}
                                    </>
                                )}
                            </ul>
                        </div>
                    </>
                ) : (
                    <>
                        {((protocolList?.protocols?.length === 0 && protocolList?.isProtocolExist === false) ||
                            !evaId) && (
                            <div className='flex no_protocol gap-5 flex-col w-full h-full justify-center items-center'>
                                <div className='img_box' />
                                <div>
                                    <>
                                        <h6>{t('protocolDetail.no_protocols')}</h6>
                                        <p>{t('protocolDetail.createFirstProtocol')}</p>
                                    </>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default LeftSideProtocolList;
