import { useEffect, useState } from 'react';

import { ClickAwayListener } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import searchIcon from '../../../../src/assets/icons/serachIocn.svg';
import { appInsights } from '../../../AppInsights';
import { ReactComponent as CheckIcon } from '../../../assets/icons/icon-check-filled.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/icon-clock.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-cross-fill.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/icon-delete-fill.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/icon-edit-fill.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/icon-more2.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/icon-plus.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { store } from '../../../store';
import { Member } from '../../../store/OrganisationStore';
import { ROLES, SIZES, getRolesByUserPermission } from '../../../utils/constants';
import { getDocumentUrl } from '../../../utils/getDocumentUrl';
import { Button } from '../../basic/Button.component';
import { Dialog } from '../../basic/Dialog.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { ProfilePicture } from '../../basic/ProfilePicture';
import { Select } from '../../basic/Select.component';
import { ColumnConfig, Sort, Table } from '../../manage/Table.component';
import TableSkeleton from '../../skeleton/TableSkeleton.component';
import './style.css';
import SubHeader from './eva/components/SubHeader';

const MAX_MEMBER_DISPLAYED = 16;

// Check if the user has the permission to edit the member
const isEditable = (userPermit: string, role: string) => {
    if (userPermit === ROLES.Global) {
        // Global role can edit anyone
        return true;
    } else if (userPermit === ROLES.Steward) {
        // Steward role can only edit users with the role of architect or member
        return role === ROLES.Architect || role === ROLES.Member;
    } else {
        // Other roles cannot edit
        return false;
    }
};

const Members = ({ tier }: { tier?: string }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [selection, setSelection] = useState<Member[]>([]);
    const [membersData, setMembersData] = useState<Member[]>();
    const [showPopUp, setShowPopUp] = useState(false);
    const [selectedMember, setSelectedMember] = useState<Member | undefined>();
    const [userPermit, setUserPermit] = useState('');
    const [input, setInput] = useState('');
    const {
        getMembers,
        deleteMember,
        error,
        setError,
        isLoading,
        setIsLoading,
        getUserPermission,
        updateMember,
        removeInvitedMember,
    } = useOrganisationForm();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    const { id: organisationId } = useParams<{ id: string }>();
    const pageCount = membersData ? Math.ceil(membersData.length / MAX_MEMBER_DISPLAYED) : 0;
    const [showDialog, setShowDialog] = useState(false);
    const [currentSort, setCurrentSort] = useState<Sort | undefined>();
    const [editMember, setEditMember] = useState(false);
    const [role, setRole] = useState<string | null>(null);
    const open = () => {
        setError(null);
        setShowDialog(true);
    };
    const close = () => setShowDialog(false);
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    const updateMemberRole = async (member: number, role: string) => {
        await updateMember(organisationId, member, role);
    };

    const handleRemoveInvitedMember = async (email: string) => {
        try {
            await removeInvitedMember(email, organisationId);
            membersData?.splice(membersData.indexOf(selectedMember as Member), 1);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ error: err });
            }
            console.log(err);
            setIsLoading(false);
        }
    };

    const columnsConfigMembers = getColumnsConfigMembers(
        setShowPopUp,
        showPopUp,
        selectedMember,
        setSelectedMember,
        userPermit,
        isMobile,
        open,
        editMember,
        setEditMember,
        role,
        setRole,
        updateMemberRole,
        handleRemoveInvitedMember,
        t,
        tier,
    );

    useEffect(() => {
        if (organisationId) {
            (async () => {
                const data: Member[] = await getMembers(organisationId);
                setMembersData(data);
                store.organisation.setOrganisationMembers(data);
                const userPermit = await getUserPermission(organisationId);
                setUserPermit(userPermit);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId]);

    const handleConfirm = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await deleteMember(organisationId, selectedMember?.id as number);
            setTimeout(async () => {
                if (response) {
                    membersData?.splice(membersData.indexOf(selectedMember as Member), 1);
                    close();
                }
                setIsLoading(false);
            }, 2000);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ error: err });
            }
            console.log(err);
            setIsLoading(false);
        }
    };

    const handleSort = (columnId: string) => {
        const newSort: Sort =
            currentSort && currentSort[0] === columnId
                ? [columnId, currentSort[1] === 'ASC' ? 'DESC' : 'ASC']
                : [columnId, 'ASC'];
        setCurrentSort(newSort);
        if (membersData) {
            const sortedRows = [...membersData].sort((a, b) => {
                //@ts-ignore
                const valueA = a[columnId];
                //@ts-ignore
                const valueB = b[columnId];

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return newSort[1] === 'ASC' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else {
                    return newSort[1] === 'ASC' ? valueA - valueB : valueB - valueA;
                }
            });
            setMembersData(sortedRows);
        }
    };

    return (
        <>
            <Tooltip id='add-member' offset={5} place='bottom' className='tooltip-box'>
                <p>
                    <p>{t('members.addMember')}</p>
                </p>
            </Tooltip>
            <SubHeader labelName={'Member'} />

            <div className='h-full w-full max-w-5xl mx-auto'>
                {showDialog && (
                    <Dialog
                        handleConfirm={handleConfirm}
                        title={t('members.removeMember')}
                        close={close}
                        open={open}
                        loading={isLoading}
                        content={
                            <div className='text-darker text-opacity-75 space-y-6'>
                                <p className='text-sm mb-4'>
                                    {t('members.warningMsg')}
                                    <br />
                                    {t('members.confirmMsg')}
                                </p>
                            </div>
                        }
                        confirmText={t('btnNames.delete')}
                        error={error}
                    />
                )}
                <div className='flex flex-row items-center md:justify-between md:mt-4 mb-6 md:mb-8 md:space-x-56'>
                    <div className='w-full flex items-center justify-center position-relative'>
                        <label className='w-full text-center text-sm text-darker text-opacity-75'>
                            {t('members.title')}
                        </label>

                        {(userPermit === ROLES.Global || userPermit === ROLES.Steward) && (
                            <div className='flex items-center space-x-2 ml-auto position-absolute-right'>
                                {/* <Button size='rectSmall' className='h-10' variant='access'>Manage Access</Button> */}
                                <Link to={`/organisation/${organisationId}/add-member`}>
                                    <Button
                                        variant='blueRYB'
                                        size='rectSmall'
                                        className='h-10'
                                        data-tooltip-id='add-member'
                                    >
                                        <div className='flex items-center space-x-2 font-medium whitespace-nowrap'>
                                            <PlusIcon width={10} height={10} fill='white' />
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>

                <div className='member_search'>
                    <img src={searchIcon} alt='' />

                    <input type='text' placeholder={t('search')} onChange={(e) => setInput(e.target.value)} />
                </div>

                {!membersData ? (
                    <TableSkeleton rows={5} />
                ) : (
                    <div className='overflow-x-auto md:overflow-visible'>
                        <Table
                            rows={
                                input !== ''
                                    ? membersData.filter(
                                          (member: Member) =>
                                              member.name.toLocaleLowerCase().includes(input.toLocaleLowerCase()) ||
                                              member.email.toLocaleLowerCase().includes(input.toLocaleLowerCase()),
                                      )
                                    : membersData.length > 0
                                    ? membersData.slice(MAX_MEMBER_DISPLAYED * (page - 1), MAX_MEMBER_DISPLAYED * page)
                                    : []
                            }
                            columns={columnsConfigMembers}
                            getKey={getClientId}
                            pageCount={pageCount}
                            page={page}
                            setPage={setPage}
                            selection={selection}
                            onSelectionChange={setSelection}
                            totalItems={membersData.length}
                            onClickCheckbox={(row, e) => e.stopPropagation()}
                            selectable={false}
                            maxRowsPerPage={MAX_MEMBER_DISPLAYED}
                            handleSort={handleSort}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default observer(Members);

export const getClientId = (mem: Member) => {
    return mem.id;
};

export const getColumnsConfigMembers = (
    setShowPopUp: (value: boolean) => void,
    showPopUp: boolean,
    selectedRow: Member | undefined,
    setSelectedRow: (value: Member) => void,
    userPermit: string,
    isMobile: boolean,
    open: () => void,
    editMember: boolean,
    setEditMember: (value: boolean) => void,
    role: string | null,
    setRole: (value: string) => void,
    updateMemberRole: (member: number, role: string) => void,
    removeInvitedMember: (email: string) => void,
    t: TFunction,
    tier?: string,
): ColumnConfig<Member>[] => [
    {
        id: 'name',
        header: t('members.table.name'),
        renderCell: ({ row }) => (
            <div className='flex flex-row items-center py-1 space-x-4 text-sm text-darker text-opacity-75 font-medium whitespace-nowrap'>
                <ProfilePicture
                    path={row.documents.length > 0 ? getDocumentUrl(row.documents[0].id) : ''}
                    size='24px'
                />
                <p>{row.name}</p>
            </div>
        ),
        sortable: true,
    },
    {
        id: 'email',
        header: t('members.table.email'),
        renderCell: ({ row }) => <p className='text-sm text-darker text-opacity-75 whitespace-nowrap'>{row.email}</p>,
        sortable: true,
    },
    {
        id: 'role',
        header: t('members.table.role'),
        renderCell: ({ row }) => (
            <div>
                {row.role === ROLES.Invited ? (
                    <div className='flex space-x-2 items-center'>
                        <ClockIcon className='w-3 h-3' />
                        <p className='member-invited'>{t('members.roles.Invited')}</p>
                    </div>
                ) : editMember && selectedRow === row ? (
                    <Select
                        className='w-32'
                        entries={getRolesByUserPermission(userPermit, tier)}
                        value={row.role ? row.role : ROLES.Global}
                        onChange={(e) => {
                            setRole(row.role);
                            row.role = e;
                        }}
                        height='h-6'
                        rounded={'rounded-lg'}
                        marginTop={30}
                        getName={(role) => t(`members.roles.${role}`)}
                    />
                ) : (
                    <p className='text-sm text-darker text-opacity-75 whitespace-nowrap'>
                        {row.role ? t(`members.roles.${row.role}`) : t(`members.roles.Global Admin`)}
                    </p>
                )}
            </div>
        ),
        sortable: true,
    },
    {
        id: 'EVA',
        header: t('members.table.eva'),
        renderCell: ({ row }) => (
            <div className='group flex items-center space-x-2'>
                <div
                    className={clsx(
                        'flex w-2 h-2 rounded-full items-center',
                        row.eva ? 'eva-user-enabled' : 'eva-user-disabled',
                    )}
                />
                <div className='opacity-0 group-hover:opacity-100 text-xs font-normal text-darker text-opacity-75'>
                    {row.eva ? t('members.table.enabled') : t('members.table.disabled')}
                </div>
            </div>
        ),
        sortable: true,
    },

    {
        id: 'update',
        header: '',
        renderCell: ({ row }) => {
            return (
                <div
                    className='absolute'
                    onClick={() => {
                        setShowPopUp(!showPopUp);
                        setSelectedRow(row);
                    }}
                >
                    {row.role == ROLES.Invited ? (
                        <button
                            className='flex items-center justify-center opacity-0 group-hover:opacity-100'
                            onClick={() => removeInvitedMember(row.email)}
                        >
                            <CloseIcon className='w-3 h-3 cursor-pointer' fill='#0F1044' fillOpacity={0.5} />
                        </button>
                    ) : editMember && selectedRow === row ? (
                        <div className='flex items-center justify-center space-x-4'>
                            <div
                                className='flex w-6 h-6 justify-center bg-white items-center border rounded-md border-blue-dark border-opacity-10 cursor-pointer'
                                onClick={() => {
                                    updateMemberRole(row.id, row.role as string);
                                    setEditMember(false);
                                }}
                            >
                                <CheckIcon className='w-3 h-3 cursor-pointer' fill='#5A44FF' />
                            </div>
                            <div
                                className='flex w-6 h-6 justify-center bg-white items-center border rounded-md border-blue-dark border-opacity-10 cursor-pointer'
                                onClick={() => {
                                    if (role) row.role = role;
                                    setEditMember(false);
                                }}
                            >
                                <CloseIcon className='w-2.5 h-2.5 cursor-pointer' fill='#0F1044' fillOpacity={0.5} />
                            </div>
                        </div>
                    ) : (
                        isEditable(userPermit, row.role) &&
                        row.id !== row.createdById &&
                        row.id !== store.user.userData?.id && (
                            <>
                                {!isMobile && <MoreIcon className='w-3 h-3 cursor-pointer' fill='#0F104480' />}

                                {showPopUp && selectedRow === row && (
                                    <ClickAwayListener onClickAway={() => setShowPopUp(false)}>
                                        <div className='absolute bg-white w-60 py-2 shadow-message-container rounded-xl left-3 top-1'>
                                            <button
                                                className='text-sm py-2 px-6 space-x-4 w-full flex items-center h-8 hover:bg-primary-darker hover:bg-opacity-3'
                                                onClick={() => {
                                                    setEditMember(true);
                                                }}
                                            >
                                                <EditIcon /> <p>{t('btnNames.edit')} </p>
                                            </button>
                                            <HorizontalSeparator className='my-2' />
                                            <button
                                                className='text-sm py-2 px-6 space-x-4 w-full flex items-center h-8 hover:bg-primary-darker hover:bg-opacity-3'
                                                onClick={open}
                                            >
                                                <DeleteIcon className='w-3 h-3' /> <p>{t('btnNames.remove')}</p>
                                            </button>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </>
                        )
                    )}
                </div>
            );
        },
        sortable: false,
    },
];
