import { Dispatch, SetStateAction, useState } from 'react';

import ActiveEvaSelect from '../../evaAnalytics/risk/ActiveEvaSelect.component';
import { EvaGraphAnalyticsEvaList } from '../types';

interface SubHeaderProps {
    labelName?: string;
    component?: JSX.Element | undefined;
}

const SubHeader: React.FC<SubHeaderProps> = ({ labelName, component }) => {
    if (!labelName && !component) {
        return <div className='sub_header_empty' />;
    }

    return (
        <div className='sub_header'>
            <span className='left_round' />
            <span className='right_round' />

            {component
                ? component
                : labelName && (
                      <div className='sub_header_content'>
                          <h6> {labelName} </h6>
                      </div>
                  )}
        </div>
    );
};

export default SubHeader;
