import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setProtocolIndex } from '../../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../../Redux/store';
import { protocolTypeIcon } from '../ProtocolTypeList';
import { ReactComponent as DashedCircle } from './icons/icon-dashed-circle.svg';
import { ReactComponent as RecommendedIcon } from './icons/icon-recommended.svg';
import { ReactComponent as RequiredIcon } from './icons/icon-required.svg';
import { MetricProtocolType, ProtocolRequirementLevel } from './metricsType';

const Protocol = ({
    protocolStatus,
    protocolArray,
    handleClick,
    handleDlClick,
}: {
    protocolStatus: 'available' | 'update' | 'create';
    protocolArray: MetricProtocolType[];
    handleClick: (action: string, protocolName: string) => void;
    handleDlClick: (event: React.MouseEvent<HTMLElement>, index?: number | null) => void;
}) => {
    const { t } = useTranslation();

    const protocolIndex = useSelector((state: RootState) => state.EvaInfo.protocolIndex);

    const dispatch = useDispatch();
    return (
        <div className='w-full flex flex-col items-start space-y-2 mt-2'>
            <div className='w-full space-y-2'>
                {protocolStatus === 'available' ? (
                    <div className='flex items-center space-x-1'>
                        <div className='available-icon' />
                        <p>{t('available')}</p>
                    </div>
                ) : protocolStatus === 'update' ? (
                    <div className='flex items-center space-x-1'>
                        <DashedCircle className='w-2.5 h-2.5' fill='#6FBFE1' />
                        <p>{t('toUpdate')}</p>
                    </div>
                ) : (
                    <div className='flex items-center space-x-1'>
                        <DashedCircle className='w-2.5 h-2.5' fill='#257CFF' />
                        <p>{t('toCreate')}</p>
                    </div>
                )}
                {protocolArray.map((protocol, index) => (
                    <button
                        className={clsx(
                            'protocol',
                            protocol.protocolIndex - 1 === protocolIndex ? 'protocol-selected' : '',
                        )}
                        key={index}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDlClick(e, protocol.protocolIndex - 1);
                            dispatch(setProtocolIndex(protocol.protocolIndex - 1));
                            //to call generate metric protocol
                            if (protocolStatus !== 'available') handleClick(protocolStatus, protocol.protocolName);
                        }}
                    >
                        <div className='flex w-full ml-2 items-center space-x-1'>
                            <div className='mt-1'>
                                <img src={protocolTypeIcon(protocol.protocolType)} className='w-5 h-5' alt='hint' />
                            </div>
                            <p>{protocol.protocolName}</p>
                        </div>
                        {protocol.requirementLevel === ProtocolRequirementLevel.Suggested ||
                        protocol.requirementLevel === ProtocolRequirementLevel.Recommended ? (
                            <button className='flex req-level' data-title={t('recommended')}>
                                <RecommendedIcon className='w-3 h-3' />
                            </button>
                        ) : (
                            <button className='flex req-level' data-title={t('required')}>
                                <RequiredIcon className='w-3 h-3' />
                            </button>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Protocol;
