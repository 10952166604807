import { useEffect, useState } from "react";

import { Modal } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

import ModalImage from '../../assets/images/modal.png';
import ExtensionButton from "./ExtensionButton.component";

const ExtensionModel = () => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(JSON.parse(localStorage.getItem('first-visit') || 'false'));
    const bberryExtension = JSON.parse(localStorage.getItem('bberry-extension') || 'false');

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        localStorage.setItem('first-visit', 'false');
    }, []);

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            style={{
                width: '50%',
                height: '50%',
                margin: 'auto',
                border: 'none',
                borderRadius: 15,
                outline: 'none',
            }}
        >
        <div className='flex h-full'>
            <div className='bg-blue flex items-center justify-center rounded-l-lg w-1/2'>
                <img
                    src={ModalImage}
                    style={{
                        width: '70%',
                        height: '80%',
                    }}
                />
            </div>
            <div className='bg-white rounded-r-lg w-1/2'>
                <div className='relative'>
                    <button className='absolute right-4 top-4' onClick={handleCloseModal}>
                        <CloseIcon style={{ color: '#0F104459' }} />
                    </button>
                </div>
                <div className='h-full flex flex-col justify-center mx-10 space-y-4'>
                    <div>
                        <p className="font-medium text-darker text-opacity-75">{t('extensionDialog')}</p>
                    </div>
                    {!bberryExtension && 
                    <div className="flex w-full mx-auto">
                        <ExtensionButton />
                    </div>}
                </div>
            </div>
        </div>
    </Modal>
    )
}

export default ExtensionModel