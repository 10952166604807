import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CrossIcon } from '../../../assets/icons/icon-cross.svg';
import { Button } from '../../basic/Button.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { Input } from '../../basic/Input.component';

export type DeleteDialogProps = {
    title?: string;
    description?: string;
    warningMessage?: string;
    close: () => void;
    open: () => void;
    handleConfirm?: () => void;
};

const DeleteDialog = ({ title, description, close, handleConfirm }: DeleteDialogProps) => {
    const { t } = useTranslation();
    const [deleteInput, setDeleteInput] = useState<string>('');

    return (
        <div
            className='fixed inset-0 z-50 flex items-center justify-center'
            style={{ backgroundColor: 'rgba(22, 22, 49, 0.6)' }}
        >
            <div className='bg-white p-6 rounded-2xl w-full max-w-2xl'>
                <div className='flex justify-between items-center mb-4'>
                    <h1 className='text-tiny font-medium text-darker text-opacity-75'>
                        {t('btnNames.delete')} {title}
                    </h1>
                    <button onClick={close}>
                        <CrossIcon width={12} height={12} fill='#0F104459' />
                    </button>
                </div>
                <HorizontalSeparator className='mb-6' />
                <p className='text-sm mb-2 text-darker text-opacity-75'>{description}</p>
                <div className='bg-notification-100 rounded-md h-full w-full p-2 mb-5 max-w-lg flex space-x-2'>
                    <div className='bg-warning-900 rounded-full w-4 h-4 px-2 flex items-center justify-center'>
                        <p className='text-white text-xs'>!</p>
                    </div>
                    <div className='text-xs font-medium text-darker text-opacity-75'>
                        <p>{t('spaces.deleteWarning1')}</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: t('spaces.deleteWarning2'),
                            }}
                        />
                    </div>
                </div>
                <p className='text-sm mb-4 text-darker text-opacity-75'>
                    {t('spaces.deleteConfirm')}
                    <span className='font-medium'> {title} </span> ?
                </p>
                <HorizontalSeparator className='mb-4' />
                <p className='text-sm mb-6 text-darker text-opacity-75'>
                    {t('spaces.write')} "<span className='font-semibold text-error'>Delete</span>"{' '}
                    {t('spaces.fieldMsg')}
                </p>
                <Input value={deleteInput} onChange={(e) => setDeleteInput(e)} className='mb-6' />

                <div className='flex items-center justify-end space-x-3'>
                    <Button onClick={close} variant='outlinedCancel' size='oval'>
                        {t('btnNames.cancel')}
                    </Button>
                    <Button onClick={handleConfirm} variant='rejectRed' size='oval' disabled={deleteInput !== 'Delete'}>
                        {t('spaces.delete')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteDialog;
