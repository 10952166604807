import * as Sentry from '@sentry/react';

import { appInsights } from '../../AppInsights';
import { OnboaringTour, UpdateUserDto } from '../../store/UserStore';
import { del, get, patch, post, put } from '../../utils/network';

export class UsersService {
    async getUserInfo(userId: number) {
        try {
            return await get(`/users?id=${userId}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async updateUser(data: { userId: number; [key: string]: unknown }) {
        try {
            return await patch(`/users/update-user/${data.userId}`, { ...data });
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async exists(email: string) {
        try {
            return await post('/users/is-user', { email });
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async reverseDelete() {
        try {
            return await post('/users/reverse-delete-account');
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async update(updateUserDto: UpdateUserDto) {
        try {
            return await put('/users/update', { updateUserDto });
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async deleteAccount() {
        try {
            return await del('/users/delete-account');
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async isUserInvited(email: string) {
        try {
            return post(`users/is-invited/${email}`);
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async updateUserType(status: string) {
        try {
            return await post('/users/update-user-type', { status });
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async createOrUpdateUserEmail(emails: string[]) {
        try {
            return await post('/users/update-user-email', { emails });
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async deleteUserEmail(email: string) {
        try {
            return await put('/users/delete-user-email', { email });
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async updateOnboardingStep(status: boolean, name: keyof OnboaringTour) {
        try {
            return await put('/users/update-onboarding-step', { status, name });
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }

    async skipOnboardingTour() {
        try {
            return await put('/users/skip-tour');
        } catch (e) {
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ exception: e });
            }
        }
    }
}
