import { useEffect, useState, useCallback } from 'react';

import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { stripePromise } from '../..';
import { EvaProtectedRoute } from '../../App';
import { appInsights } from '../../AppInsights';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/icon-analytics.svg';
import { ReactComponent as EvaIcon } from '../../assets/icons/icon-eva.svg';
import { ReactComponent as LibraryIcon } from '../../assets/icons/icon-library.svg';
import { ReactComponent as MembersIcon } from '../../assets/icons/icon-members.svg';
import EditPaymentMethod from '../../components/admin/paymentMethods/EditPaymentMethod';
import { CustomLoader } from '../../components/basic/CustomeLoader/CustomLoader';
import AddMember from '../../components/organisation/copilotOrganisation/AddMember.component';
import Billing from '../../components/organisation/copilotOrganisation/billing/Billing.component';
import Eva from '../../components/organisation/copilotOrganisation/eva/Eva.component';
import { EvaType, SubScriptionType } from '../../components/organisation/copilotOrganisation/eva/types';
import AnalyticsPage from '../../components/organisation/copilotOrganisation/evaAnalytics/Analytics.page';
import Library from '../../components/organisation/copilotOrganisation/Library.component';
import Members from '../../components/organisation/copilotOrganisation/Members.component';
import Settings from '../../components/organisation/copilotOrganisation/Settings.component';
import ConfirmOrganisationPlan from '../../components/organisation/createOrganisation/ConfirmOrganisationPlan';
import OrgainisationTier from '../../components/organisation/createOrganisation/OrgainisationTier';
import AddPayment from '../../components/plans/AddPayment.component';
import { useServices } from '../../components/ServiceProvider';
import VerticalTabLayout from '../../components/spaces/home/Workspace/VeritcalTabLayout.component';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useOrganisationForm } from '../../hooks/useOrganisation';
import { store } from '../../store';
import { SUBSCRIPTION_STATUS } from '../../store/OrganisationStore';
import { AickoCurrentSubscription } from '../../store/SubscriptionsStore';
import { getRolesByUserPermission, ROLES, SIZES } from '../../utils/constants';
import NotFound from '../NotFound.page';

type VerticalTab = {
    title: string;
    path: string;
    exactPath?: string[];
    icon?: React.ReactElement;
    onClick?: () => void;
    tour?: string;
    disabled?: boolean;
    hidden?: boolean;
};

const Organisation = () => {
    const { path } = useRouteMatch();
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    const location = useLocation();
    const history = useHistory();
    const { getEvaByOrganisationId, getUserPermission } = useOrganisationForm();
    const [organisationId, setOrganisationId] = useState<string | null>(location.pathname.split('/')[2]);
    const [activeSubscription, setActiveSubscription] = useState<boolean>(false);
    const { organisation } = useServices();

    const {
        data: evaData,
        isLoading,
        refetch,
    } = useQuery(['getEvaByOrganisationId', organisationId, EvaType.publish], () => {
        try {
            return getEvaByOrganisationId(organisationId as string, EvaType.publish);
        } catch (e) {
            history.replace('/home');
            console.error(e, 'error');
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
                Sentry.captureException(e);
            }
        }
    });

    const { data: userPermit, isLoading: userPermitLoading } = useQuery(['getUserPermission', organisationId], () => {
        try {
            return getUserPermission(organisationId as string);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
                Sentry.captureException(e);
            }
        }
    });

    const {
        data: subscription,
        isLoading: subscriptionLoading,
        refetch: refetchSubscription,
    } = useQuery<AickoCurrentSubscription | null>(
        ['currentSubscription', organisationId],
        async (): Promise<AickoCurrentSubscription | null> => {
            try {
                if (organisationId) {
                    const response: AickoCurrentSubscription = await organisation.getSubscription(organisationId);
                    if (response.status === SUBSCRIPTION_STATUS.CANCELED) {
                        setActiveSubscription(false);
                    } else {
                        setActiveSubscription(true);
                    }
                    return response; // Ensure to return the response
                }
                return null;
            } catch (e) {
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                }
                return null; // Ensure to return null in case of error
            }
        },
        {
            enabled: !!organisationId, // Ensure the query only runs when organisationId is defined
        },
    );

    const handleSubscriptionRefetch = async () => {
        await refetchSubscription();
    };

    const tabs = useCallback(() => {
        const commonProps = {
            path: `${path}/${organisationId}`,
            disabled: subscription && subscription.status === SUBSCRIPTION_STATUS.CANCELED ? true : false,
        };

        const tabArr: VerticalTab[] = [
            {
                title: 'EVA',
                path: `${commonProps.path}/eva`,
                exactPath: [`${commonProps.path}/eva`],
                icon: <EvaIcon className='w-3 h-3 tab_svg' />,
                disabled: commonProps.disabled,
                hidden: userPermit === ROLES.Treasurer,
            },
            {
                title: 'Analytics',
                path: `${commonProps.path}/analytics`,
                exactPath: [
                    `${commonProps.path}/analytics`,
                    `${commonProps.path}/analytics/Trends`,
                    `${commonProps.path}/analytics/risk`,
                    `${commonProps.path}/analytics/navi`,
                ],
                icon: <AnalyticsIcon className='w-3 h-3 tab_svg' />,
                disabled: commonProps.disabled,
                hidden: userPermit === ROLES.Member || userPermit === ROLES.Treasurer,
            },
            {
                title: '',
                path: `${commonProps.path}/blueprints`,
                exactPath: [`${commonProps.path}/blueprints`],
                icon: <LibraryIcon className='w-3 h-3 tab_svg' />,
                disabled: commonProps.disabled || subscription?.productName === SubScriptionType.SHIELD,
                hidden: userPermit === ROLES.Treasurer || userPermit === ROLES.Member,
            },
            {
                title: '',
                path: `${commonProps.path}/members`,
                exactPath: [
                    `${commonProps.path}/members`,
                    `${commonProps.path}/add-member`,
                    `${commonProps.path}/edit-member/:memberId`,
                ],
                icon: <MembersIcon className='w-3 h-3 tab_svg' />,
                disabled: commonProps.disabled,
                hidden: userPermit === ROLES.Treasurer || userPermit === ROLES.Architect,
            },
        ];
        return tabArr;
    }, [path, organisationId, subscription, userPermit]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.organisation.publishedEVAExist]);

    useEffect(() => {
        if (!organisationId) return;
        (async () => {
            try {
                const data = await organisation.getOrganisation(organisationId);
                if (data) {
                    const workspaceInfo = {
                        workspaceBanner: '',
                        workspaceIcon: data.organisationName[0],
                        workspaceName: data.organisationName,
                        workspaceColor: '',
                        workspaceLogo: data.logo ? data.logo : '',
                        isOrganisation: true,
                    };
                    store.spaces.setCurrentSelectedWorkSpaceInfo(workspaceInfo);
                }
            } catch (e) {
                history.replace('/home');
                console.error(e);
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                    Sentry.captureException(e);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const storedOrganisationId = localStorage.getItem('spaceId');
        if (storedOrganisationId) setOrganisationId(storedOrganisationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('spaceId')]);

    const hideVerticalTabs = () => {
        if (
            location.pathname.includes('billing') ||
            location.pathname.includes('add-payment') ||
            location.pathname.includes('edit-payment') ||
            location.pathname.includes('settings') ||
            location.pathname.includes('change-tier') ||
            location.pathname.includes('confirm-plan')
        )
            return true;
    };

    if (isLoading || userPermitLoading || subscriptionLoading) {
        return <CustomLoader />;
    }

    return (
        <div className='h-full mx-auto space-x-6'>
            <Elements stripe={stripePromise}>
                {!hideVerticalTabs() && !isMobile && (
                    <div className='flex justify-center'>
                        <VerticalTabLayout tabs={tabs()} />
                    </div>
                )}
                <div
                    className={clsx(
                        `${isMobile ? 'block  p-2' : 'flex '}  relative`,
                        !hideVerticalTabs() && 'main_eva_component flex-col justify-start',
                    )}
                >
                    <Switch>
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id`, `${path}/:id/eva`]}
                            component={Eva}
                            subscription={activeSubscription}
                            isUserAdmin={userPermit !== ROLES.Treasurer}
                            redirectPath={activeSubscription ? `${path}/${organisationId}/billing` : undefined}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/members`]}
                            component={() => <Members tier={subscription?.productName} />}
                            subscription={activeSubscription}
                            isUserAdmin={userPermit !== ROLES.Treasurer && userPermit !== ROLES.Architect}
                            redirectPath={activeSubscription ? `${path}/${organisationId}/billing` : undefined}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/add-member`]}
                            component={() => (
                                <AddMember
                                    mode='create'
                                    roles={getRolesByUserPermission(userPermit, subscription?.productName)}
                                />
                            )}
                            subscription={activeSubscription}
                            isUserAdmin={userPermit === ROLES.Global || userPermit === ROLES.Steward}
                            redirectPath={activeSubscription ? `${path}/${organisationId}/billing` : undefined}
                        />
                        <EvaProtectedRoute
                            path={[`${path}/:id/analytics`]}
                            component={AnalyticsPage}
                            subscription={activeSubscription}
                            isUserAdmin={
                                userPermit === ROLES.Global ||
                                userPermit === ROLES.Steward ||
                                userPermit === ROLES.Architect
                            }
                            redirectPath={activeSubscription ? `${path}/${organisationId}/billing` : undefined}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/blueprints`]}
                            component={Library}
                            subscription={activeSubscription}
                            isUserAdmin={
                                userPermit === ROLES.Global ||
                                userPermit === ROLES.Steward ||
                                userPermit === ROLES.Architect
                            }
                            redirectPath={activeSubscription ? `${path}/${organisationId}/billing` : undefined}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/billing`]}
                            component={Billing}
                            subscription={true}
                            isUserAdmin={userPermit !== ROLES.Member && userPermit !== ROLES.Architect}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/add-payment`]}
                            component={AddPayment}
                            subscription={true}
                            isUserAdmin={userPermit !== ROLES.Member && userPermit !== ROLES.Architect}
                        />
                        <EvaProtectedRoute
                            exact
                            path={`${path}/:id/edit-payment/:cardId`}
                            component={EditPaymentMethod}
                            subscription={true}
                            isUserAdmin={userPermit !== ROLES.Member && userPermit !== ROLES.Architect}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/settings`]}
                            component={Settings}
                            subscription={true}
                            isUserAdmin={userPermit !== ROLES.Member && userPermit !== ROLES.Treasurer}
                            redirectPath={activeSubscription ? `${path}/${organisationId}/billing` : undefined}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/change-tier`]}
                            component={() => <OrgainisationTier subscriptionExist />}
                            subscription={true}
                            isUserAdmin={userPermit !== ROLES.Member && userPermit !== ROLES.Treasurer}
                        />
                        <EvaProtectedRoute
                            exact
                            path={[`${path}/:id/:type/confirm-plan`]}
                            component={() => (
                                <ConfirmOrganisationPlan
                                    upgrade
                                    goTo={`/organisation/${organisationId}/add-payment`}
                                    refetchSubscription={handleSubscriptionRefetch}
                                />
                            )}
                            subscription={true}
                            isUserAdmin={userPermit !== ROLES.Member && userPermit !== ROLES.Treasurer}
                        />
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </Elements>
        </div>
    );
};

export default observer(Organisation);
