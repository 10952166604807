import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the interface for the line chart data
interface LineChart {
    name: string;
    id: string;
    data: number[];
    dateRange: string[];
    category: string;
    format: string;
}

// Define the interface for the selected graph data
interface SelectedGraphData {
    value?: number | null; // Value can be a number or null
    drilledDate: string | null; // Time is a string (could be null initially)
}

interface SelectedMetricGraphData {
    metricValue?: number | null;
    metricDrilledDate: string | null;
}
// Define the interface for the protocol state
interface ProtocolState {
    selectedProtocols: string[]; // List of selected protocols
    selectedIds: string[]; // List of selected IDs
    selectedMetricIds: string[]; // List of selected metric IDs
    chartData: LineChart[]; // List of line chart data
    selectMetricProtocol: string[]; // List of selected metric protocols
    selectedGraphData: SelectedGraphData; // Data for the selected graph
    selectedMetricGraphData: SelectedMetricGraphData; // Data for the selected graph
    metricLoading: boolean;
}

// Initial state for the protocol slice
const initialState: ProtocolState = {
    selectedProtocols: [],
    selectedIds: [],
    selectedMetricIds: [],
    chartData: [],
    selectMetricProtocol: [],
    selectedGraphData: {
        value: null, // Initialize value as null
        drilledDate: null,
    },
    selectedMetricGraphData: {
        metricValue: null,
        metricDrilledDate: null,
    },
    metricLoading: false,
};

// Create the slice for managing trend metric data
const TrendMetric = createSlice({
    name: 'TrendMetric',
    initialState,
    reducers: {
        // Action to set selected protocols
        setSelectedProtocols: (state, action: PayloadAction<string[]>) => {
            state.selectedProtocols = action.payload;
        },
        // Action to set selected metric protocols
        setSelectMetricProtocol: (state, action: PayloadAction<string[]>) => {
            state.selectMetricProtocol = action.payload;
        },
        // Action to set selected IDs for metrics
        setSelectedIds: (state, action: PayloadAction<string[]>) => {
            state.selectedIds = action.payload;
        },
        // Action to set chart data
        setChartData: (state, action: PayloadAction<LineChart[]>) => {
            state.chartData = action.payload;
        },
        // Action to set selected graph data
        setSelectedGraphData: (state, action: PayloadAction<SelectedGraphData>) => {
            state.selectedGraphData = action.payload;
        },
        // Action to set selected Metric graph data
        setSelectedMetricGraphData: (state, action: PayloadAction<SelectedMetricGraphData>) => {
            state.selectedMetricGraphData = action.payload;
        },
        // Action to set selected metric IDs for metrics when click
        setSelectedMetricIds: (state, action: PayloadAction<string[]>) => {
            state.selectedMetricIds = action.payload;
        },
        setMetricLoading: (state, action: PayloadAction<boolean>) => {
            state.metricLoading = action.payload;
        },
    },
});

// Export the actions to be used outside the slice
export const {
    setSelectedProtocols,
    setSelectMetricProtocol,
    setSelectedIds,
    setSelectedGraphData,
    setChartData,
    setSelectedMetricIds,
    setSelectedMetricGraphData,
    setMetricLoading,
} = TrendMetric.actions;

// Export the reducer as default export
export default TrendMetric.reducer;
