import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowMobileIcon } from '../../assets/icons/icon-arrow-left-fill.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/icon-cross-fill.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { SIZES } from '../../utils/constants';
import { Loader } from '../Loader';
import { Button, buttonVariants } from './Button.component';
import { IconAlert } from './IconAlert.component';

export type DialogProps = {
    title?: string;
    close: () => void;
    open?: () => void;
    handleConfirm?: () => void;
    content?: ReactNode;
    confirmText?: string;
    loading?: boolean;
    buttonVariant?: keyof typeof buttonVariants;
    confirmButton?: boolean;
    error?: string | null;
};

export const Dialog = ({
    title,
    close,
    handleConfirm,
    content,
    confirmText,
    loading,
    buttonVariant,
    confirmButton = true,
    error,
}: DialogProps) => {
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    const { t } = useTranslation();
    return (
        <div
            className='fixed inset-0 z-50 flex items-center justify-center'
            style={{ backgroundColor: 'rgba(30, 31, 36, 0.41)' }}
        >
            <div
                className='bg-backgroundColor md:bg-white p-4 md:py-8 md:px-6 md:rounded-2xl w-full max-w-2xl
                h-screen md:h-fit'
            >
                <div
                    className='flex md:justify-between space-x-4 items-center pb-4 mb-4 md:border-b border-blue-dark
                    border-opacity-10'
                >
                    {isMobile && (
                        <button onClick={close}>
                            <ArrowMobileIcon />
                        </button>
                    )}
                    <h1 className='text-sm font-normal text-darker text-opacity-75'>{title}</h1>
                    {!isMobile && (
                        <button onClick={close}>
                            <CrossIcon fill='#0F104480' width={12} height={12} />
                        </button>
                    )}
                </div>
                {loading && (
                    <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        {' '}
                        <Loader />
                    </div>
                )}
                {content}
                {error && <IconAlert message={error} />}
                <div className='flex md:justify-end space-x-4'>
                    <Button onClick={close} variant='outlinedCancel' size='oval'>
                        {t('btnNames.cancel')}
                    </Button>
                    {confirmButton && (
                        <Button
                            onClick={handleConfirm}
                            variant={buttonVariant ? buttonVariant : 'rejectRed'}
                            size='oval'
                            disabled={loading}
                        >
                            {confirmText ? confirmText : 'Yes, Continue'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
