import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { appInsights } from '../AppInsights';
import { useServices } from '../components/ServiceProvider';
import { store, useStore } from '../store';
import { OnboaringTour } from '../store/UserStore';
import { useForm } from '../utils/useForm';
import { and, isPasswordContainsSpace, isPasswordMatch, isValidPassword, required } from '../utils/validation';

export const useSettings = () => {
    const { t } = useTranslation();
    const { users, auth } = useServices();
    const { user } = useStore();
    const userData = user.getUserData;

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [updatingPassword, setUpdatingPassword] = useState(false);
    const [edit, setEdit] = useState(false);

    const { fields, isValid, setAllVisited } = useForm({
        fields: {
            currentPassword: {
                validation: and(
                    (value: string) => required(value, t),
                    (value: string) => isPasswordContainsSpace(value, t),
                ),
            },
            newPassword: {
                validation: and(
                    (value: string) => required(value, t),
                    (value) => isValidPassword(value, t),
                    (value, allValues) => isPasswordMatch(allValues.confirmPassword, value, t),
                ),
            },
            confirmPassword: {
                validation: and(
                    (value: string) => required(value, t),
                    (value, allValues) => isPasswordMatch(allValues.newPassword, value, t),
                ),
            },
        },
    });

    const handleDeleteAccount = async () => {
        if (
            confirm(
                'Are you absolutely SURE you want to DELETE YOUR ACCOUNT ? Your account will be removed permanently and all your saved data will be permanently deleted. ',
            )
        ) {
            try {
                await users.deleteAccount();
                localStorage.setItem('jwt', '');
                alert('Account deleted ! Your clients will be able to connect and access their data for 90 days.');
                window.location.reload();
            } catch (e) {
                if (e instanceof Error) {
                    appInsights.trackException({ exception: e });
                          Sentry.captureException(e);
                }
                alert('Could not delete account. Please contact us.');
            }
        }
    };

    const updatePassword = async (password: string) => {
        if (password.trim() == '') {
            setError('Required password');
            return;
        }
        if (userData?.email) {
            setUpdatingPassword(true);
            if (confirmPassword == password) {
                setError('');
                await auth.updatePassword(userData?.email, password);
            } else {
                setUpdatingPassword(false);
                setError('Password does not match.');
                return;
            }
        }
        setUpdatingPassword(false);
    };

    const resetPassword = async () => {
        setAllVisited();
        if (isValid()) {
            try {
                if (userData?.email) {
                    setUpdatingPassword(true);
                    if (fields.newPassword.value == fields.confirmPassword.value) {
                        setError('');
                        const response = await auth.checkIfPasswordMatches(fields.currentPassword.value);
                        if (!response) {
                            setUpdatingPassword(false);
                            setError('Incorrect current password.');
                            return false;
                        }
                        await auth.updatePassword(userData?.email, fields.newPassword.value);
                        setError('');
                        setEdit(false);
                        setUpdatingPassword(false);
                        return true;
                    } else {
                        setUpdatingPassword(false);
                        setError('Password does not match.');
                        return false;
                    }
                }
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ exception: err });
                    Sentry.captureException(err);
                } else {
                    setError('Error while reseting the password');
                    appInsights.trackException({ exception: new Error('Error while reseting the password') });
                }
                return false;
            }
        } else {
            setError('Some fields are not valid, please check again');
            return false;
        }
    };

    const handlePasswordMatch = () => {
        if (fields.newPassword.value !== fields.confirmPassword.value) {
            setError('Password does not match.');
        } else {
            setError('');
        }
    };

    const updateOnboardingTour = async (status: boolean, name: keyof OnboaringTour) => {
        try {
            await users.updateOnboardingStep(status, name);
            store.user.updateOnboardingTour(status, name);
        } catch (e) {
            if (e instanceof Error) {
                appInsights.trackException({ exception: e });
                Sentry.captureException(e);
            }
        }
    };

    const skipOnboardingTour = async () => {
        try {
            await users.skipOnboardingTour();
            store.user.skipOnboardingTour();
        } catch (e) {
            if (e instanceof Error) {
                appInsights.trackException({ exception: e });
                Sentry.captureException(e);
            }
        }
    };

    return {
        handleDeleteAccount,
        updatePassword,
        password,
        setConfirmPassword,
        confirmPassword,
        setPassword,
        error,
        updatingPassword,
        edit,
        setEdit,
        fields,
        resetPassword,
        handlePasswordMatch,
        setError,
        updateOnboardingTour,
        skipOnboardingTour,
    };
};
