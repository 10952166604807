import './analyticsLogs.css';

import { Grid } from '@material-ui/core';

import { CreateEvaModalContextProvider } from '../../../../../contexts/CreateEvaModalContext';
import { SubscriptionProvider } from '../../../../../contexts/subscription.context';
import TriggerLog from '../components/analyticsChart/components/TriggerLog';

const AnalyticsLogs = ({ evaId }: { evaId: string }) => {
    return (
        <div className='pe-5 analytics_box'>
            <SubscriptionProvider>
                <CreateEvaModalContextProvider>
                    <Grid spacing={2} className='scroll_div h-full'>
                        <Grid item className='graph_box h-full '>
                            <TriggerLog evaId={evaId} />
                        </Grid>
                    </Grid>
                </CreateEvaModalContextProvider>
            </SubscriptionProvider>
        </div>
    );
};

export default AnalyticsLogs;
