import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type ResetPasswordCardProps = {
    email: string;
};

export const ResetPasswordCard = ({ email }: ResetPasswordCardProps) => {
    const { t } = useTranslation();
    return (
        <div className='flex flex-col items-center mx-auto max-w-md w-full px-1'>
            <div className='w-full'>
                <h1 className='text-tiny md:text-base font-semibold text-darker text-opacity-75 mb-6 text-center md:text-left'>
                    {t('signIn&Up.resetPassword')}
                </h1>
                <div className='text-sm text-darker text-opacity-75'>
                    <p>
                        {t('signIn&Up.emailSent')} <span className='font-medium'> {email}</span>
                    </p>
                    <p className='mb-4'>{t('signIn&Up.resetLink')}</p>
                    <p className='font-medium'>{t('signIn&Up.emailNotReceived')}</p>
                    <p>
                        {t('signIn&Up.checkSpam')}
                        <Link to={`/sign-in/forgot-password`} className='text-blue-ryb font-medium  '>
                            {' '}
                            {t('signIn&Up.resend')}
                        </Link>{' '}
                        {t('signIn&Up.emailInstruction')}
                    </p>
                </div>
            </div>
            <div className='flex items-center justify-center space-x-2 text-sm font-medium mt-12'>
                <Link to={'/sign-in'} className='text-blue-ryb my-2 underline-blue-ryb'>
                    {t('signIn&Up.back')}
                </Link>
            </div>
        </div>
    );
};
