import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import usePasswordToggle from '../../hooks/usePasswordToggle';
import { useSignInForm } from '../../hooks/useSignInForm';
import { Button } from '../basic/Button.component';
import { IconAlert } from '../basic/IconAlert.component';
import { Input } from '../basic/Input.component';
import ThreeDotAnimation from './ThreeDotAnimation.component';

const NewSignInForm = () => {
    const { t } = useTranslation();
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const { InputType, Icon, handlePasswordVisibility } = usePasswordToggle();
    const { onSubmit, fields, isLoading, isEmailValid, clearError, error } = useSignInForm();

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handlePasswordVisibility();
        }
    };

    return (
        <form
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
                if (!showPasswordInput) {
                    const res = isEmailValid();
                    if (res) {
                        setShowPasswordInput(true);
                        clearError();
                    }
                    return;
                }
                onSubmit();
            }}
            className='grid grid-1-cols justify-items-stretch text-darker text-opacity-75 max-w-lg w-full mx-auto'
        >
            <div className='overflow-auto w-full'>
                <div className='space-y-5 px-1'>
                    <h1 className='md:text-base text-tiny font-semibold'>{t('signIn&Up.signIn')}</h1>
                    <Input
                        placeholder='Email*'
                        type='email'
                        required
                        {...fields.email}
                        error={fields.email.error}
                        rounded='rounded'
                    />
                    {fields.email.error && fields.email.errorMessage && (
                        <IconAlert message={fields.email.errorMessage} className='mt-4' />
                    )}
                    <div style={{ display: showPasswordInput ? 'block' : 'none' }}>
                        <div className='relative w-full'>
                            <Input
                                required
                                placeholder='Password*'
                                type={InputType}
                                {...fields.password}
                                isPassword={true}
                                rounded='rounded'
                                error={error !== null}
                                right={
                                    <Link
                                        to='#'
                                        onKeyDown={handleKeyDown}
                                        className='pass_eye_icon absolute  right-4 cursor-pointer'
                                    >
                                        <img
                                            className=' opacity-50'
                                            src={Icon}
                                            onClick={handlePasswordVisibility}
                                            alt=''
                                        />
                                    </Link>
                                }
                            />
                        </div>
                        {error !== null && <IconAlert message={error} className='mt-4' />}
                    </div>
                    <div className='flex flex-row justify-end mt-4'>
                        <Link to='sign-in/forgot-password' className='text-sm text-blue-ryb font-medium'>
                            {t('signIn&Up.forgotPassword')}
                        </Link>
                    </div>
                    <Button
                        className='w-full continue_btn'
                        type='submit'
                        variant='blueRYB'
                        size='oval'
                        disabled={
                            showPasswordInput
                                ? !fields?.email?.value || !fields?.password?.value || isLoading
                                : !fields?.email?.value || isLoading
                        }
                    >
                        {isLoading ? (
                            <ThreeDotAnimation />
                        ) : showPasswordInput ? (
                            <p>{t('signIn&Up.signIn')}</p>
                        ) : (
                            <p>{t('btnNames.continue')}</p>
                        )}
                    </Button>
                    {/* <SignInAddons /> */}
                    <div className='flex items-center justify-center space-x-2 text-sm font-medium mt-12'>
                        <Link to={'/sign-up'} className='text-blue-ryb my-1 underline-blue-ryb'>
                            {t('signIn&Up.dontHaveAccount')}
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default NewSignInForm;
