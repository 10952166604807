import { useState } from 'react';

import { CircularProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { appInsights } from '../../../../AppInsights';
import { useOrganisationForm } from '../../../../hooks/useOrganisation';
import {
    ORGANISATION_TIERS,
    SUBSCRIPTION_BUTTON_LABEL,
    SUBSCRIPTION_STATUS,
} from '../../../../store/OrganisationStore';
import { CardResponse } from '../../../../store/UserStore';
import { useAsyncFetcher } from '../../../../utils/useAsyncFetcher';
import { Dialog } from '../../../basic/Dialog.component';
import HorizontalSeparator from '../../../basic/HorizontalSeparator';
import { useServices } from '../../../ServiceProvider';
import Downgrade from './Downgrade.component';
import OragnisationBillingSummary from './OrganisationBillingSummary.component';
import OrganisationSubscription from './OrganisationSubscription';
import PaymentMethod from './PaymentMethod.component';
import ResumeSubscription from './ResumeSubscription.component';

const Billing = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);
    const { organisation } = useServices();
    const { voidCanceledSubscription, downgradeSubscription } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();
    const { data: cards } = useAsyncFetcher<CardResponse[]>(
        async () => organisation.getPaymentMethods(organisationId),
        [],
    );
    const {
        data: subscription,
        isLoading: subscriptionLoading,
        refetch,
    } = useQuery(['getSubscription', organisationId], async () => {
        try {
            const response = await organisation.getSubscription(organisationId);
            if (response.status === SUBSCRIPTION_STATUS.CANCELED) setShowDialog(true);
            else setShowDialog(false);
            return response;
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                Sentry.captureException(e);
                appInsights.trackException({ error: e });
            }
        }
    });
    const subscriptionCancellationDate = subscription?.cancelsAt ? new Date(subscription?.cancelsAt) : null;
    if (organisationId) localStorage.setItem('spaceId', organisationId);

    if (subscriptionLoading || loading) {
        return (
            <div className='flex-grow flex flex-col justify-center items-center pt-20 text-darker text-opacity-75 mt-24'>
                <CircularProgress sx={{ color: '#373BBA', width: '32px', height: '32px' }} />
            </div>
        );
    }

    const handleSubscriptionChange = async () => {
        if (subscription?.cancelsAt) {
            try {
                setLoading(true);
                const response = await handleResume();
                if (response) setLoading(false);
            } catch (err) {
                if (err instanceof Error) {
                    setLoading(false);
                    Sentry.captureException(err);
                }
            }
        } else {
            history.push(`/organisation/${organisationId}/change-tier`);
        }
    };

    const handleDowngrade = async () => {
        try {
            const response = await downgradeSubscription(organisationId);
            if (response) {
                refetch();
            }
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ error: err });
            }
        }
    };

    const handleResume = async () => {
        try {
            const response = await voidCanceledSubscription(organisationId);
            if (response) {
                refetch();
            }
            return response;
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ error: err });
            }
        }
    };

    return (
        <div className='w-full max-w-5xl mx-auto h-full flex flex-col justify-between'>
            {showDialog && (
                <Dialog
                    title={`${subscription?.organisationName} ${t('billing.isCancelled')}`}
                    close={close}
                    open={open}
                    content={
                        <div
                            className='text-sm text-darker text-opacity-75 mb-5'
                            dangerouslySetInnerHTML={{
                                __html: t('billing.subscribeMsg'),
                            }}
                        />
                    }
                    confirmButton={false}
                />
            )}
            <div className='w-full flex-grow'>
                <OrganisationSubscription
                    type={t(`subscription_tier.${subscription?.type}`)}
                    pricingName={subscription?.productName}
                    description={
                        subscription?.productName === ORGANISATION_TIERS.SHIELD
                            ? t('subscription_tier.shield_description')
                            : t('subscription_tier.oversight_description')
                    }
                    price={subscription?.price || 0}
                    buttonName={
                        subscription?.cancelsAt
                            ? t('SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION')
                            : subscription.status === SUBSCRIPTION_STATUS.CANCELED
                            ? t('SUBSCRIPTION_BUTTON_LABEL.SUBSCRIBE')
                            : t('SUBSCRIPTION_BUTTON_LABEL.CHANGE_TIER')
                    }
                    onClick={handleSubscriptionChange}
                />
                <OragnisationBillingSummary cards={cards} />
                <PaymentMethod cards={cards} />
            </div>
            {subscription && !subscription.cancelsAt && subscription.status !== SUBSCRIPTION_STATUS.CANCELED && (
                <div className='mt-auto'>
                    <HorizontalSeparator />
                    <Downgrade
                        organisationName={subscription?.organisationName}
                        endDate={subscription?.currentPeriod.endAt}
                        handleDowngrade={handleDowngrade}
                    />
                </div>
            )}
            {subscription && subscriptionCancellationDate && subscriptionCancellationDate > new Date() && (
                <div className='mt-auto'>
                    <HorizontalSeparator />
                    <ResumeSubscription
                        organisationName={subscription?.organisationName}
                        endDate={subscription?.cancelsAt}
                        handleResume={handleResume}
                    />
                </div>
            )}
        </div>
    );
};

export default Billing;
