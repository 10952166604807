import clsx from 'clsx';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const TierCard = ({
    bgColor = '#FFFF',
    borderColor = 'border border-blue-dark border-opacity-10',
    title,
    description,
    price,
    buttonClass,
    onClick,
    buttonName = i18next.t('subscription_tier.startTier'),
    disabled = false,
    showAnnualBilling = true,
    isOverSight = false,
}: {
    bgColor?: string;
    borderColor?: string;
    title: string;
    description: string;
    price: number;
    buttonClass?: string;
    onClick: () => void;
    buttonName?: string;
    disabled?: boolean;
    showAnnualBilling?: boolean;
    isOverSight?: boolean;
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={clsx(
                'w-75 p-6 rounded-2xl',
                borderColor,
                !isOverSight ? 'text-darker text-opacity-75' : 'text-white',
            )}
            style={{
                background: bgColor,
                height: '200px',
            }}
        >
            <h1 className='font-semibold uppercase'>{title}</h1>
            <p
                className={clsx(
                    'text-xs whitespace-nowrap',
                    !isOverSight ? 'text-opacity-50 text-darker' : 'text-white text-opacity-75',
                )}
            >
                {description}
            </p>
            <div className='flex mt-4 items-center space-x-1'>
                <p
                    className={clsx(
                        'font-semibold text-xl',
                        !isOverSight ? 'text-darker text-opacity-75' : 'text-white',
                    )}
                >
                    £{price}
                </p>
                <span
                    className={clsx(
                        'text-xs font-normal',
                        !isOverSight ? 'text-darker text-opacity-50' : 'text-white text-opacity-75',
                    )}
                >
                    /org/mo
                </span>
            </div>
            {showAnnualBilling && (
                <p
                    className={clsx(
                        'text-xxs font-light',
                        !isOverSight ? 'text-darker text-opacity-35' : 'text-white text-opacity-50',
                    )}
                >
                    {t('subscription_tier.annual')} {t('subscription_tier.billing')}
                </p>
            )}

            <button
                className={clsx(
                    'w-full rounded-full p-2 mt-6 text-sm font-medium',
                    buttonClass
                        ? buttonClass
                        : 'border border-blue-dark border-opacity-10 hover:border-opacity-25 text-darker text-opacity-75',
                    'disabled:opacity-50 disabled:cursor-not-allowed',
                )}
                onClick={onClick}
                disabled={disabled}
            >
                <p>{buttonName}</p>
            </button>
        </div>
    );
};

export default TierCard;
