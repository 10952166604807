import { useState } from 'react';

import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { appInsights } from '../../../../AppInsights';
import { SUBSCRIPTION_BUTTON_LABEL } from '../../../../store/OrganisationStore';
import { timestampToDate } from '../../../../utils/dateUtils';
import { Button } from '../../../basic/Button.component';
import { Dialog } from '../../../basic/Dialog.component';

const ResumeSubscription = ({
    organisationName,
    endDate,
    handleResume,
}: {
    organisationName: string;
    endDate: number;
    handleResume: () => void;
}) => {
    const { t } = useTranslation();
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);

    const handleConfirm = async () => {
        setLoading(true);
        try {
            handleResume();
            setTimeout(async () => {
                setLoading(false);
                close();
            }, 2000);
        } catch (err) {
            if (err instanceof Error) {
                Sentry.captureException(err);
                appInsights.trackException({ error: err });
            }
            setLoading(false);
            close();
        }
    };

    return (
        <div className='w-full h-full'>
            {showDialog && (
                <Dialog
                    handleConfirm={handleConfirm}
                    title={t('SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION')}
                    close={close}
                    open={open}
                    loading={loading}
                    content={
                        <div
                            className='h-14 text-sm text-darker text-opacity-75'
                            dangerouslySetInnerHTML={{
                                __html: t('billing.revertConfirmMsg', {
                                    endDate: timestampToDate(endDate),
                                }),
                            }}
                        />
                    }
                    confirmText={t('SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION')}
                    buttonVariant='blueRYB'
                />
            )}
            <div className='w-full'>
                <div className='p-4 flex items-center justify-between'>
                    <div className='max-w-3xl text-darker text-opacity-75 mr-14'>
                        <h3 className='font-medium text-sm mb-2'>{`${t(
                            'SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION',
                        )} ${organisationName}`}</h3>
                        <p className='text-sm whitespace-pre-line'>
                            {t('billing.revertMsg', { date: timestampToDate(endDate) })}
                        </p>
                    </div>
                    <Button onClick={open} variant='blueRYB' size='oval' className='whitespace-nowrap'>
                        {t('SUBSCRIPTION_BUTTON_LABEL.CANCEL_UNSUBSCRIPTION')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ResumeSubscription;
